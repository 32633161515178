import { useCallback } from "react"

export const useFetch = () => {
    // useCallback to prevent this function recreated when the component re-renders
    return useCallback(async ({ url, method = "GET", token, body = null, returnRaw = false }) => {
        try {
            const rawResponse = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: body ? JSON.stringify(body) : null
            })

            if (returnRaw) {
                return rawResponse
            }

            return rawResponse.json()
        } catch (error) {
            console.error("error", error)
        }
    }, [])
}