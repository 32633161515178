import { useEffect } from "react"
import { 
    useLocation,
    useNavigate 
} from "react-router-dom"

// Containers
import { Layout } from "containers"

// Components
import {
    ContentSection,
    LoadingBoxes,
    InstallationButton,
    AppsTable,
    InstallationTable
} from "components"

// Util
import { ROUTE_INSTALLATION } from "util/Routes.const"
import { APP_TYPE_WEB, APP_TYPE_REACT_NATIVE } from "util/Apps.const"
// import { SEARCH_PARAM_APP_TYPE, SEARCH_PARAM_URL } from "util/SearchParams.const"

export const Tags = ({ user, properties }) => {

    // Let the sidebar Global filter control the Tags section
    const location = useLocation()
    useEffect(() => {
        console.log("Do something w/ the picks", location.state?.picks)// -> TEMP
    }, [location])

    // ...
    const hasProperties = properties?.length > 0 || false
    const navigate = useNavigate()

    useEffect(() => {
        // TODO: Check if this is correct
        if (user.property) {
            return
        }
    }, [user, navigate])

    return (
        <Layout header={"Overview"}>
            <ContentSection>
                {!hasProperties && (
                    <>
                        <h2>Get started</h2>
                        <div>
                            <p>Installed our Pixel here</p>
                            <InstallationButton link={`/${ROUTE_INSTALLATION}?type=${APP_TYPE_WEB}`} text="Website" />
                            <InstallationButton link={`/${ROUTE_INSTALLATION}?type=${APP_TYPE_REACT_NATIVE}`} text="React Native" />
                        </div>
                    </>
                )}
            </ContentSection>
            <ContentSection>
                <InstallationTable defaultAppsTable={false}>
                    {properties && properties.map((property, index) => (
                        <details key={`${property.url}`} className="installation-table__property">
                            <summary className="installation-table__property-header">
                                <span>{property.url}</span>
                                <span
                                    className={`installation-table__status
                                    installation-table__status--position-right
                                    installation-table__status--small 
                                    ${property.public_key?.active
                                            ? "installation-table__status--active"
                                            : "installation-table__status--inactive"}`}
                                >{property.public_key?.active ? "Active" : "Inactive"}</span>
                            </summary>
                            {property.apps && (
                                <AppsTable
                                    className="installation-table__property-body"
                                    key={`${property.url}-${index}`}
                                    apps={property.apps}
                                    propertyUrl={property?.url}
                                    totalLimit={property?.total_limit}
                                    // ActionsRow={({ app }) => <InstallationButton link={`/${ROUTE_INSTALLATION}?${SEARCH_PARAM_URL}=${property.url}&${SEARCH_PARAM_APP_TYPE}=${app.type}`} text={""} />}
                                />
                            )}
                        </details>
                    ))}
                </InstallationTable>
            </ContentSection>
            {hasProperties &&
                <ContentSection className="content-section--box content-section--tags">
                    <p>Data collection in progress. Come back later.</p>
                    <span></span>
                    <LoadingBoxes />
                </ContentSection>
            }
        </Layout>
    )
}