// Components
import { AppsTable } from "components"

// Icons
import { ReactComponent as WarningIcon } from "assets/_temp/Warning.svg"

// Styling
import "./InstallationTable.css"

export const InstallationTable = ({ apps, propertyUrl, totalLimit, defaultAppsTable = true, children }) => {
    const hasApps = apps?.length > 0 || children || false

    return (
        <section className="installation-table">
            <header className="installation-table__header">
                <h2 className="installation-table__title">Your installations {!hasApps && <span className="installation-table__badge">0 installations</span>}</h2>
            </header>
            {!hasApps &&
                <section className="installation-table__content installation-table__content--center">
                    <span className="installation-table__content-icon-wrapper">
                        <WarningIcon className="installation-table__content-icon"/>
                    </span>
                    <h3 className="installation-table__content-title">No installations found!</h3>
                    <p className="installation-table__content-description">We didn’t find any active installations. Please refresh the page to try again or get in touch if the problem sticks around!</p>
                </section>
            }
            {defaultAppsTable && hasApps && <AppsTable apps={apps} propertyUrl={propertyUrl} totalLimit={totalLimit} />}
            {children}
        </section>
    )
}