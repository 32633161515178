import {
  useEffect,
  useState,
  useRef
} from "react"

import "./Counter.css"

export const Counter = ({value=0, meta=null, duration=1000, callback}) => {
  const [counter, setCounter] = useState()
  const unit = useRef("")
  useEffect(() => {

    // Animated counter
    const end = parseFloat(value)
    const start = 0
    let startTimestamp = null
    if (isNaN(+value)) unit.current = value.split(end).pop()// ALT: unit.current = typeof value === "number" ? "" : value.split(end).pop()
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp
      const progress = Math.min((timestamp - startTimestamp) / duration, 1)
      setCounter(Math.floor(progress * (end - start) + start))
      if (progress < 1) {
        window.requestAnimationFrame(step)
      } else {

        /* Count end callback */
        callback && callback(true)
      }
    }
    window.requestAnimationFrame(step)
  }, [value, callback, duration])

  return (
    <div className={`counter-box ${unit.current ? "counter-box--smaller" : ""} counter-box-${meta}`}>
      {counter}{unit.current && <span className={`__text-xs counter-box-${meta}`}>{unit.current}</span>}
    </div>
)}