import "./Search.css"

export const Search = ({ value, onChange, placeholder = "", listId, className }) => {
    return (
        <input
            className={`search ${className ? className : ""}`}
            type="search"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            list={listId}
        />
    )
}