import { useRef, useEffect } from "react"

// Components
import { FormAction } from "components"

// Util
import { WITH_CONSENT, WITH_SPA } from "util/const"

// Styling
import "./InstallationWizard.css"

export const EditExistingWizard = ({ propertyToken, propertyUrl, withConsent, withSpa, onSubmit }) => {
    const withConsentRef = useRef()
    const withSpaRef = useRef()

    const propertyId = "currentProperty"
    const withConsentId = "withConsentId"
    const withSpaId = "withSpaId"

    const onSubmitWizard = (e) => {
        e.preventDefault()

        const body = {
            with_consent: withConsentRef.current.checked,
            is_spa: withSpaRef.current.checked
        }

        onSubmit({ propertyToken, body })
    }

    useEffect(() => {
        if (withConsentRef.current) {
            withConsentRef.current.checked = withConsent
        }

        if (withSpaRef.current) {
            withSpaRef.current.checked = withSpa
        }
    }, [withConsent, withSpa])

    return (
        <form onSubmit={onSubmitWizard} className="installation-wizard">
            <h2>Edit Property</h2>
            <div className="installation-wizard__group">
                <label className="installation-wizard__group-label installation-wizard__group-label--gutter-v" htmlFor={propertyId}>Current Property</label>
                <input className="installation-wizard__group-control installation-wizard__group-control--faded" type="text" id={propertyId} disabled={true} value={propertyUrl} />
            </div>
            {WITH_CONSENT && (
                <div className="installation-wizard__group installation-wizard__group--place-start">
                    <label className="installation-wizard__group-label" htmlFor={withConsentId}>
                        <span className="installation-wizard__group-label-main">Only collect data if consent is given</span>
                        <i className="installation-wizard__group-label-helper">If you are in a region that requires user consent (GDPR, CCPA, etc.) you should mark the checkbox.</i>
                    </label>
                    <input className="installation-wizard__group-check" type="checkbox" id={withConsentId} ref={withConsentRef} />
                </div>
            )}
            {WITH_SPA && (
                <div className="installation-wizard__group installation-wizard__group--place-start">
                <label className="installation-wizard__group-label" htmlFor={withSpaId}>
                <span className="installation-wizard__group-label-main">Mark this if you have a Single Page Application (SPA)</span>
                <i className="installation-wizard__group-label-helper">Marking this will ensure correct registering of visits on pages with client-site routing.<br />This feature is in beta. We are still refining this feature. Feel free to contact us if you experience any issues.</i>
                </label>
                <input className="installation-wizard__group-check" type="checkbox" id={withSpaId} ref={withSpaRef} />
            </div>
            )}
            <FormAction className="installation-wizard__group" text={"Save"} />
        </form>
    )
}