import { useState, useEffect, useRef } from "react"

// Containers
import { Layout } from "containers"

// Components
import { ContentHeader, Search, FormGroup, FormAction } from "components"

// Hooks
import { useDebounce } from "hooks/debounce"
import { useFetch } from "hooks/fetch"

// Util
import { API_NUCLEUS_BACKEND_PROPERTY_CSV } from "util/service.const"

// Styling
import "./AdminCsv.css"

const SORT_OPTIONS = [
    {
        label: "Fingerprint",
        value: "fingerprint"
    },
    {
        label: "Start date",
        value: "startDate"
    }
]

export const AdminCsv = ({ properties, googleUser }) => {
    const [localProperties, setLocalProperties] = useState(properties)
    const [isValidProperty, setIsValidProperty] = useState(false)

    const [searchValue, setSearchValue] = useState("")
    const debouncedValue = useDebounce(searchValue, 500)

    const startDateRef = useRef()
    const endDateRef = useRef()
    const limitRef = useRef()
    const sortRef = useRef()
    const skipRef = useRef()

    const fetch = useFetch()

    const listId = "admin-csv-list"

    const onFilterProperties = (search) => {
        if (!search) {
            setLocalProperties(properties)
            return
        }

        const filteredProperties = properties.filter(property => new RegExp(search, "gi").test(property.url))
        setLocalProperties(filteredProperties)
    }

    const onSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const onGenerateCsv = async (e) => {
        e.preventDefault()

        if (!localProperties.length === 1) {
            // TODO: Error handling
            return
        }

        const token = googleUser?.credential

        if (!token) {
            // TODO: Error handling
            return
        }

        const property = localProperties[0]

        const publicKey = property?.public_key?.value

        if (!publicKey) {
            // TODO: Error Handling
            return
        }

        // Construct query
        const urlParams = new URLSearchParams()
        const startDate = startDateRef.current.value
        const endDate = endDateRef.current.value
        const limit = limitRef.current.value || 10000
        const skip = skipRef.current.value || 0
        const sortBy = sortRef.current.value

        urlParams.set("start_date", startDate)
        urlParams.set("end_date", endDate)
        urlParams.set("limit", limit)
        urlParams.set("skip", skip)
        urlParams.set("sort_by", sortBy)

        const response = await fetch({
            // TODO: Add query for limit & skip
            url: `${API_NUCLEUS_BACKEND_PROPERTY_CSV}/${publicKey}?${urlParams}`,
            token,
            returnRaw: true
        })

        if (!response.ok) {
            // TODO: Error handling
            return
        }

        try {
            // TODO: Include date in filename
            // const now = new Date()
            const filename = `${new URL(property.url)?.host}.csv`
            const blob = await response.blob()

            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute("download", filename);
            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === "undefined") {
                tempLink.setAttribute("target", "_blank");
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(blobURL);
            }, 100);

            // Random UUID as filename
            // const file = window.URL.createObjectURL(blob);
            // window.location.assign(file)
        } catch (error) {
            // TODO: error handling
            console.error("Could not get CSV", error)
        }
    }

    useEffect(() => {
        setIsValidProperty(localProperties.length === 1)
    }, [localProperties])

    useEffect(() => {
        onFilterProperties(debouncedValue)
        // eslint-disable-next-line
    }, [debouncedValue])

    return (
        <Layout header={"Download CSV"}>
            <ContentHeader title={"Generate CSV file"} />
            <form onSubmit={onGenerateCsv}>
                <div>
                    <Search
                        className="admin-csv__search"
                        onChange={onSearch}
                        value={searchValue}
                        placeholder="example.com"
                        listId={listId}
                    />
                    <datalist id={listId}>
                        {localProperties.map(({ url }) => (
                            <option key={url}>{url}</option>
                        ))}
                    </datalist>
                </div>

                <FormGroup
                    className="admin-csv__form-group"
                    id={"startDate"}
                    label={"Start date"}
                    name={"startDate"}
                    type={"date"}
                    ref={startDateRef}
                // onChange
                // className
                />

                <FormGroup
                    className="admin-csv__form-group"
                    id={"endDate"}
                    label={"End date"}
                    name={"endDate"}
                    type={"date"}
                    ref={endDateRef}
                // onChange
                // className
                />

                <FormGroup
                    className="admin-csv__form-group"
                    id={"limit"}
                    label={"Limit"}
                    name={"limit"}
                    type={"number"}
                    ref={limitRef}
                // onChange
                // className
                >
                    <p>
                        <i>The maximum number of rows that will be included in the downloaded CSV file.</i>
                        <br />
                        <i>Higher number than 10,000 might affect our server performance.</i>
                    </p>
                </FormGroup>

                <FormGroup
                    className="admin-csv__form-group"
                    id={"skip"}
                    label={"Skip"}
                    name={"skip"}
                    type={"number"}
                    ref={skipRef}
                >
                    <p><i>How many rows to skip.</i></p>
                </FormGroup>
                <div className="admin-csv__form-group admin-csv__form-group--column">
                    <label className="admin-csv__form-group-label" htmlFor="sort">Sort</label>
                    <select ref={sortRef} className="admin-csv__form-group-select" name="sort" id="sort">
                        <option disabled={true}>sort by...</option>
                        {SORT_OPTIONS.map(({ label, value }) => (
                            <option key={`sort-${value}`} value={value}>{label}</option>
                        ))}
                    </select>
                    <p><i>Choose which column to sort by.</i></p>
                </div>

                <FormAction
                    className="installation-wizard__group"
                    text={"Generate CSV"}
                    disabled={!isValidProperty}
                />
            </form>
        </Layout>
    )
}