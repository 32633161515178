import { 
	useRef
 } from "react"
import { useNavigate } from "react-router-dom"

// Components
import { LoginButton } from "components"

// Hooks
import { useFetch } from "hooks/fetch"

// Util
import { ROUTE_TAGS } from "util/Routes.const"
import { API_NUCLEUS_BACKEND_USER } from "util/service.const"
import { STATUS_FAILURE } from "util/Status"
import { APP_TYPE, APP_TYPE_ADMIN } from "util/const"

// Icons
import { ReactComponent as NucleusLogoIcon } from "assets/logo-icon.svg"
import { ReactComponent as NucleusLogoText } from "assets/logo-text.svg"

// Styles
import "./Login.css"

// TODO: Streamline login flow
export const Login = ({ checkAuth, newUser }) => {

	const isAdminApp = APP_TYPE === APP_TYPE_ADMIN

	const fetch = useFetch()
	const navigate = useNavigate()

	const retrieveUser = async (googleUser) => {
		const token = googleUser.credential

		if (!token) {
			return
		}
		
		const url = isAdminApp ? `${API_NUCLEUS_BACKEND_USER}?check_admin=1` : API_NUCLEUS_BACKEND_USER
		const response = await fetch({ url, token })
		const { status, payload } = response

		if (STATUS_FAILURE === status) {
			return Promise.reject()
		}

		return Promise.resolve(payload)
	}

	const onSuccess = async (response) => {
		const googleUser = response
		const user = await retrieveUser(response)
		checkAuth({ googleUser, user })
		
		if (isAdminApp) {
			googleUser && user && user.is_admin && navigate(`/${ROUTE_TAGS}`)
			return
		}

		googleUser && user && navigate(`/${ROUTE_TAGS}`)
	}

	const onError = (response) => {
		console.log("onError", response)
	}

	const loginButtonWrapper = useRef(undefined)
	const Legal = () => {
		return (
			<div className="login__legal text-s">
				<input type="checkbox" onChange={(e) => {
					 	loginButtonWrapper.current.classList[e.target.checked ? "remove" : "add"]("oauth-button--lock")
				}}/>
				&nbsp;&nbsp;I agree to the&nbsp;<a href="/legal/tos.html" target="_self" rel="noreferrer">Terms of Service</a>
			</div>
		)
	} 

	return (
		<main className="login">
			<section className="login__content">
				<NucleusLogoIcon className="login__logo-icon" />
				<NucleusLogoText className="login__logo-text" />
				<span className="login__header text-l">Welcome! Continue with your Google account.</span>
				<div ref={loginButtonWrapper} className={"oauth-button oauth-button--lock"}>
					<LoginButton width="200px" onSuccess={onSuccess} onError={onError} />
				</div>
				<Legal/>	
			</section>
		</main>
	)
}