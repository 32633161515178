const {
    REACT_APP_NUCLEUS_API
} = process.env

export const API_NUCLEUS_BACKEND = `${REACT_APP_NUCLEUS_API}/v1`
export const API_NUCLEUS_BACKEND_USER = `${API_NUCLEUS_BACKEND}/user`
export const API_NUCLEUS_BACKEND_PROPERTY = `${API_NUCLEUS_BACKEND}/property`
export const API_NUCLEUS_BACKEND_PROPERTY_STATUS = `${API_NUCLEUS_BACKEND}/property/status`
export const API_NUCLEUS_BACKEND_PROPERTY_CSV = `${API_NUCLEUS_BACKEND}/csv`
export const API_NUCLEUS_BACKEND_AUDIENCES = `${API_NUCLEUS_BACKEND}/audience`
export const API_NUCLEUS_BACKEND_STATISTICS = `${API_NUCLEUS_BACKEND}/statistics`
