import { 
    useRef
} from "react"
import { ReactComponent as MenuIcon } from "assets/_temp/Menu.svg"
import { ReactComponent as NucleusIcon } from "assets/logo-icon.svg"

// Styling
import "./Header.css"

export const Header = ({ content, callback }) => {
    const toggleSidebar = useRef(true)
    return (
        <div className="header">
            <div className="header__menu__wrapper" onClick={() => callback(toggleSidebar.current = !toggleSidebar.current)}>
                <MenuIcon/>
            </div>
            <div className="header__h2__wrapper">
                {typeof content === 'string' 
                    ? 
                    <h2 className="header__title">{content}</h2> 
                    : 
                    content
                }
            </div>
            <div className={`header__icon__wrapper ${toggleSidebar.current && "header__icon__wrapper--toggle"}`}>
                <NucleusIcon/>
            </div>
        </div>
    )
}