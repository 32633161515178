import "./Bars.css"

export const Bars = ({series, seriesAverage}) => {// TODO: Series as prop

  // TODO: 
  // - Compute once
  // - Percentual height instead (so if we change the grid's cells height, everything adapts accordingly)
  const boxHeight = 320 * 0.7/*inner-box-height-factor*/
  const barWidth = 7.25
  const boxWidth = barWidth * series?.length || 0
  const rangeMaximum = 99// -> Max bar value (top of the scale range)
  const barHeightFactor = (boxHeight / rangeMaximum)

  // Dynamic time series labeling
  /* TODO: 
     - Yearly vertical separators
  */
  // OLD: const monthLabels = new Array(Math.round(series.length / 4.34524/*weeks-per-month*/)).fill("Jan")
  const dateNow = new Date()
  const date1st = new Date((new Date()).setDate(dateNow.getDate() - series?.length || 0 /* - TODO: add missing datapoints/days from Today */))// - (/*TEST (pretend days are weeks):*/series.length * 4.34524/*TODO: No need unless we are showing/allowing-selecting week numbers instead of months [b/c daily bars are fixed by design]*/)))
  const diffMonths = ((dateNow.getYear() - date1st.getYear()) * 12) + (dateNow.getMonth() - date1st.getMonth()) // -> Days [and therefore weekdays] already available from raw daily data
  const monthIdx1st = date1st.getMonth()
  const monthLabels = [...Array(diffMonths)].map((_, idx) => ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][(idx + monthIdx1st) % 12])

  return (
    <main className="card-barchart" style={{
      "--average": ((boxHeight * seriesAverage) * 0.01) + "px"
    }}>
      <div className="card-barchart-header text-xs">
        <b>History</b><br/>
        <span>Your audience engagement over time</span>
      </div>
      <div className="card-barchart__bars-wrapper">

        <svg className="card-barchart__bars" height={boxHeight} viewBox={`0 0 ${barWidth * series?.length || 0} ${boxHeight}`} > {/* 684 342  preserveAspectRatio="xMinYmid meet"     preserveAspectRatio="xMinYMid meet"  */}
          <g transform={`scale(1, -1) translate(0, ${boxHeight * -1})`} onMouseOver={(e) => {
            const visibleBar = e.target.parentElement?.lastElementChild
            const targetH = visibleBar?.height?.baseVal.value
            const targetX = visibleBar?.x?.baseVal.value
            if (targetH && targetX) {

              // Dot
              const hoverDot = e.currentTarget.lastElementChild
              hoverDot.setAttribute("cy", targetH)
              hoverDot.setAttribute("cx", targetX + ((barWidth - 1) * 0.5))

              // Bar
              const hoverBar = hoverDot.previousElementSibling
              hoverBar.setAttribute("height", targetH)
              hoverBar.setAttribute("x", targetX)
            }
          }}>
          {series?.length && series?.map((item, index) => {
            return (
              <g key={index} >
                <rect width={barWidth + 1} x={index * barWidth} y="0" height="100%" className="card-barchart__bar card-barchart__bar-filler"/>
                <rect width={barWidth - 1} x={index * barWidth} y="0" height={item * barHeightFactor} className="card-barchart__bar">
                  <animate attributeName="height" from="0" to={item * barHeightFactor} dur="1s" keySplines="" keyTimes="0"/>
                </rect>
              </g>
          )})}
          <rect y="0" className="card-barchart__mask-bg"></rect>
          <rect width={barWidth - 1} y="0" className="card-barchart__bar card-barchart__bar--lit"></rect> 
          <circle cx="-100" cy="-100" r={barWidth + 2} className="card-barchart__dot"/>
        </g>
      </svg>
      <div className="card-barchart__mask-fg"></div>
      <div className="card-barchart__dash"></div>
      <div className="card-barchart__dash-back"></div>
      <div className="trend__labels text-xs" style={{"width": boxWidth + "px"}}>
        {monthLabels.map((item, index) => {
          return (
            <span key={index}>{item}</span>
        )})}
      </div>
    </div>
    <div className="card-barchart__arrow"></div>
    <div className="card-barchart__disc"></div>
    <div className="card-barchart__dash-label text-xs">My all-time average</div>
    <div className="card-barchart__dash-number text-xs"><b>{seriesAverage}</b></div>
  </main>
)}