// Icons
import { ReactComponent as DotIcon } from "assets/_temp/Dot.svg"
import { ReactComponent as TickIcon } from "assets/_temp/Tick.svg"

// Styling
import "./Steps.css"

// TODO: Include dynamic steps
const steps = [
    {
        text: "Create account",
        isActive: true
    },
    {
        text: "Company details",
        isActive: true
    },
    {
        text: "Install Core Pixel",
        isActive: false
    },
]

export const Steps = ({ className }) => {
    return (
        <ul className={`steps ${className ? className : ""}`}>
            {steps?.map((step, index) => (
                <li className={`steps__item ${step.isActive ? "steps__item--active" : ""}`} key={`${step.text}-${index}`}>
                    <span className="steps__item-connector"></span>
                    <div className="steps__item-content">
                        <span className="steps__item-icon">{step.isActive ? <TickIcon /> : <DotIcon />}</span>
                        <span className="steps__item-text">{step.text}</span>
                    </div>
                </li>
            ))}
        </ul>
    )
}