import { 
  useState,
  useEffect
} from "react"

// Containers
import { 
    Layout,
    Grid
} from "containers"

// Components
import { 
  Topbar,
  Wheel,
  Bars,
  // Persona
} from "components"

// ...
import { 
  useAuth
} from "contexts"

// Highlights-wide styling
import "./Highlights.css"

// Make the deck adaptive (the JS way b/c the grid engine can't control/know its parent arrangement choice/s)
const checkColumns = () => {

  /* TODO: Get inner width from content div instead */
  const sideBarNone = window.innerWidth < 768
  const sideBarMini = window.innerWidth < 1024
  const contentSidebar = sideBarMini ? (sideBarNone ? (90 + 10)/*smoother adaptive transition into 768*/ + 10 : 70) : 200 + 10/*leftmost gap*/
  const contentViewport = window.innerWidth - contentSidebar
  return Math.floor(contentViewport / (320 + 10/*in-between gaps*/)) || 1
}

// Highlights assembly
export const Highlights = () => {
  const auth = useAuth()
  const audiences = auth?.context?.audiences
  const personas = audiences?.personas

  // Dynamic rearrangement
  // TODO: This (including the "checkColumns" function above) should be part of the grid component (we have already three instances of this block)
  const [preColumns, setColumns] = useState(checkColumns)
  useEffect(() => {
    const resetSidebar = () => {
      const newColumns = checkColumns()
      if (newColumns !== preColumns) {
        window.removeEventListener("resize", resetSidebar)
        setColumns(newColumns)
      }
    }

    /* TODO: Use useEffect or equivalent w/ checkColumns() as input instead (alt: resize observer) */
    window.addEventListener("resize", resetSidebar)
  }, [preColumns])

  // --- TEMP (we are supposed to have a fixed ceil here for all our data)
  const series = audiences?.escore_temp// -> NEXT: personas?.[0].escore
  // TEMP - Ceil is not needed anymore. escore vectors are now percentages
  // const series_fixed = series?.map(n => n * (100 / (Math.max(...series) || 100)))
  const series_fixed = series
  const series4x = series_fixed || [] //LOOP (for testing): --> series_fixed ? [...series_fixed.concat(series_fixed, series_fixed, series_fixed)] : []// --> Concat [unlike the spread operator] creates a shallow copy (so ~not enough for our [longer historical dataset] testing)

  // TODO: 
  // - Compute once per load (w/ useEffect [bound to the 'series4x' parameter])
  // - Compute all the averages globally somewhat (as part of root context)
  const globalAverage = Math.round(series_fixed?.reduce((acc, cur) => acc + cur, 0) / series_fixed?.length) || 0
  // ---

  return (
    <Layout header={<Topbar data={personas?.[0]?.metrics}/>}>
      <Grid
          content={[
            <Wheel score={series_fixed?.at(-1)/*OLD: escore[escore.length -1]*/} scoreSecondary={globalAverage}/>,
            <Bars series={series4x} seriesAverage={globalAverage}/>
          ]}
          template={(preColumns - 1) > 0
            ? `
              0${" 1".repeat(preColumns - 1)}
              ` 
            : `
              0
              1
              `}
          cellSize={{
            w: 320, 
            h: 320
          }}
        />
        {/* <Grid
          content={
            // DEV
            new Array(preColumns).fill().map((_, idx) => { 
              return <Persona key={idx} report={personas?.[0]}/>
            })
            // PROD (most likely unneeded anymore if we end up using Christian's grouping idea)
            // new Array(personas ? personas?.length : 0).fill().map((_, idx) => { 
            //   return <Persona report={personas[idx]} banner={idx === 0 ? "most" : (idx === personas?.length - 1 ? "least" : "average")}/>
            // })
            }
          template={`
            ${Array(preColumns).fill().map((_, idx) => idx++).join(" ")}
          `}
          cellSize={{
            w: 320,
            h: 640
          }}
        /> */}
    </Layout>
)}