import { useState, useEffect } from "react"

// Components
import {
    AppsTable,
    Search
} from "components"

// Hooks
import { useDebounce } from "hooks/debounce"

// Styling
import "./InstallationTable.css"

export const AdminInstallationTable = ({ properties, getPropertyStatus, onFilterProperties }) => {
    const [searchValue, setSearchValue] = useState("")
    const debouncedValue = useDebounce(searchValue, 500)

    const onGetPropertyStatus = (property) => {
        if (!property) {
            // TODO: Error Handling
            return
        }

        if ("total_limit" in property && "total_visits_aggregated" in property) {
            return
        }

        getPropertyStatus(property)
    }

    const onSearch = (e) => {
        setSearchValue(e.target.value)
    }

    useEffect(() => {
        onFilterProperties(debouncedValue)
        // eslint-disable-next-line
    }, [debouncedValue])

    return (
        <section className="installation-table">
            <header className="installation-table__header installation-table__header--row">
                <h2 className="installation-table__title">All installations</h2>
                <Search
                    className="installation-table__search"
                    value={searchValue}
                    onChange={onSearch}
                    placeholder="example.com"
                />
            </header>
            <div>
                {properties && properties.map((property, index) => (
                    <details key={`${property.url}`} className="installation-table__property" onClick={() => onGetPropertyStatus(property)}>
                        <summary className="installation-table__property-header">
                            <span>{property.url}</span>
                            <span
                                className={`installation-table__status
                                    installation-table__status--position-right
                                    installation-table__status--small 
                                    ${property.public_key?.active
                                        ? "installation-table__status--active"
                                        : "installation-table__status--inactive"}`}
                            >{property.public_key?.active ? "Active" : "Inactive"}</span>
                        </summary>
                        {property.apps && (
                            <AppsTable
                                className="installation-table__property-body"
                                key={`${property.url}-${index}`} apps={property.apps}
                                propertyUrl={property.url}
                                totalLimit={property?.total_limit}
                            />
                        )}
                    </details>
                ))}
            </div>
        </section>
    )
}