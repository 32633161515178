import { useState, useEffect, useRef } from "react"

// Containers
import {
    Layout
} from "containers"

// Components
import { ContentSection, Button } from "components"

// Contexts
import { useAuth } from "contexts"

// Util
import { useFetch } from "hooks/fetch"
import { API_NUCLEUS_BACKEND_STATISTICS } from "util/service.const"
import { STATUS_FAILURE } from "util/Status"
import {
    STATE_STATISTICS,
    JOBS_STATISTICS,
    JOBS_STATUS,
    JOBS_CODE
} from "./Statistics.const"

// Styles
import "./StatisticsReport.css"
import { FormGroup } from "components/Form/FormGroup/FormGroup"

const DEFAULT_REPORT_STATUS = null

const DEFAULT_JOB = JOBS_STATISTICS.ALL_V1

const DEFAULT_REPORT_STATUS_TEXT = 'Generate daily report'
const DEFAULT_REPORT_IN_PROGRESS_TEXT = 'New report is being generated. Wait until job is done to generate a new report.'

const DEFAULT_REPORT_STATUS_INTERVAL = 10000

export const StatisticsReport = ({ getAudiences }) => {
    const [reports, setReports] = useState([])
    const [reportStatus, setReportStatus] = useState(DEFAULT_REPORT_STATUS)
    const [reportStatusText, setReportStatusText] = useState(DEFAULT_REPORT_STATUS_TEXT)
    const [isStartJobAvailable, setIsStartJobAvailable] = useState(false)

    const isInferenceBusy = reports?.some(report => report.state === JOBS_STATUS.IN_PROGRESS)

    const auth = useAuth()

    const fetch = useFetch()

    const reportDateRef = useRef()
    const reportStatusIntervalRef = useRef()

    const job = DEFAULT_JOB

    const fetchReports = async () => {
        const token = auth?.context?.googleUser?.credential

        if (!token) {
            return
        }

        const response = await fetch({
            url: `${API_NUCLEUS_BACKEND_STATISTICS}`,
            token
        })

        const { status, payload } = response

        if (status === STATUS_FAILURE) {
            // TODO: Include error handling
            return
        }

        setReports(payload?.reports)
    }

    const onPublish = async (inference_id, reportId, state = STATE_STATISTICS.PUBLISHED) => {
        const token = auth?.context?.googleUser?.credential

        if (!(token || reportId)) {
            return
        }

        const response = await fetch({
            url: `${API_NUCLEUS_BACKEND_STATISTICS}/${reportId}`,
            method: 'PUT',
            token,
            body: {
                inference_id,
                state
            }
        })

        const { status } = response

        if (status === STATUS_FAILURE) {
            // TODO: Include error handling
            return
        }

        fetchReports()
        auth?.context?.currentDomainKey && getAudiences(auth?.context?.currentDomainKey)
    }

    const onGenerateReport = async (e) => {
        e.preventDefault()

        const token = auth?.context?.googleUser?.credential

        if (!(token)) {
            return
        }

        const startDate = reportDateRef?.current?.value || null

        const url = startDate ? `${API_NUCLEUS_BACKEND_STATISTICS}/${job}?start_date=${startDate}` : `${API_NUCLEUS_BACKEND_STATISTICS}/${job}`

        const response = await fetch({
            url,
            method: 'POST',
            token
        })

        const { status, payload } = response

        if (status === STATUS_FAILURE) {
            // TODO: Error handling
            if (payload?.code === JOBS_CODE.ALREADY_EXISTS) {
                setReportStatusText(`Report for date: ${startDate} already exists. Choose another date.`)
                setReportStatus(null)
                setIsStartJobAvailable(false)
            }
            return
        }

        if (payload?.code === JOBS_CODE.JOBS_DONE) {
            setReportStatusText(`Report jobs are done for date ${startDate}. Come back later when report is ready for publishing.`)
            setReportStatus(null)
            fetchReports()
            clearInterval(reportStatusIntervalRef.current)
            setIsStartJobAvailable(false)
            return
        }

        // payload?.currentJobStatus && setReportStatus(payload.currentJobStatus)
        setReportStatusText('Report is being generated.')
        job === JOBS_STATISTICS.ALL_V1 && setIsStartJobAvailable(false)
        payload?.jobs && setReportStatus(payload?.jobs)

        reportStatusIntervalRef.current = setInterval(() => {
            onGetReportStatus()
        }, DEFAULT_REPORT_STATUS_INTERVAL)
    }

    const onGetReportStatus = async () => {
        const token = auth?.context?.googleUser?.credential

        if (!(token)) {
            return
        }

        const startDate = reportDateRef?.current?.value || null

        if (!startDate) {
            return
        }

        const url = startDate ? `${API_NUCLEUS_BACKEND_STATISTICS}/status/${job}?start_date=${startDate}` : `${API_NUCLEUS_BACKEND_STATISTICS}/status/${job}`

        const response = await fetch({
            url,
            method: 'GET',
            token
        })

        const { status, payload } = response

        if (status === STATUS_FAILURE) {
            // TODO: Error handling

            if (payload?.code === JOBS_CODE.ALREADY_EXISTS) {
                setReportStatusText(`Report for date: ${startDate} already exists. Choose another date.`)
                setReportStatus(null)
                setIsStartJobAvailable(false)
                job === JOBS_STATISTICS.ALL_V1 && clearInterval(reportStatusIntervalRef.current)
                return
            }
        }

        if (payload?.code === JOBS_CODE.NOT_STARTED) {
            setReportStatusText(`Report for date ${startDate} not started.`)
            setReportStatus(null)
            setIsStartJobAvailable(true)
            return
        }

        if (job === JOBS_STATISTICS.ALL_V1 && payload?.code === JOBS_CODE.JOBS_DONE) {
            setReportStatusText(`Report jobs are done for date ${startDate}. Come back later when report is ready for publishing.`)
            setReportStatus(null)
            fetchReports()
            clearInterval(reportStatusIntervalRef.current)
            setIsStartJobAvailable(false)
            return
        }

        if (job === JOBS_STATISTICS.ALL_V1 && payload?.status === JOBS_STATUS.IN_PROGRESS) {
            payload?.jobs && setReportStatus(payload?.jobs)
            setIsStartJobAvailable(false)
            return
        }

        // TODO: Consider if below conditions are used
        // Clear interval when current job is done
        if (payload?.[job] === JOBS_STATUS.DONE) {
            clearInterval(reportStatusIntervalRef.current)
            setIsStartJobAvailable(false)
            return
        }

        if (payload?.[job] === JOBS_STATUS.IN_PROGRESS) {
            setIsStartJobAvailable(false)
            return
        }

        if (!reportDateRef.current.value) {
            setIsStartJobAvailable(false)
            return
        }

        setIsStartJobAvailable(true)
    }

    const onDateChange = async () => {
        onGetReportStatus()
    }

    useEffect(() => {
        fetchReports()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        isInferenceBusy && setReportStatusText(DEFAULT_REPORT_IN_PROGRESS_TEXT)
    }, [isInferenceBusy])

    return (
        <Layout header={`Statistics Report`}>
            <form className="nucleus-statistics-report__form" onSubmit={onGenerateReport}>
                <h2>Generate report</h2>
                <p className="space-top">{reportStatusText}</p>
                {/* TODO: Include actions for generating a report */}
                <ContentSection className="nucleus-statistics-report__form-row" row>
                    <FormGroup label="Report date" className="nucleus-statistics-report__form-group" id="reportDate" type="date" ref={reportDateRef} onChange={onDateChange} />
                    <Button type="submit" shouldCenterContent className="nucleus-statistics-report__action space-top" text="Start job" disabled={isInferenceBusy ? isInferenceBusy : !isStartJobAvailable} />
                </ContentSection>
                {reportStatus && (
                    <>
                        <div>fetch_period: {reportStatus?.fetch_period}</div>
                        <div>store_period: {reportStatus?.store_period}</div>
                        <div>inference: {reportStatus?.inference}</div>
                    </>
                )}
            </form>
            <hr />
            {/* TODO: Include loading state to avoid visual glitches when updating global auth context */}
            <ContentSection>
                <h2>Available reports</h2>
                <table className="nucleus-statistics-report-table">
                    <thead>
                        <tr className="nucleus-statistics-report-table__row">
                            <th className="nucleus-statistics-report-table__cell">Inference Id</th>
                            <th className="nucleus-statistics-report-table__cell">Result date</th>
                            <th className="nucleus-statistics-report-table__cell">State</th>
                            <th className="nucleus-statistics-report-table__cell">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.length > 0 && reports?.map(report => (
                            <tr key={report?._id} className="nucleus-statistics-report-table__row">
                                <td className="nucleus-statistics-report-table__cell">{report.inference_id}</td>
                                <td className="nucleus-statistics-report-table__cell">{report.result_date}</td>
                                <td className="nucleus-statistics-report-table__cell">{report.state}</td>
                                <td className="nucleus-statistics-report-table__cell">
                                    {!(report?.state === STATE_STATISTICS.PUBLISHED || report?.state === STATE_STATISTICS.IN_PROGRESS) &&
                                        <Button text={`Publish`} onClick={() => onPublish(report?.inference_id, report?._id)} />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ContentSection>
        </Layout>
    )
}