// Paywall-wide styling
import "./Paywall.css"

// Containers
import { 
    Layout
} from "containers"

// Paywall assembly
export const Paywall = () => {
    return (
      <Layout header={"Paywall"}>
        <b>Work in progress...</b><br/>
        <small>This section will be hidden before a new commit is pushed</small>
      </Layout>
)}