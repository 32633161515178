import { useState, useEffect } from "react"

// Dependencies
import { useNavigate, useSearchParams } from "react-router-dom"

// Sytling
import "./AdminUploadPersona.css"

// Containers
import {
    Layout,
    Grid
} from "containers"

// Components
import {
    Persona,
    PersonaForm,
    Button
} from "components"

// Contexts
import { useAuth } from "contexts"

// Hooks
import { useFetch } from "hooks/fetch"

// Const
import { ROUTE_UPLOAD_PERSONA } from "util/Routes.const"
import { API_NUCLEUS_BACKEND_AUDIENCES } from "util/service.const"
import { STATUS_FAILURE } from "util/Status"

const QUERY_ACTION = 'action'
const QUERY_ACTION_TYPES = {
    CREATE: 'create',
    UPDATE: 'update'
}

// Make the deck adaptive (the JS way b/c the grid engine can't control/know its parent arrangement choice/s)
const checkColumns2 = () => {
    const sideBarNone = window.innerWidth < 768
    const sideBarMini = window.innerWidth < 1024
    const contentSidebar = sideBarMini ? (sideBarNone ? (90 + 10)/*smoother adaptive transition into 768*/ + 10 : 70) : 200 + 10/*leftmost gap*/
    const contentViewport = window.innerWidth - contentSidebar
    return Math.floor(contentViewport / (320 + 10/*in-between gaps*/))
}

// Audiences parametric assembly
export const AdminUploadPersona = () => {
    const auth = useAuth()

    // const [personas, setPersonas] = useState(auth?.context?.audiences?.personas)
    const [personas, setPersonas] = useState()

    const [currentPersona, setCurrentPersona] = useState()

    const navigate = useNavigate()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()

    const fetch = useFetch()

    const token = auth?.context?.googleUser?.credential

    const fetchPersonas = async () => {
        if (!token) {
            return
        }

        try {
            const response = await fetch({
                url: `${API_NUCLEUS_BACKEND_AUDIENCES}/persona`,
                token
            })

            const { status, payload } = response

            if (status === STATUS_FAILURE) {
                // TODO: Error handling
                return
            }

            payload?.personas && setPersonas(payload?.personas)
        } catch (error) {
            console.error("[fetchPersonas]", error)
        }
    }

    useEffect(() => {
        fetchPersonas()
        // eslint-disable-next-line
    }, [])

    // CRUD navigation
    const navigateToCreatePersona = () => navigate(`/${ROUTE_UPLOAD_PERSONA}?${QUERY_ACTION}=${QUERY_ACTION_TYPES.CREATE}`)
    const navigateToUpdatePersona = (personaId) => {
        const currentPersona = personas.find(persona => persona?.persona_id === personaId)
        setCurrentPersona(currentPersona)
        navigate(`/${ROUTE_UPLOAD_PERSONA}?${QUERY_ACTION}=${QUERY_ACTION_TYPES.UPDATE}`)
    }

    // Helpers for determining action
    const isActionNone = !searchParams.has(QUERY_ACTION)
    const isActionCreate = searchParams.has(QUERY_ACTION) && searchParams.get(QUERY_ACTION) === QUERY_ACTION_TYPES.CREATE
    const isActionUpdate = searchParams.has(QUERY_ACTION) && searchParams.get(QUERY_ACTION) === QUERY_ACTION_TYPES.UPDATE

    // Resize management
    const [preColumns2, setColumns2] = useState(checkColumns2)
    useEffect(() => {
      const resetSidebar2 = () => {
        const newColumns2 = checkColumns2()
        if (newColumns2 !== preColumns2) {
          window.removeEventListener("resize", resetSidebar2)
          setColumns2(newColumns2)
        }
      }
  
    //   /* TODO: Use useEffect or equivalent w/ checkColumns() as input instead (alt: resize observer) */
      window.addEventListener("resize", resetSidebar2)
    }, [preColumns2])

    return (
        <Layout header={<Button className="upload-persona-temp-button" text={`Create new persona`} onClick={navigateToCreatePersona} />}>
            {isActionNone && (
                <Grid
                content={new Array(personas ? personas?.length : 0).fill().map((_, idx) => { 
                    const persona = personas[idx]
                    return (
                        <Persona 
                            buttonCallback={() => navigateToUpdatePersona(persona?.persona_id)}
                            banner={persona?.state}
                            report={{
                            "id": persona?.persona_id,
                            "escore": [
                                0
                            ],
                            "share": [
                                0
                            ],
                            "profile": {
                                "pronoun": persona?.pronoun || '',
                                "name": persona?.name || '',
                                "title": persona?.title || '',
                                "description": persona?.description || '',
                                "image": persona?.image
                            },
                            "dimensions": {
                                "app": [],
                                "device": [],
                                "channel": [],
                                "category": [],
                                "location": [],
                                "monetization": []
                            },
                            "metrics": [],
                            "admin": {
                                "elabel": persona?.engagement_label,
                                "glabel": persona?.group_label
                            }
                        }}
                        />
                        )
                    })}
                    template={personas 
                    ? 
                    `${(new Array(/* rows = */(Math.ceil(personas?.length / preColumns2) * preColumns2) || 0).fill()).map((_, idx) => {
                        return (idx % preColumns2 ? "" : "\n") + idx
                    }).join(" ")}\n`// + "? ".repeat(preColumns2)
                    : 
                    ""
                    }
                    cellSize={{
                        w: 320, 
                        h: preColumns2 > 1 ? 640 : null
                    }}
                    >
                </Grid>
            )}

            {/* CRUD Single Persona */}
            {isActionCreate && <PersonaForm fetchPersonas={fetchPersonas} />}
            {isActionUpdate && <PersonaForm persona={currentPersona} fetchPersonas={fetchPersonas} />}
        </Layout>
    )
}