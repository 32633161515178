import {
  useEffect,
  useRef
} from "react"

import "./Filter.css"

const filterSelection = []
let scrollPosition

export const Filter = ({multi = false, domains, choices}) => {
  const fieldSet = useRef()
  useEffect(() => {
    const nodes = fieldSet?.current?.querySelectorAll("input")
    for (const item of nodes) {

      // Checkboxes
      if (item.id === "_") {
        const allTicked = filterSelection.length
        item.classList[allTicked === domains.length ? "remove" : "add"]("filter-item__pick-some")
        item.checked = allTicked

      // Radio buttons
      } else {
        item.checked = (filterSelection.join("") === item.id)
      }
    }
    if (nodes.length && !filterSelection.length) {
      const defaultPick = nodes[0] 
      filterSelection[0] = defaultPick.id
      defaultPick.checked = true
    }
    fieldSet.current.scrollTop = scrollPosition
  }, [multi, domains?.length])

  return (
    <div className="filter-container text-s">
      <div className="filter-header">
        <span className="filter-title text-xs"><b>Global filter</b></span>
        <br/>
        <span className="filter-description text-xs">This is where you select<br/> where the data comes from</span>
      </div>
      <fieldset ref={fieldSet} className="filter-domains" onClick={e => {
          if (e.target.name === "_") {
            scrollPosition = e.currentTarget.scrollTop

            // Radio buttons
            if (e.target.id !== "_") {
              if (filterSelection.join() !== e.target.id) { 
                filterSelection[0] = e.target.id
                choices([e.target.id])
              }

            // Checkboxes
            } else {
              // TODO: Checkboxes support for the current updated model
            }
          }
          // if (e.target.name == "_") {
          //   if (multi) {
          //     filterSelection.length = 0
          //     const items = fieldSet.current?.querySelectorAll("input[type='checkbox']")
          //     if (e.target.id === "_") {
          //       for (const item of items) {
          //         if (e.target.id === "_") {
          //           // (item.checked = e.target.checked) && filterSelection.push(item.id)
          //           (item.checked = e.target.checked) && filterSelection.push(item.id)
          //         } else {
          //           // const groupTick = fieldSet.current?.querySelector("#_")
          //           // const allTicked = filterSelection.filter(value => value).length
          //           // groupTick.classList[allTicked === filterSelection.length ? "remove" : "add"]("filter-item__pick-some") 
          //           // groupTick.checked = allTicked
          //         }
          //       }
          //     } else {
          //       if (e.target.checked) {
          //         filterSelection.push(e.target.id)
          //       }
          //     }
          //   } 
          // }
        }}>
        {multi ? <div className="filter-item">{/* TODO: Get "Select All" checkbox tick out of the scroll */}
          <input className="filter-item__pick" type="checkbox" name="_" id="_"/>
          <label className="filter-item__text text-s" htmlFor="_">Select all</label>
        </div> : ""}
        {multi && <div className="filter-divisor"></div>}
        {domains?.map((domain, index) => 
          <div key={index} className="filter-item">
            <input className="filter-item__pick" value={domain} type={multi ? "checkbox" : "radio"} name="_" id={index} disabled={index === 2}/>
            <label className="filter-item__text text-s" htmlFor={index}>{domain}</label>
          </div>
        )}
      </fieldset>
      <div className="filter-footer"></div>
    </div>
  )}