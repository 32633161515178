import { useState, useEffect, createRef } from "react"
import { useNavigate } from "react-router-dom"

// Components
import { FormGroup, FormAction } from "components"

// Hooks
import { useAuth } from "contexts"

// Util
import { ROUTE_UPLOAD_PERSONA } from "util/Routes.const"
import { STATUS_FAILURE } from "util/Status"
import { API_NUCLEUS_BACKEND_AUDIENCES } from "util/service.const"
import { PERSONA_STATES } from "util/PersonaStates.const"

// Styling
import "./PersonaForm.css"

// Message Consts
// FIXME: Copied from Installation, create a common component
const MESSAGE_TYPES = {
    SUCCESS: "success",
    ERROR: "error"
}

const MESSAGE_DEFAULT_STATE = {
    text: "",
    type: null
}

const DEFAULT_MESSAGE_TIMEOUT = 1500

export const PersonaForm = ({ persona, fetchPersonas }) => {
    const [message, setMessage] = useState()
    const [personaImage, setPersonaImage] = useState()

    const engagementId = "engagementId"
    const groupLabelId = "groupLabelId"
    const personaNameId = "personaNameId"
    const personaTitleId = "personaTitleId"
    const personaImageId = "personaImageId"
    const personaPronounId = "personaPronounId"
    const personaStateId = "personaStateId"

    const engagementLabelRef = createRef()
    const groupLabelRef = createRef()
    const personaNameRef = createRef()
    const personaTitleRef = createRef()
    const personaPronounRef = createRef()
    const personaStateRef = createRef()

    const fetch = window.fetch
    const auth = useAuth()

    const navigate = useNavigate()

    // TODO: Create message context
    const showMessage = (message, type, callback = () => { }, timeout = DEFAULT_MESSAGE_TIMEOUT) => {
        setMessage({ text: message, type })
        setTimeout(() => {
            setMessage(MESSAGE_DEFAULT_STATE)
            callback && callback()
        }, timeout)
    }

    const showErrorMessage = (message, callback) => {
        showMessage(message, MESSAGE_TYPES.ERROR, callback)
    }

    const showSuccessMessage = (message, callback) => {
        showMessage(message, MESSAGE_TYPES.SUCCESS, callback)
    }

    const constructPersonaForm = () => {
        const engagementLabel = engagementLabelRef.current?.value
        const groupLabel = groupLabelRef.current?.value
        const personaName = personaNameRef.current?.value
        const personaTitle = personaTitleRef.current?.value
        const personaState = personaStateRef.current?.value
        const personaPronoun = personaPronounRef.current?.value

        const formData = new FormData()
        engagementLabel && formData.append("engagement_label", engagementLabel)
        groupLabel && formData.append("group_label", groupLabel)
        personaName && formData.append("name", personaName)
        personaTitle && formData.append("title", personaTitle)
        personaPronoun && formData.append("pronoun", personaPronoun)
        personaImage && personaImage[0] && formData.append("image", personaImage[0], personaImage[0].name)
        personaState && formData.append("state", personaState)

        return formData
    }

    const navigateToOverview = () => {
        fetchPersonas()
        navigate(`/${ROUTE_UPLOAD_PERSONA}`)
    }

    const handleFile = (e) => {
        setPersonaImage(e.target.files)
    }

    const onCreate = async (e) => {
        e.preventDefault()

        const token = auth?.context?.googleUser?.credential

        if (!token) {
            // TODO: Error message
            showErrorMessage("Not a valid token.")
            return
        }

        const formData = constructPersonaForm()

        try {
            // Using regular fetch instead of hook for sending multipart/form-data
            const response = await fetch(`${API_NUCLEUS_BACKEND_AUDIENCES}/persona`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            })

            const { status, payload } = await response?.json()

            if (status === STATUS_FAILURE) {
                showErrorMessage("Could not save persona.")
                return
            }

            if (status === STATUS_FAILURE && payload?.CODE === "PERSONA_EXISTS") {
                showErrorMessage("Persona already exists. Create a persona with other engagement and group labels")
                console.warn("Persona exists.")
                return
            }

            // TODO: Determine if the admin should navigate to upload-audiences
            showSuccessMessage("Persona created.", navigateToOverview)
        } catch (error) {
            console.error("[onCreate - persona] error", error)
            showErrorMessage("Could not save persona. Something went wrong.")
        }
    }

    const onUpdate = async (e) => {
        e.preventDefault()

        const token = auth?.context?.googleUser?.credential

        if (!token) {
            // TODO: Error message
            showErrorMessage("Not a valid token.")
            return
        }

        const formData = constructPersonaForm()

        try {
            // Using regular fetch instead of hook for sending multipart/form-data
            const response = await fetch(`${API_NUCLEUS_BACKEND_AUDIENCES}/persona/${persona?._id}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            })

            const { status } = await response?.json()

            if (status === STATUS_FAILURE) {
                showErrorMessage("Could not save persona.")
                return
            }

            // TODO: Determine if the admin should navigate to upload-audiences
            showSuccessMessage("Persona updated.", navigateToOverview)
        } catch (error) {
            console.error("[onUpdate - persona] error", error)
            showErrorMessage("Could not update persona. Something went wrong.")
        }
    }

    const onDelete = async (e) => {
        const token = auth?.context?.googleUser?.credential

        if (!token) {
            // TODO: Error message
            showErrorMessage("Not a valid token.")
            return
        }

        try {
            // Using regular fetch instead of hook for sending multipart/form-data
            const response = await fetch(`${API_NUCLEUS_BACKEND_AUDIENCES}/persona/${persona?._id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })

            const { status } = await response?.json()

            if (status === STATUS_FAILURE) {
                showErrorMessage("Could not delete persona.")
                return
            }

            // TODO: Determine if the admin should navigate to upload-audiences
            showSuccessMessage("Persona deleted.", navigateToOverview)
        } catch (error) {
            console.error("[onDelete - persona] error", error)
            showErrorMessage("Could not delete persona. Something went wrong.")
        }
    }

    useEffect(() => {
        if (persona?.engagement_label) {
            engagementLabelRef.current.value = persona?.engagement_label
        }

        if (persona?.group_label) {
            groupLabelRef.current.value = persona?.group_label
        }

        if (persona?.name) {
            personaNameRef.current.value = persona?.name
        }

        if (persona?.name) {
            personaNameRef.current.value = persona?.name
        }

        if (persona?.title) {
            personaTitleRef.current.value = persona?.title
        }
        
        if (persona?.pronoun) {
            personaPronounRef.current.value = persona?.pronoun
        }

        if (persona?.state) {
            personaStateRef.current.value = persona?.state
        }
        // eslint-disable-next-line
    }, [persona])

    return (
        <form className="persona-form" onSubmit={persona ? onUpdate : onCreate}>
            <h2>{persona ? "Update persona" : "Create persona"}</h2>
            <FormGroup
                className="persona-form__group"
                id={engagementId}
                name={"engagement"}
                label={"Engagement label"}
                // type={getInputType()}
                placeholder={`Insert engagement label`}
                required
                size="50"
                ref={engagementLabelRef}
                readOnly={persona}
            />
            <FormGroup
                className="persona-form__group"
                id={groupLabelId}
                name={"groupLabel"}
                label={"Group label"}
                // type={getInputType()}
                placeholder={`Insert group label`}
                required
                size="50"
                ref={groupLabelRef}
                readOnly={persona}
            />
            <FormGroup
                className="persona-form__group"
                id={personaNameId}
                name={"personaName"}
                label={"Persona name"}
                placeholder={`Insert persona name here`}
                size="50"
                ref={personaNameRef}
            />
            <FormGroup
                className="persona-form__group"
                id={personaTitleId}
                name={"personaTitle"}
                label={"Persona title"}
                placeholder={`Insert persona title`}
                size="50"
                ref={personaTitleRef}
            />
            <FormGroup
                className="persona-form__group"
                id={personaPronounId}
                name={"personaPronoun"}
                label={"Persona pronoun"}
                placeholder={`Insert persona pronoun`}
                size="50"
                ref={personaPronounRef}
            />
            <FormGroup
                className="persona-form__group"
                id={personaImageId}
                name={"personaImage"}
                label={"Persona image"}
                placeholder={`Insert group label id`}
                type={`file`}
                onChange={handleFile}
                additionalAttributes={{
                    accept: "image/png, image/jpeg"
                }}
            />
            {!personaImage && persona?.image && (
                <div className="persona-form__group persona-form__group--column persona-form__group--push-top">
                    <i>Existing image</i>
                    <img src={persona?.image} style={{ height: '300px', width: '168.5px', margin: '1em 0' }} alt='Existing persona' />
                </div>
            )}
            {personaImage && personaImage[0] && (
                <div className="persona-form__group persona-form__group--column persona-form__group--push-top">
                    <i>New image - Preview</i>
                    <img src={URL.createObjectURL(personaImage[0])} style={{ height: '300px', width: '168.5px', margin: '1em 0' }} alt='New persona' />
                </div>
            )}
            <div className="persona-form__group persona-form__group--column persona-form__group--push-top">
                <label className="persona-form__label" htmlFor={personaStateId}>Status</label>
                <select ref={personaStateRef} className="persona-form__select" name="personaStatus" id={personaStateId}>
                    {Object.entries(PERSONA_STATES).map(([key, value]) => (
                        <option key={`persona-state-${value}`} value={value}>{key}</option>
                    ))}
                </select>
            </div>
            <div className="persona-form__actions">
                <FormAction
                    className="persona-form__action"
                    text={persona ? "Update persona" : "Create persona"}
                />
                {persona &&
                    <FormAction
                        className="persona-form__action persona-form__action--delete"
                        text={"Delete persona"}
                        onClick={onDelete}
                        type="button"
                    />
                }
            </div>
            {message?.text && message?.type && (
                <p
                    className={`persona-form__message
                        ${MESSAGE_TYPES.ERROR === message.type ? "persona-form__message--error" : ""}
                        ${MESSAGE_TYPES.SUCCESS === message.type ? "persona-form__message--success" : ""}
                    `}
                >{message.text}</p>
            )}
        </form>
    )
}