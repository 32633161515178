import { Link } from "react-router-dom"

// Icon
import { ReactComponent as DownloadIcon } from "assets/_temp/Download.svg"

// Styling
import "./InstallationButton.css"

export const InstallationButton = ({ link, text, Logo = DownloadIcon, className }) => {
    return (
        <Link to={link} className={`installation-button ${className ? className : ""}`}>
            <Logo className="installation-button__icon" />
            {text && <span className="installation-button__text">{text}</span>}
        </Link>
    )
}