// Containers
import { Layout } from "containers"

// Components
import {
    ContentHeader,
    ContentSection,
    InstallationButton,
    InstallationTable,
    Steps,
    AppsTable
} from "components"

// Util
import { ROUTE_INSTALLATION } from "util/Routes.const"
import { APP_TYPE_WEB, APP_TYPE_REACT_NATIVE } from "util/Apps.const"
// import { SEARCH_PARAM_APP_TYPE, SEARCH_PARAM_URL } from "util/SearchParams.const"

// Icons
import { ReactComponent as DownloadIcon } from "assets/_temp/Download.svg"

// Styling
import "./Settings.css"

export const Settings = ({ properties }) => {
    return (
        <Layout header={"Get Started"}>
                {!(properties?.[0]?.apps.length > 0) && <Steps className="settings__steps" />}
                <ContentHeader
                    title={"Find the Nucleus Pixel for your site or app"}
                    description={"We are continuously adding new pixels. If you can’t find a pixel for your site or app, please contact us."}
                />
                <ContentSection>
                    <h3>Create new Pixel</h3>
                    <InstallationButton link={`/${ROUTE_INSTALLATION}?type=${APP_TYPE_WEB}`} text="Website" Logo={DownloadIcon} />
                    <InstallationButton link={`/${ROUTE_INSTALLATION}?type=${APP_TYPE_REACT_NATIVE}`} text="React Native" Logo={DownloadIcon} />
                </ContentSection>
                <ContentSection>
                    <InstallationTable defaultAppsTable={false}>
                        {properties && properties.map((property, index) => (
                            <details key={`${property.url}`} className="installation-table__property">
                                <summary className="installation-table__property-header">
                                    <span>{property.url}</span>
                                    <span
                                        className={`installation-table__status
                                    installation-table__status--position-right
                                    installation-table__status--small 
                                    ${property.public_key?.active
                                                ? "installation-table__status--active"
                                                : "installation-table__status--inactive"}`}
                                    >{property.public_key?.active ? "Active" : "Inactive"}</span>
                                </summary>
                                {property.apps && (
                                    <AppsTable
                                        className="installation-table__property-body"
                                        key={`${property.url}-${index}`}
                                        apps={property.apps}
                                        propertyUrl={property?.url}
                                        totalLimit={property?.total_limit}
                                        // ActionsRow={({ app }) => <InstallationButton link={`/${ROUTE_INSTALLATION}?${SEARCH_PARAM_URL}=${property.url}&${SEARCH_PARAM_APP_TYPE}=${app.type}`} text={""} />}
                                    />
                                )}
                            </details>
                        ))}
                    </InstallationTable>
                </ContentSection>
        </Layout>
    )
}