import { createContext, useContext, useState, useEffect } from "react"

// Util
// import { STORAGE_CURRENT_DOMAIN } from "util/Storage.const"

const AuthContext = createContext()

export const useProvideAuth = (authValue = null) => {
	const [context, setAuthContext] = useState(authValue)

	
	useEffect(() => {
		if (authValue === context) {
			return
		}

        authValue && setAuthContext(authValue)
    }, [authValue, context])

	const set = (newContext) => {
		setAuthContext(newContext)
	}

	const clearContext = () => {
        setAuthContext(null)
	}

	return {
		context,
        set,
        clearContext
	}
}

export const ProvideAuth = ({ children, auth }) => {
	const context = useProvideAuth(auth)

	return (
		<AuthContext.Provider value={context}>
		 {children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => useContext(AuthContext)