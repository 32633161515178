import {
  useEffect,
  useState,
  useRef
} from "react"
import "./Arc.css"
const trace = (angle) => {
  const radians = ((angle < 360 ? angle : 359.999) - 90) * Math.PI / 180.0
  return {
    x: 50 + (50 * Math.cos(radians)),
    y: 50 + (50 * Math.sin(radians)),
    f: angle > 180 ? 1 : 0
  }
}
export const Arc = ({target=100, targetSecondary=0, theme=null, duration=1000}) => {
  const [coordFg, setcoordFg] = useState({x: 50, y: 0, f: 0})
  const [coordBg, setcoordBg] = useState({x: 50, y: 0, f: 0})

  const unit = useRef("")
  const arc = useRef(0)
  const spokes = useRef()
  const thickness = useRef()
  const [popUp, setPopUp] = useState(false)
  useEffect(() => {
    thickness.current = parseInt(arc.current.getBoundingClientRect().height) > 100 ? "thick" : "thin"
    
    // Foreground score
    const endFg = parseFloat(target)

    // Background score
    const endBg = parseFloat(targetSecondary)
    
    const factor = 360 / (/* --- */100/* --- */ || 1)
    if (isNaN(+target)) unit.current = target.split(endFg).pop()// ALT: unit.current = typeof target === "number" ? "" : target.split(endFg).pop()
    let startTimestamp = null
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp
      const progress = Math.min((timestamp - startTimestamp) / duration, 1)

      // Foreground score
      setcoordFg(trace(progress * endFg * factor))

      // Background score
      setcoordBg(trace(progress * endBg * factor))

      if (progress < 1) {
        window.requestAnimationFrame(step)
      } else {

        // Foreground score
        const positionFg = Math.floor(target * progress)
        const spokesFgSvg = spokes.current?.querySelector(`svg:nth-child(${positionFg % 2 === 0 ? "odd" : "even"})`) 
        const spokesFgPath = spokesFgSvg?.querySelector(`path:nth-child(${parseInt(positionFg * 0.5)})`).nextSibling
        spokesFgPath?.classList.add(`arc-theme-${theme}`) 

        // Background score
        // const positionBg = Math.floor(targetSecondary * progress)
        // const spokesBgSvg = spokes.current?.querySelector(`svg:nth-child(${positionBg % 2 === 0 ? "odd" : "even"})`) 
        // const spokesBgPath = spokesBgSvg?.querySelector(`path:nth-child(${parseInt(positionBg * 0.5)})`).nextSibling
        // spokesBgPath?.classList.add(`arc-theme-${theme}`) 
      }
    }
    window.requestAnimationFrame(step)
  }, [target, duration, theme])

  return (
    <div className="arc-wrapper">
      {thickness.current === "thick" ?
      <div ref={spokes} className={`arc__spokes arc-theme-${theme}`}>
        <svg className="arc__spokes-full" viewBox="0 0 325 325">
          <path d="M162.539 0.809452V28.2184"/>
          <path d="M182.768 2.0821L179.333 29.275"/>
          <path d="M202.679 5.88039L195.863 32.4283"/>
          <path d="M221.957 12.1441L211.867 37.6283"/>
          <path d="M240.298 20.7747L227.093 44.7933"/>
          <path d="M257.412 31.6358L241.302 53.8101"/>
          <path d="M273.031 44.5564L254.268 64.5367"/>
          <path d="M286.906 59.3324L265.787 76.8036"/>
          <path d="M298.821 75.7311L275.678 90.4176"/>
          <path d="M308.586 93.4938L283.785 105.164"/>
          <path d="M316.048 112.34L289.98 120.81"/>
          <path d="M321.088 131.973L294.165 137.109"/>
          <path d="M323.629 152.083L296.274 153.804"/>
          <path d="M323.629 172.353L296.274 170.632"/>
          <path d="M321.089 192.463L294.165 187.327"/>
          <path d="M316.047 212.097L289.98 203.627"/>
          <path d="M308.586 230.943L283.785 219.273"/>
          <path d="M298.821 248.706L275.678 234.019"/>
          <path d="M286.906 265.104L265.787 247.633"/>
          <path d="M273.031 279.881L254.268 259.9"/>
          <path d="M257.412 292.801L241.302 270.627"/>
          <path d="M240.298 303.662L227.094 279.644"/>
          <path d="M221.957 312.293L211.867 286.809"/>
          <path d="M202.679 318.556L195.863 292.009"/>
          <path d="M182.768 322.355L179.333 295.162"/>
          <path d="M162.538 323.627V296.218"/>
          <path d="M142.308 322.355L145.744 295.162"/>
          <path d="M122.398 318.556L129.214 292.009"/>
          <path d="M103.12 312.293L113.21 286.808"/>
          <path d="M84.779 303.662L97.9834 279.644"/>
          <path d="M67.6645 292.801L83.7751 270.627"/>
          <path d="M52.0463 279.881L70.8091 259.9"/>
          <path d="M38.1706 265.104L59.2896 247.633"/>
          <path d="M26.2563 248.706L49.3984 234.019"/>
          <path d="M16.491 230.943L41.2914 219.273"/>
          <path d="M9.02918 212.097L35.0967 203.627"/>
          <path d="M3.98848 192.463L30.912 187.328"/>
          <path d="M1.44771 172.353L28.8026 170.632"/>
          <path d="M1.44795 152.083L28.8029 153.804"/>
          <path d="M3.98824 131.973L30.9117 137.109"/>
          <path d="M9.02918 112.34L35.0967 120.81"/>
          <path d="M16.4912 93.4939L41.2916 105.164"/>
          <path d="M26.256 75.7311L49.3982 90.4176"/>
          <path d="M38.1706 59.3324L59.2896 76.8036"/>
          <path d="M52.0463 44.5563L70.8091 64.5366"/>
          <path d="M67.6645 31.6358L83.7751 53.8101"/>
          <path d="M84.779 20.7746L97.9834 44.7933"/>
          <path d="M103.12 12.1442L113.21 37.6284"/>
          <path d="M122.398 5.88033L129.214 32.4282"/>
          <path d="M142.308 2.08222L145.744 29.2751"/>
        </svg>
        <svg className="arc__spokes-half" viewBox="0 0 298 298">
          <path d="M157.814 0.791352L156.952 14.4827"/>
          <path d="M176.218 3.1164L173.648 16.5919"/>
          <path d="M194.186 7.72978L189.947 20.7768"/>
          <path d="M211.434 14.5587L205.593 26.9715"/>
          <path d="M227.69 23.4956L220.339 35.0785"/>
          <path d="M242.698 34.3994L233.953 44.9696"/>
          <path d="M256.221 47.0982L246.22 56.4891"/>
          <path d="M268.045 61.3917L256.947 69.4552"/>
          <path d="M277.985 77.0545L265.963 83.6635"/>
          <path d="M285.884 93.8396L273.129 98.8896"/>
          <path d="M291.616 111.482L278.329 114.894"/>
          <path d="M295.092 129.704L281.482 131.424"/>
          <path d="M296.257 148.218H282.538"/>
          <path d="M295.092 166.732L281.482 165.013"/>
          <path d="M291.616 184.955L278.329 181.543"/>
          <path d="M285.884 202.597L273.129 197.547"/>
          <path d="M277.985 219.382L265.963 212.773"/>
          <path d="M268.045 235.045L256.947 226.982"/>
          <path d="M256.221 249.339L246.22 239.948"/>
          <path d="M242.698 262.037L233.953 251.467"/>
          <path d="M227.69 272.941L220.339 261.358"/>
          <path d="M211.434 281.878L205.593 269.465"/>
          <path d="M194.186 288.707L189.947 275.66"/>
          <path d="M176.218 293.32L173.647 279.845"/>
          <path d="M157.814 295.645L156.952 281.954"/>
          <path d="M139.263 295.645L140.125 281.954"/>
          <path d="M120.859 293.32L123.429 279.845"/>
          <path d="M102.891 288.707L107.13 275.66"/>
          <path d="M85.6431 281.878L91.4841 269.465"/>
          <path d="M69.3871 272.941L76.7378 261.358"/>
          <path d="M54.3791 262.037L63.1235 251.467"/>
          <path d="M40.8564 249.339L50.8567 239.948"/>
          <path d="M29.0317 235.045L40.1301 226.982"/>
          <path d="M19.0917 219.382L31.1133 212.773"/>
          <path d="M11.1934 202.597L23.9485 197.547"/>
          <path d="M5.46084 184.955L18.7483 181.543"/>
          <path d="M1.98494 166.732L15.5952 165.013"/>
          <path d="M0.819885 148.218H14.5383"/>
          <path d="M1.98494 129.704L15.5952 131.424"/>
          <path d="M5.46084 111.482L18.7483 114.894"/>
          <path d="M11.1934 93.8397L23.9485 98.8898"/>
          <path d="M19.0917 77.0547L31.1133 83.6636"/>
          <path d="M29.0317 61.3917L40.1301 69.4552"/>
          <path d="M40.8564 47.0982L50.8567 56.4891"/>
          <path d="M54.3791 34.3995L63.1235 44.9697"/>
          <path d="M69.3871 23.4956L76.7378 35.0785"/>
          <path d="M85.6431 14.5587L91.4841 26.9715"/>
          <path d="M102.891 7.72978L107.13 20.7768"/>
          <path d="M120.859 3.1164L123.43 16.5919"/>
          <path d="M139.263 0.791352L140.125 14.4827"/>
        </svg>
      </div>
      : ""}
      <div className={`arc__info ${popUp ? "arc__info--show" : ""}`}>
        Pending...
      </div>
      {/* <div className={thickness.current === "thick" ? "arc__disc" : ""}></div> */}
      <svg className={`arc arc-${thickness.current} arc-theme-${theme}`} viewBox="0 0 100 100" ref={arc} >
        <path className="arc__path" d={`M ${50 - 0.1} 0 A 50 50 0 1 0 50 0`}/>
        <path className="arc__line" d={`M ${coordFg.x} ${coordFg.y} A 50 50 0 ${coordFg.f} 0 50 0`}/>
        <path className="arc__mask" d={`M 50 5 L 50 -5`}/>
        {unit.current
        ? 
          <path className={`arc__fill arc-theme-${theme}`} d={`M ${coordFg.x} ${coordFg.y} A 50 50 0 ${coordFg.f} 0 50 0 L 50 50 L ${coordFg.x} ${coordFg.y}`}/>
        : 
          <g>
            <path className={`arc__dot1a arc-theme-${theme}`} d={`M ${coordFg.x - 0.1} ${coordFg.y} A 50 50 0 0 0 ${coordFg.x} ${coordFg.y}`}/>
            <path className={`arc__dot1b arc-theme-${theme}`} d={`M ${coordFg.x - 0.1} ${coordFg.y} A 50 50 0 0 0 ${coordFg.x} ${coordFg.y}`} onMouseEnter={() => setPopUp(true)} onMouseLeave={() => setPopUp(false)}/>
            {
            targetSecondary &&
            <>
              <path className={`arc__dot2a arc-theme-${theme}`} d={`M ${coordBg.x - 0.1} ${coordBg.y} A 50 50 0 0 0 ${coordBg.x} ${coordBg.y}`}/>
              <path className={`arc__dot2b arc-theme-${theme}`} d={`M ${coordBg.x - 0.1} ${coordBg.y} A 50 50 0 0 0 ${coordBg.x} ${coordBg.y}`}/>
            </>
            }
          </g>
        }
      </svg>
    </div>
  )}