import { forwardRef } from "react"

// Util
import { roundToDecimals } from "util/util"

// Styles
import "./Meter.css"

// Default values
const DEFAULT_METER_VALUE = 1

export const Meter = forwardRef(({ meterLevel, className, current, total }, ref) => {
    const calculatedCurrent = current > total ? total : current

    return (
        <span className={`meter-wrapper ${className ? className : ""}`}>
            <span className="meter-explainer">
                <span className="meter-explainer___content">{calculatedCurrent} / {total}</span>
            </span>
            <span className="meter">
                <span ref={ref} className={`meter__bar `} style={{ "--length": `${meterLevel || DEFAULT_METER_VALUE}%` }}></span>
            </span>
            <span className="meter__text">{roundToDecimals(meterLevel) || 0} %</span>
        </span>
    )
})
