// React built-ins
import {
  useRef
} from "react"

import {
  Link,
  useLocation
} from "react-router-dom"

// Context
import {
  useAuth
} from "contexts"

// Components
import {
  Avatar,
  Filter,
} from "components"

// Util
import {
  ROUTE_HIGHLIGHTS,
  ROUTE_AUDIENCES,
  ROUTE_CONTENT,
  ROUTE_PAYWALL,
  ROUTE_SETTINGS,
  ROUTE_TAGS,
  ROUTE_CSV,
  ROUTE_UPLOAD_PERSONA,
  ROUTE_STATISTICS_REPORT
} from "util/Routes.const"
import {
  APP_TYPE,
  APP_TYPE_ADMIN,
  SHOW_ROUTE_HIGHLIGHTS,
  ENABLE_ROUTE_HIGHLIGHTS,
  SHOW_ROUTE_AUDIENCES,
  ENABLE_ROUTE_AUDIENCES,
  SHOW_ROUTE_CONTENT,
  ENABLE_ROUTE_CONTENT,
  SHOW_ROUTE_PAYWALL,
  ENABLE_ROUTE_PAYWALL,
  SHOW_ROUTE_CSV,
  ENABLE_ROUTE_CSV,
  SHOW_ROUTE_UPLOAD_PERSONA,
  ENABLE_ROUTE_UPLOAD_PERSONA,
  ENABLE_ROUTE_STATISTICS_REPORT,
  SHOW_ROUTE_STATISTICS_REPORT,
  SHOW_N_ENABLE_FILTER,
  SHOW_N_ENABLE_ALL
} from "util/const"

// Integration-wide styling
import "./Sidebar.css"

// SVG assets
import { ReactComponent as HighlightsIcon } from "assets/icons_mini/highlights.svg"
import { ReactComponent as AudiencesIcon } from "assets/icons_mini/audiences.svg"
import { ReactComponent as ContentIcon } from "assets/icons_mini/content.svg"
import { ReactComponent as PaywallIcon } from "assets/icons_mini/paywall.svg"
import { ReactComponent as TagsIcon } from "assets/icons_mini/tags.svg"
import { ReactComponent as DownloadIcon } from "assets/icons_mini/download.svg"
import { ReactComponent as UploadIcon } from "assets/icons_mini/upload.svg"
import { ReactComponent as SettingsIcon } from "assets/icons_mini/settings.svg"
import { ReactComponent as StatisticsIcon } from "assets/icons_mini/statistics.svg"
import { ReactComponent as NucleusLogoIcon } from "assets/logo-icon.svg"
import { ReactComponent as NucleusLogoText } from "assets/logo-text.svg"

// Primary navigation bar
export const Sidebar = is => {
  const isAdminApp = APP_TYPE === APP_TYPE_ADMIN

  const sources = useRef()
  const auth = useAuth()

  const pathname = useLocation().pathname
  const pick = me => "sidebar__list__bullet" + (pathname.includes(me) ? " sidebar__list__bullet--select" : "")

  const WrapLink = ({ route, shouldWrap, children }) => (
    shouldWrap
      ? (
        <Link to={route}>
          {children}
        </Link>
      ) : children
  )

  return (
    <nav className="sidebar">

      {/* Head */}
      <Link to={`/${ROUTE_HIGHLIGHTS}`}>
        <div className="sidebar__head">
          <NucleusLogoIcon className="sidebar__head__icon" />
          <NucleusLogoText className="sidebar__head__text" />
        </div>
      </Link>
      <div className="sidebar__slit"></div>

      {/* Main */}
      <ul className="sidebar__list">
        {(SHOW_N_ENABLE_ALL || SHOW_ROUTE_HIGHLIGHTS) && (
          <WrapLink route={`/${ROUTE_HIGHLIGHTS}`} shouldWrap={SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HIGHLIGHTS}>
            <li className={`${pick(`/${ROUTE_HIGHLIGHTS}`)} ${SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HIGHLIGHTS ? '' : 'sidebar__list__bullet--inactive'}`}>
              <HighlightsIcon className="sidebar__list__bullet__icon" />
              <span className={`sidebar__list__bullet__label text-m`}>Highlights</span>
            </li>
          </WrapLink>
        )}
        {(SHOW_N_ENABLE_ALL || SHOW_ROUTE_AUDIENCES) && (
          <WrapLink route={`/${ROUTE_AUDIENCES}`} shouldWrap={SHOW_N_ENABLE_ALL || ENABLE_ROUTE_AUDIENCES}>
            <li className={`${pick(`/${ROUTE_AUDIENCES}`)} ${SHOW_N_ENABLE_ALL || ENABLE_ROUTE_AUDIENCES ? '' : 'sidebar__list__bullet--inactive'}`}>
              <AudiencesIcon className="sidebar__list__bullet__icon sidebar__list__bullet__icon-rotated" />
              <span className={`sidebar__list__bullet__label text-m`}>Audiences</span>
            </li>
          </WrapLink>
        )}
        {(SHOW_N_ENABLE_ALL || SHOW_ROUTE_CONTENT) && (
          <WrapLink route={`/${ROUTE_CONTENT}`} shouldWrap={SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CONTENT}>
            <li className={`${pick(`/${ROUTE_CONTENT}`)} ${SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CONTENT ? '' : 'sidebar__list__bullet--inactive'}`}>
              <ContentIcon className="sidebar__list__bullet__icon" />
              <span className={`sidebar__list__bullet__label text-m`}>Content</span>
            </li>
          </WrapLink>
        )}
        {(SHOW_N_ENABLE_ALL || SHOW_ROUTE_PAYWALL) && (
          <WrapLink route={`/${ROUTE_PAYWALL}`} shouldWrap={SHOW_N_ENABLE_ALL || ENABLE_ROUTE_PAYWALL}>
            <li className={`${pick(`/${ROUTE_PAYWALL}`)} ${SHOW_N_ENABLE_ALL || ENABLE_ROUTE_PAYWALL ? '' : 'sidebar__list__bullet--inactive'}`}>
              <PaywallIcon className="sidebar__list__bullet__icon sidebar__list__bullet__icon-rotated sidebar__list__bullet__icon-squared" />
              <span className={`sidebar__list__bullet__label text-m`}>Paywall</span>
            </li>
          </WrapLink>
        )}

        {/* Admin only start */}
        {(isAdminApp && (SHOW_N_ENABLE_ALL || SHOW_ROUTE_CSV)) && (
          <WrapLink route={`/${ROUTE_CSV}`} shouldWrap={SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CSV}>
            <li className={`${pick(`/${ROUTE_CSV}`)} ${SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CSV ? '' : 'sidebar__list__bullet--inactive'}`}>
              <DownloadIcon className="sidebar__list__bullet__icon sidebar__list__bullet__icon-squared" />
              <span className={`sidebar__list__bullet__label text-m`}>CSV</span>
            </li>
          </WrapLink>
        )}
        {(isAdminApp && (SHOW_N_ENABLE_ALL || SHOW_ROUTE_UPLOAD_PERSONA)) && (
          <WrapLink route={`/${ROUTE_UPLOAD_PERSONA}`} shouldWrap={SHOW_N_ENABLE_ALL || ENABLE_ROUTE_UPLOAD_PERSONA}>
            <li className={`${pick(`/${ROUTE_UPLOAD_PERSONA}`)} ${SHOW_N_ENABLE_ALL || ENABLE_ROUTE_UPLOAD_PERSONA ? '' : 'sidebar__list__bullet--inactive'}`}>
              <UploadIcon className="sidebar__list__bullet__icon sidebar__list__bullet__icon-squared" />
              <span className={`sidebar__list__bullet__label text-m`}>Upload persona</span>
            </li>
          </WrapLink>
        )}
        {(isAdminApp && (SHOW_N_ENABLE_ALL || SHOW_ROUTE_STATISTICS_REPORT)) && (
          <WrapLink route={`/${ROUTE_STATISTICS_REPORT}`} shouldWrap={SHOW_N_ENABLE_ALL || ENABLE_ROUTE_STATISTICS_REPORT}>
            <li className={`${pick(`/${ROUTE_STATISTICS_REPORT}`)} ${SHOW_N_ENABLE_ALL || ENABLE_ROUTE_STATISTICS_REPORT ? '' : 'sidebar__list__bullet--inactive'}`}>
              <StatisticsIcon className="sidebar__list__bullet__icon sidebar__list__bullet__icon-rotated sidebar__list__bullet__icon-squared" />
              <span className={`sidebar__list__bullet__label text-m`}>Statistics report</span>
            </li>
          </WrapLink>
        )}

        {/* Admin only end */}
      </ul>

      {/* Multipurpose sidebar block (e.g. global tag filter) */}
      <div className="sidebar__foot-pre">
        {(SHOW_N_ENABLE_ALL || SHOW_N_ENABLE_FILTER) && <Filter multi={/*origin.length > 1 TODO: Switcher logic after [chekbox/and] multidomain is supported */false} domains={auth?.context?.properties?.map((domain) => {
          // TODO: Refactor an create separate function
          // Fixes runtime error for parsing not parsable urls
          try {
            const url = new URL(domain?.url)
            return url?.hostname
          } catch (error) {
            return domain?.url || ''
          }
        })} choices={
          (pick) => {
            auth?.context?.audiences?.origin(auth?.context?.properties?.[pick]?.public_key?.value)
          }
        } />}
      </div>

      {/* Foot */}
      <div className="sidebar__foot">
        <ul className="sidebar__list">
          <Link to={`/${ROUTE_TAGS}`} state={{ picks: sources.current }}>
            <li className={pick(`/${ROUTE_TAGS}`)}>
              <TagsIcon className="sidebar__list__bullet__icon sidebar__list__bullet__icon-rotated sidebar__list__bullet__icon-squared" />
              <span className={`sidebar__list__bullet__label text-m`}>Tags</span>
            </li>
          </Link>
          {!isAdminApp && (
            <Link to={`/${ROUTE_SETTINGS}`}>
              <li className={pick(`/${ROUTE_SETTINGS}`)}>
                <SettingsIcon className="sidebar__list__bullet__icon sidebar__list__bullet__icon-rotated" />
                <span className={`sidebar__list__bullet__label text-m`}>Settings</span>
              </li>
            </Link>
          )}
        </ul>
        <div className="sidebar__slit"></div>
        <div className="sidebar__foot__avatar">
          <Avatar user={auth?.context?.user} />
        </div>
      </div>
    </nav>
  )
}