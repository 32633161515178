import { 
  Counter,
  Tick
} from "components"

import "./Topbar.css"

// Compute deltas (dummy/preliminary version)
const getDelta = (item) => {
  return item.unit === "%" 
    ? (item.data[0] - item.data[1]) / (item.data[1] / 100)
    : item.data[0] - item.data[item.data.length - 1]
}
let delta = 0

export const Topbar = ({data}) => {
  return (
    // TODO: 
    //    - Pad from middleline (not from top/bottom edges)
    //    - Clip content adaptatively (acronyms, xK-like suffxes, hide tail, ...)
    //    - List/drop-down mode for mobile devices
    //    - Filtering support
    //    - Right helper grey box should be fixed in width 
    <div className="topbar">
      <div className="topbar__filter">
        <span className="text-m"><b>Daily personas</b>{/*<span className="topbar__suffix-icon"></span>*/}</span><br/>
        <span className="text-s">All your sites and apps</span>
      </div>
      {data?.map((item, index) => {
        return (
          <div key={index} className="topbar__chip">
            <div className="topbar__chip__label">
              <span className="text-s">{item.name}</span>
            </div>
            <div className="topbar__chip__data">
              <span className="text-l"><b><Counter value={item.data[1]} meta={(delta = getDelta(item)) > 0}/></b></span>
              <Tick delta={delta}/>
              <span className="topbar__chip__data__tail text-xs">vs last<br/>month</span>
            </div>
          </div>
      )
      })}
    </div>
  )
}