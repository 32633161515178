import { 
  useState,
  useEffect
} from "react"

// Audiences-wide styling
import "./Audiences.css"

// Containers
import { 
    Layout,
    Grid
} from "containers"

// Components
import { 
  Navbar,
  Topbar,
  Persona
} from "components"

// ...
import { 
  useAuth
} from "contexts"

// Make the deck adaptive (the JS way b/c the grid engine can't control/know its parent arrangement choice/s)
const checkColumns = () => {
  
  /* TODO: Get inner width from content div instead */
  const sideBarNone = window.innerWidth < 768
  const sideBarMini = window.innerWidth < 1024
  const contentNavbar = sideBarNone ? 0 : 90
  const contentSidebar = sideBarMini ? (sideBarNone ? (90 + 10)/*smoother adaptive transition into 768*/ + 10 : 70) : 200 + 10/*leftmost gap*/
  const contentViewport = window.innerWidth - contentSidebar - contentNavbar
  return Math.floor(contentViewport / (320 + 10/*in-between gaps*/))
}

// Audiences parametric assembly
export const Audiences = () => {
  const auth = useAuth()
  // ---
  // DEV
  // const personas = new Array(9).fill(auth?.context?.audiences?.personas[0])

  // PROD
  const personas = auth?.context?.audiences?.personas
  // ---
  const deckSize = personas?.length
  const [preColumns, setColumns] = useState(checkColumns)

  useEffect(() => {
    const resetSidebar = () => {
      const newColumns = checkColumns()
      if (newColumns !== preColumns) {
        window.removeEventListener("resize", resetSidebar)
        setColumns(newColumns)
      }
    }

    /* TODO: Use useEffect or equivalent w/ checkColumns() as input instead (alt: resize observer) */
    window.addEventListener("resize", resetSidebar)
  }, [preColumns])

  /* TODO:
     - Autoscroll
     - Horizontal animated arrow [w/ indent on card]
     - Smart horizontal resize [w/ added gaps/divs if below per-viewport-width columns]
  */
  return (
      deckSize ? 
      <Layout
        header={<Topbar data={personas[0]?.metrics}/>} 
        scroll={<Navbar dots={personas.map((x) => x.escore[x.escore.length - 1]).sort()}/>}
      >
      <Grid
        content={new Array(deckSize).fill().map((_, idx) => { 
          return <Persona report={personas[idx]} banner={idx === 0 ? "most" : (idx === deckSize - 1 ? "least" : "average")}/>
        })}
        template={
          `${(new Array(/* rows = */(Math.ceil(deckSize / preColumns) * preColumns) || 0).fill()).map((_, idx) => {
            return (idx % preColumns ? "" : "\n") + idx
          }).join(" ")}\n` //+ "? ".repeat(preColumns)
        }
        cellSize={{
          w: 320, 
          h: preColumns > 1 ? 640 : null
        }}
      >
      </Grid>
    </Layout> : <Layout/>
)}