import { useState, useEffect } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"

// Containers
import { Layout } from "containers"

// Components
import { InstallationWizard, InstallationScript, EditExistingWizard } from "components"

// Service
import { useFetch } from "hooks/fetch"

// Util
import { API_NUCLEUS_BACKEND_PROPERTY } from "util/service.const"
import { STATUS_FAILURE } from "util/Status"
import { SEARCH_PARAM_APP_TYPE, SEARCH_PARAM_URL, SEARCH_PARAM_STEP } from "util/SearchParams.const"
import { ROUTE_SETTINGS } from "util/Routes.const"
import { APP_TYPE, APP_TYPE_ADMIN, WITH_SPA } from "util/const"

// Styling
import "./Installation.css"

const MESSAGE_TYPES = {
    SUCCESS: "success",
    ERROR: "error"
}

const MESSAGE_DEFAULT_STATE = {
    text: "",
    type: null
}

const STEPS = {
    PROPERTY: "property",
    INSTALLATION_SCRIPT: "installation",
    EDIT: "edit"
}

const DEFAULT_MESSAGE_TIMEOUT = 3000

export const Installation = ({ googleUser, properties, getProperties }) => {
    const isAdminApp = APP_TYPE === APP_TYPE_ADMIN

    const currentProperties = properties || []
    const userToken = googleUser?.credential

    const [message, setMessage] = useState(MESSAGE_DEFAULT_STATE)
    const [currentStep, setCurrentStep] = useState(STEPS.PROPERTY)
    const [selectedProperty, setSelectedProperty] = useState(null)

    const fetch = useFetch()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    // TODO: Create message context
    const showMessage = (message, type, callback = () => { }, timeout = DEFAULT_MESSAGE_TIMEOUT) => {
        setMessage({ text: message, type })
        setTimeout(() => {
            setMessage(MESSAGE_DEFAULT_STATE)
            callback && callback()
        }, timeout)
    }

    const showErrorMessage = (message, callback) => {
        showMessage(message, MESSAGE_TYPES.ERROR, callback)
    }

    const showSuccessMessage = (message, callback) => {
        showMessage(message, MESSAGE_TYPES.SUCCESS, callback)
    }

    const onCreateProperty = async ({ url, isNew, type, with_consent = false, is_spa = false }) => {
        const response = await fetch({
            url: API_NUCLEUS_BACKEND_PROPERTY,
            method: "POST",
            token: userToken,
            body: { url, is_new: isNew, type, with_consent, is_spa }
        })

        const { status, payload } = response

        if (STATUS_FAILURE === status && "APP_ALREADY_EXISTS" === payload?.code) {
            showErrorMessage("App already exists. Choose another app or another property and try again")
            return
        }
        
        if (STATUS_FAILURE === status && "URL_MAY_EXISTS" === payload?.code) {
            showErrorMessage("App with URL may already exists. Try another one or try again later.")
            return
        }

        if (STATUS_FAILURE === status) {
            showErrorMessage("Could not create app or property. Try again later")
            return
        }

        showSuccessMessage("Property or app created")
        await getProperties()
        setSearchParams(`${SEARCH_PARAM_STEP}=${STEPS.INSTALLATION_SCRIPT}&${SEARCH_PARAM_APP_TYPE}=${type}&${SEARCH_PARAM_URL}=${payload.url}`)
    }

    const onUpdateProperty = async ({ propertyToken, body }) => {
        if (!propertyToken) {
            showErrorMessage("Could not update property. Missing token.")
            return
        }

        // Save data
        const response = await fetch({
            url: isAdminApp ? `${API_NUCLEUS_BACKEND_PROPERTY}/admin/${propertyToken}` : `${API_NUCLEUS_BACKEND_PROPERTY}/${propertyToken}`,
            method: "PUT",
            token: userToken,
            body: body
        })

        const { status } = response

        if (STATUS_FAILURE === status) {
            showErrorMessage("Could not update app or property. Try again later")
            return
        }

        // Go to settings page
        showSuccessMessage("Property Updated")
        setTimeout(async () => {
            navigate(`/${ROUTE_SETTINGS}`)
            await getProperties()
        }, 1000);
    }

    const onPropertyChange = (currentProperty) => {
        // TODO: Refactor and DRY out
        const matchingProperties = properties.filter(({ url }) => url === currentProperty)
        const doesExists = 1 === matchingProperties?.length
        doesExists && setSelectedProperty(matchingProperties[0])
        doesExists && setSearchParams(`${SEARCH_PARAM_URL}=${currentProperty}&${SEARCH_PARAM_APP_TYPE}=${searchParams.get(SEARCH_PARAM_APP_TYPE)}`)
    }

    useEffect(() => {
        if (STEPS.INSTALLATION_SCRIPT === searchParams.get(SEARCH_PARAM_STEP)) {
            // TODO: Refactor and DRY out
            setCurrentStep(STEPS.INSTALLATION_SCRIPT)
            const matchingProperties = properties.filter(({ url }) => url === searchParams.get(SEARCH_PARAM_URL))
            const doesExists = 1 === matchingProperties?.length
            doesExists && setSelectedProperty(matchingProperties[0])
        }

        if (searchParams.get(SEARCH_PARAM_URL)) {
            // TODO: Refactor and DRY out
            const matchingProperties = properties.filter(({ url }) => url === searchParams.get(SEARCH_PARAM_URL))
            const doesExists = 1 === matchingProperties?.length
            doesExists && setSelectedProperty(matchingProperties[0])
        }

        if (STEPS.EDIT === searchParams.get(SEARCH_PARAM_STEP)) {
            // TODO: Refactor and DRY out
            setCurrentStep(STEPS.EDIT)
        }
    }, [searchParams, properties])

    useEffect(() => {
        if (!(STEPS.INSTALLATION_SCRIPT === currentStep)) {
            return
        }
    }, [currentStep])

    useEffect(() => {
        if (!selectedProperty) {
            return
        }

        if (currentStep === STEPS.EDIT) {
            return
        }

        setCurrentStep(STEPS.INSTALLATION_SCRIPT)
    }, [selectedProperty, currentStep])

    const propertyToken = selectedProperty?.public_key?.value
    const propertyUrl = selectedProperty?.url
    const propertyWithConsent = selectedProperty?.with_consent
    const propertyWithSpa = WITH_SPA ? selectedProperty?.is_spa : false

    return (
        <Layout header={"Installation"}>
            {STEPS.PROPERTY === currentStep && <InstallationWizard properties={currentProperties} onSubmit={onCreateProperty} onPropertyChange={onPropertyChange} />}
            {STEPS.INSTALLATION_SCRIPT === currentStep && <InstallationScript token={propertyToken} url={propertyUrl} withConsent={propertyWithConsent} withSpa={propertyWithSpa} showSuccessMessage={showSuccessMessage} />}
            {STEPS.EDIT === currentStep && <EditExistingWizard propertyToken={propertyToken} propertyUrl={propertyUrl} onSubmit={onUpdateProperty} withConsent={propertyWithConsent} withSpa={propertyWithSpa} />}
            {message?.text && message?.type && (
                <p
                    className={`message
                        ${MESSAGE_TYPES.ERROR === message.type ? "message--error" : ""}
                        ${MESSAGE_TYPES.SUCCESS === message.type ? "message--success" : ""}
                    `}
                >{message.text}</p>
            )}
        </Layout>
    )
}