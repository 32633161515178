const {
	REACT_APP_GA_CLIENT_ID,
	REACT_APP_TYPE,
	REACT_APP_WITH_CONSENT,
	REACT_APP_WITH_CUSTOM_USER_ID,
	REACT_APP_WITH_IS_SPA,

	REACT_APP_SHOW_ROUTE_HIGHLIGHTS,
	REACT_APP_ENABLE_ROUTE_HIGHLIGHTS,
	REACT_APP_SHOW_ROUTE_AUDIENCES,
	REACT_APP_ENABLE_ROUTE_AUDIENCES,
	REACT_APP_SHOW_ROUTE_CONTENT,
	REACT_APP_ENABLE_ROUTE_CONTENT,
	REACT_APP_SHOW_ROUTE_PAYWALL,
	REACT_APP_ENABLE_ROUTE_PAYWALL,
	REACT_APP_SHOW_ROUTE_CSV,
	REACT_APP_ENABLE_ROUTE_CSV,
	REACT_APP_SHOW_ROUTE_UPLOAD_PERSONA,
	REACT_APP_ENABLE_ROUTE_UPLOAD_PERSONA,
	REACT_APP_SHOW_ROUTE_STATISTICS_REPORT,
	REACT_APP_ENABLE_ROUTE_STATISTICS_REPORT,
	REACT_APP_SHOW_N_ENABLE_FILTER,
	REACT_APP_SHOW_N_ENABLE_ALL
} = process.env

export const GA_CLIENT_ID = REACT_APP_GA_CLIENT_ID

// App types
export const APP_TYPE = REACT_APP_TYPE

export const APP_TYPE_ADMIN = "ADMIN"

// Expose routes
// Show = visible
// Enable = active
export const SHOW_ROUTE_HIGHLIGHTS = +REACT_APP_SHOW_ROUTE_HIGHLIGHTS === 1
export const ENABLE_ROUTE_HIGHLIGHTS = +REACT_APP_ENABLE_ROUTE_HIGHLIGHTS === 1
export const SHOW_ROUTE_AUDIENCES = +REACT_APP_SHOW_ROUTE_AUDIENCES === 1
export const ENABLE_ROUTE_AUDIENCES = +REACT_APP_ENABLE_ROUTE_AUDIENCES === 1
export const SHOW_ROUTE_CONTENT = +REACT_APP_SHOW_ROUTE_CONTENT === 1
export const ENABLE_ROUTE_CONTENT = +REACT_APP_ENABLE_ROUTE_CONTENT === 1
export const SHOW_ROUTE_PAYWALL = +REACT_APP_SHOW_ROUTE_PAYWALL === 1
export const ENABLE_ROUTE_PAYWALL = +REACT_APP_ENABLE_ROUTE_PAYWALL === 1
export const SHOW_ROUTE_CSV = +REACT_APP_SHOW_ROUTE_CSV === 1
export const ENABLE_ROUTE_CSV = +REACT_APP_ENABLE_ROUTE_CSV === 1
export const ENABLE_ROUTE_UPLOAD_PERSONA = +REACT_APP_SHOW_ROUTE_UPLOAD_PERSONA === 1
export const SHOW_ROUTE_UPLOAD_PERSONA = +REACT_APP_ENABLE_ROUTE_UPLOAD_PERSONA === 1
export const ENABLE_ROUTE_STATISTICS_REPORT = +REACT_APP_SHOW_ROUTE_STATISTICS_REPORT === 1
export const SHOW_ROUTE_STATISTICS_REPORT = +REACT_APP_ENABLE_ROUTE_STATISTICS_REPORT === 1
export const SHOW_N_ENABLE_FILTER = +REACT_APP_SHOW_N_ENABLE_FILTER === 1
export const SHOW_N_ENABLE_ALL = +REACT_APP_SHOW_N_ENABLE_ALL === 1

// Util
export const WITH_CONSENT = +REACT_APP_WITH_CONSENT === 1
export const WITH_CUSTOM_USER_ID = +REACT_APP_WITH_CUSTOM_USER_ID === 1
export const WITH_SPA = +REACT_APP_WITH_IS_SPA === 1
