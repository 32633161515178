import { useState } from "react"

// Hooks
import { useFetch } from "hooks/fetch"

// Containers
import { Layout } from "containers"

// Components
import { AdminInstallationTable } from "components"

// Util
import { API_NUCLEUS_BACKEND_PROPERTY_STATUS } from "util/service.const"
import { STATUS_FAILURE } from "util/Status"

export const AdminTags = ({ googleUser, properties }) => {
    const [localProperties, setLocalProperties] = useState(properties)
    const [isLoading, setLoading] = useState(false)

    const fetch = useFetch()

    const onFilterProperties = (search) => {
        if (!search) {
            setLocalProperties(properties)
            return
        }

        const filteredProperties = properties.filter(property => new RegExp(search, "gi").test(property.url))
        setLocalProperties(filteredProperties)
    }

    const getPropertyStatus = async (property) => {
        try {
            setLoading(true)
            const token = googleUser?.credential

            if (!token) {
                return
            }

            const publicKey = property?.public_key?.value

            if (!publicKey) {
                // TODO: Error Handling
                return
            }

            const response = await fetch({
                url: `${API_NUCLEUS_BACKEND_PROPERTY_STATUS}/admin/${publicKey}?shouldGroupByApp=1`,
                token
            })

            const { status, payload } = response

            if (STATUS_FAILURE === status) {
                return
            }

            // TODO: Logic when response is grouped by app
            let propertyIndex = localProperties.findIndex(prop => prop.url === property.url)

            let newProperties = localProperties

            if (!newProperties) {
                return
            }

            const newApps = newProperties[propertyIndex]?.apps?.map(app => {
                const totalForApp = payload?.total_visits.find(visit => visit.app_type === app.type)

                let progress = 0

                if (totalForApp?.total && payload?.limit) {
                    progress = (totalForApp?.total <= payload?.limit) ? (+totalForApp?.total / +payload?.limit) * 100 : 100
                }

                return {
                    ...app,
                    total_visits: +totalForApp?.total || 0,
                    progress
                }
            })

            newProperties[propertyIndex] = {
                ...newProperties[propertyIndex],
                apps: newApps,
                total_visits_aggregated: payload?.total_visits_aggregated || 0,
                total_limit: payload?.limit
            }

            setLocalProperties(newProperties)

        } catch (error) {
            console.error("Could not retrieve property status", error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Layout header={"Overview"} noOverflowContent>
            <AdminInstallationTable
                properties={localProperties}
                getPropertyStatus={getPropertyStatus}
                loading={isLoading}
                onFilterProperties={onFilterProperties}
            />
        </Layout>
    )
}