import {
    useState
  } from "react"
  
// Sytling
import "./Wheel.css"

// Icons
import { ReactComponent as IconHelp } from "assets/icons_mini/help.svg"

import {
    Arc,
    Counter
  } from "components"

export const Wheel = ({ score, scoreSecondary }) => {
    const [popUp, setPopUp] = useState(false)

    return (
    /* TODO
       - Divisory [arc] lines are x-side misaligned [by ~1px].
       - History chart should be made adaptive on resize (and on mobile for 1:1 ratio)
    */
    <div className="card-wheel">
        <div className="card-wheel-counter">
            <span className="card-wheel-counter__head text-s">Overall</span>
            <Counter value={score}/>
            <span className="card-wheel-counter__foot text-s">/100</span>
        </div>
        <div className="card-wheel-header text-xs">
            <b>Score</b><br/>
            <span>Learn how engaged your audience is this week</span>
        </div>
        <div className="card-wheel-footer text-xs" >
            <IconHelp className="card-wheel-icon" onMouseEnter={() => setPopUp(true)} onMouseLeave={() => setPopUp(false)}/>
            How the eScore<br/>
            is calculated
        </div>
        <div className={`help ${popUp && "help--show"} text-xs-invert`}>
            <b>Engagement score</b>
            <span>Lorem ipsum + foo bar + something</span>
        </div>
        {/* TODO: Compute width from box height -> width: boxheight * 47% */}
        <div className="card-wheel-arc">
            <Arc target={score} targetSecondary={scoreSecondary}/> 
        </div>
    </div>
)}