import { 
    useRef
    // useLayoutEffect
  } from "react"

// Sytling
import "./Persona.css"

import { 
    Arc,
    Counter
  } from "components"

// Svg icons (dimensions)
// TODO: Make dynamic or load from CDN
/* 
Browser */
import { ReactComponent as IconBrave } from "assets/icons/browser/brave.svg"
import { ReactComponent as IconChrome } from "assets/icons/browser/chrome.svg"
import { ReactComponent as IconDuckduckgo } from "assets/icons/browser/duckduckgo.svg"
import { ReactComponent as IconEdge } from "assets/icons/browser/edge.svg"
import { ReactComponent as IconFirefox } from "assets/icons/browser/firefox.svg"
import { ReactComponent as IconOpera } from "assets/icons/browser/opera.svg"
import { ReactComponent as IconSafari } from "assets/icons/browser/safari.svg"
import { ReactComponent as IconSamsunginternet } from "assets/icons/browser/samsunginternet.svg"
/* 
Category */
import { ReactComponent as IconScience } from "assets/icons/category/science.svg"
import { ReactComponent as IconTravel } from "assets/icons/category/travel.svg"
/* 
Channel */
import { ReactComponent as IconEmail } from "assets/icons/channel/email.svg"
/* 
Device */
import { ReactComponent as IconAndroidphone } from "assets/icons/device/androidphone.svg"
import { ReactComponent as IconIMac } from "assets/icons/device/imac.svg"
import { ReactComponent as IconIphone } from "assets/icons/device/iphone.svg"
import { ReactComponent as IconWindowspc } from "assets/icons/device/windowspc.svg"
/* 
Location */
// import { ReactComponent as IconBerlin } from "assets/icons/location/berlin.svg"
// import { ReactComponent as IconChicago } from "assets/icons/location/chicago.svg"
// import { ReactComponent as IconCopenhagen } from "assets/icons/location/copenhagen.svg"
// import { ReactComponent as IconDetroit } from "assets/icons/location/detroit.svg"
// import { ReactComponent as IconDublin } from "assets/icons/location/dublin.svg"
// import { ReactComponent as IconHelsinki } from "assets/icons/location/helsinki.svg"
// import { ReactComponent as IconIndiana } from "assets/icons/location/indiana.svg"
// import { ReactComponent as IconKiev } from "assets/icons/location/kiev.svg"
// import { ReactComponent as IconKuwait } from "assets/icons/location/kuwait.svg"
// import { ReactComponent as IconLondon } from "assets/icons/location/london.svg"
// import { ReactComponent as IconLosangeles } from "assets/icons/location/losangeles.svg"
// import { ReactComponent as IconMadrid } from "assets/icons/location/madrid.svg"
// import { ReactComponent as IconManila } from "assets/icons/location/manila.svg"
// import { ReactComponent as IconNewyork } from "assets/icons/location/newyork.svg"
// import { ReactComponent as IconOslo } from "assets/icons/location/oslo.svg"
// import { ReactComponent as IconPrague } from "assets/icons/location/prague.svg"
// import { ReactComponent as IconRome } from "assets/icons/location/rome.svg"
// import { ReactComponent as IconSaopaulo } from "assets/icons/location/saopaulo.svg"
// import { ReactComponent as IconStockholm } from "assets/icons/location/stockholm.svg"

import { ReactComponent as IconAashgabat } from "assets/icons/location/aashgabat.svg"
import { ReactComponent as IconAbidjan } from "assets/icons/location/abidjan.svg" 
import { ReactComponent as IconAccra } from "assets/icons/location/accra.svg" 
import { ReactComponent as IconAcre } from "assets/icons/location/acre.svg" 
import { ReactComponent as IconAct } from "assets/icons/location/act.svg" 
import { ReactComponent as IconAdak } from "assets/icons/location/adak.svg" 
import { ReactComponent as IconAddisababa } from "assets/icons/location/addisababa.svg" 
import { ReactComponent as IconAdelaide } from "assets/icons/location/adelaide.svg" 
import { ReactComponent as IconAden } from "assets/icons/location/aden.svg" 
import { ReactComponent as IconAlaska } from "assets/icons/location/alaska.svg" 
import { ReactComponent as IconAleutian } from "assets/icons/location/aleutian.svg" 
import { ReactComponent as IconAlgiers } from "assets/icons/location/algiers.svg" 
import { ReactComponent as IconAlmaty } from "assets/icons/location/almaty.svg" 
import { ReactComponent as IconAmman } from "assets/icons/location/amman.svg" 
import { ReactComponent as IconAmsterdam } from "assets/icons/location/amsterdam.svg" 
import { ReactComponent as IconAnadyr } from "assets/icons/location/anadyr.svg" 
import { ReactComponent as IconAnchorage } from "assets/icons/location/anchorage.svg" 
import { ReactComponent as IconAndorra } from "assets/icons/location/andorra.svg" 
import { ReactComponent as IconAnguilla } from "assets/icons/location/anguilla.svg" 
import { ReactComponent as IconAntananarivo } from "assets/icons/location/antananarivo.svg" 
import { ReactComponent as IconAntigua } from "assets/icons/location/antigua.svg" 
import { ReactComponent as IconApia } from "assets/icons/location/apia.svg" 
import { ReactComponent as IconAqtau } from "assets/icons/location/aqtau.svg" 
import { ReactComponent as IconAqtobe } from "assets/icons/location/aqtobe.svg" 
import { ReactComponent as IconAraguaiana } from "assets/icons/location/araguaiana.svg" 
import { ReactComponent as IconArizona } from "assets/icons/location/arizona.svg" 
import { ReactComponent as IconAruba } from "assets/icons/location/aruba.svg" 
import { ReactComponent as IconAshkhabad } from "assets/icons/location/ashkhabad.svg" 
import { ReactComponent as IconAsmara } from "assets/icons/location/asmara.svg" 
import { ReactComponent as IconAsmera } from "assets/icons/location/asmera.svg" 
import { ReactComponent as IconAstrakhan } from "assets/icons/location/astrakhan.svg" 
import { ReactComponent as IconAsuncion } from "assets/icons/location/asuncion.svg" 
import { ReactComponent as IconAthens } from "assets/icons/location/athens.svg" 
import { ReactComponent as IconAtikokan } from "assets/icons/location/atikokan.svg" 
import { ReactComponent as IconAtka } from "assets/icons/location/atka.svg" 
import { ReactComponent as IconAtlantic } from "assets/icons/location/atlantic.svg" 
import { ReactComponent as IconAtyrau } from "assets/icons/location/atyrau.svg" 
import { ReactComponent as IconAuckland } from "assets/icons/location/auckland.svg" 
import { ReactComponent as IconAzores } from "assets/icons/location/azores.svg" 
import { ReactComponent as IconBaghdad } from "assets/icons/location/baghdad.svg" 
import { ReactComponent as IconBahia } from "assets/icons/location/bahia.svg" 
import { ReactComponent as IconBahiabanderas } from "assets/icons/location/bahiabanderas.svg" 
import { ReactComponent as IconBahrain } from "assets/icons/location/bahrain.svg" 
import { ReactComponent as IconBajanorte } from "assets/icons/location/bajanorte.svg" 
import { ReactComponent as IconBajasur } from "assets/icons/location/bajasur.svg" 
import { ReactComponent as IconBaku } from "assets/icons/location/baku.svg" 
import { ReactComponent as IconBamako } from "assets/icons/location/bamako.svg" 
import { ReactComponent as IconBangkok } from "assets/icons/location/bangkok.svg" 
import { ReactComponent as IconBangui } from "assets/icons/location/bangui.svg" 
import { ReactComponent as IconBanjul } from "assets/icons/location/banjul.svg" 
import { ReactComponent as IconBarbados } from "assets/icons/location/barbados.svg" 
import { ReactComponent as IconBarnaul } from "assets/icons/location/barnaul.svg" 
import { ReactComponent as IconBeirut } from "assets/icons/location/beirut.svg" 
import { ReactComponent as IconBelem } from "assets/icons/location/belem.svg" 
import { ReactComponent as IconBelfast } from "assets/icons/location/belfast.svg" 
import { ReactComponent as IconBelgrade } from "assets/icons/location/belgrade.svg" 
import { ReactComponent as IconBelize } from "assets/icons/location/belize.svg" 
import { ReactComponent as IconBerlin } from "assets/icons/location/berlin.svg" 
import { ReactComponent as IconBermuda } from "assets/icons/location/bermuda.svg" 
import { ReactComponent as IconBeulah } from "assets/icons/location/beulah.svg" 
import { ReactComponent as IconBishkek } from "assets/icons/location/bishkek.svg" 
import { ReactComponent as IconBissau } from "assets/icons/location/bissau.svg" 
import { ReactComponent as IconBlancsablon } from "assets/icons/location/blancsablon.svg" 
import { ReactComponent as IconBlantyre } from "assets/icons/location/blantyre.svg" 
import { ReactComponent as IconBoavista } from "assets/icons/location/boavista.svg" 
import { ReactComponent as IconBogota } from "assets/icons/location/bogota.svg" 
import { ReactComponent as IconBoise } from "assets/icons/location/boise.svg" 
import { ReactComponent as IconBougainville } from "assets/icons/location/bougainville.svg" 
import { ReactComponent as IconBratislava } from "assets/icons/location/bratislava.svg" 
import { ReactComponent as IconBrazzaville } from "assets/icons/location/brazzaville.svg" 
import { ReactComponent as IconBrisbane } from "assets/icons/location/brisbane.svg" 
import { ReactComponent as IconBrokenhill } from "assets/icons/location/brokenhill.svg" 
import { ReactComponent as IconBrunei } from "assets/icons/location/brunei.svg" 
import { ReactComponent as IconBrussels } from "assets/icons/location/brussels.svg" 
import { ReactComponent as IconBucharest } from "assets/icons/location/bucharest.svg" 
import { ReactComponent as IconBudapest } from "assets/icons/location/budapest.svg" 
import { ReactComponent as IconBuenosaires } from "assets/icons/location/buenosaires.svg" 
import { ReactComponent as IconBujumbura } from "assets/icons/location/bujumbura.svg" 
import { ReactComponent as IconBusingen } from "assets/icons/location/busingen.svg" 
import { ReactComponent as IconCairo } from "assets/icons/location/cairo.svg" 
import { ReactComponent as IconCalcutta } from "assets/icons/location/calcutta.svg" 
import { ReactComponent as IconCambridgebay } from "assets/icons/location/cambridgebay.svg" 
import { ReactComponent as IconCampogrande } from "assets/icons/location/campogrande.svg" 
import { ReactComponent as IconCanary } from "assets/icons/location/canary.svg" 
import { ReactComponent as IconCanberra } from "assets/icons/location/canberra.svg" 
import { ReactComponent as IconCancun } from "assets/icons/location/cancun.svg" 
import { ReactComponent as IconCapeverde } from "assets/icons/location/capeverde.svg" 
import { ReactComponent as IconCaracas } from "assets/icons/location/caracas.svg" 
import { ReactComponent as IconCasablanca } from "assets/icons/location/casablanca.svg" 
import { ReactComponent as IconCasey } from "assets/icons/location/casey.svg" 
import { ReactComponent as IconCatamarca } from "assets/icons/location/catamarca.svg" 
import { ReactComponent as IconCayenne } from "assets/icons/location/cayenne.svg" 
import { ReactComponent as IconCayman } from "assets/icons/location/cayman.svg" 
import { ReactComponent as IconCenter } from "assets/icons/location/center.svg" 
import { ReactComponent as IconCentral } from "assets/icons/location/central.svg" 
import { ReactComponent as IconCet } from "assets/icons/location/cet.svg" 
import { ReactComponent as IconCeuta } from "assets/icons/location/ceuta.svg" 
import { ReactComponent as IconChagos } from "assets/icons/location/chagos.svg" 
import { ReactComponent as IconChatham } from "assets/icons/location/chatham.svg" 
import { ReactComponent as IconChicago } from "assets/icons/location/chicago.svg" 
import { ReactComponent as IconChihuahua } from "assets/icons/location/chihuahua.svg" 
import { ReactComponent as IconChisinau } from "assets/icons/location/chisinau.svg" 
import { ReactComponent as IconChita } from "assets/icons/location/chita.svg" 
import { ReactComponent as IconChoibalsan } from "assets/icons/location/choibalsan.svg" 
import { ReactComponent as IconChongqing } from "assets/icons/location/chongqing.svg" 
import { ReactComponent as IconChristmas } from "assets/icons/location/christmas.svg" 
import { ReactComponent as IconChungking } from "assets/icons/location/chungking.svg" 
import { ReactComponent as IconChuuk } from "assets/icons/location/chuuk.svg" 
import { ReactComponent as IconCiudadjuarez } from "assets/icons/location/ciudadjuarez.svg" 
import { ReactComponent as IconCocos } from "assets/icons/location/cocos.svg" 
import { ReactComponent as IconColombo } from "assets/icons/location/colombo.svg" 
import { ReactComponent as IconComodrivadavia } from "assets/icons/location/comodrivadavia.svg" 
import { ReactComponent as IconComoro } from "assets/icons/location/comoro.svg" 
import { ReactComponent as IconConakry } from "assets/icons/location/conakry.svg" 
import { ReactComponent as IconContinental } from "assets/icons/location/continental.svg" 
import { ReactComponent as IconCopenhagen } from "assets/icons/location/copenhagen.svg" 
import { ReactComponent as IconCoralharbour } from "assets/icons/location/coralharbour.svg" 
import { ReactComponent as IconCordoba } from "assets/icons/location/cordoba.svg" 
import { ReactComponent as IconCostarica } from "assets/icons/location/costarica.svg" 
import { ReactComponent as IconCreston } from "assets/icons/location/creston.svg" 
import { ReactComponent as IconCst6cdt } from "assets/icons/location/cst6cdt.svg" 
import { ReactComponent as IconCuba } from "assets/icons/location/cuba.svg" 
import { ReactComponent as IconCuiaba } from "assets/icons/location/cuiaba.svg" 
import { ReactComponent as IconCuracao } from "assets/icons/location/curacao.svg" 
import { ReactComponent as IconCurrie } from "assets/icons/location/currie.svg" 
import { ReactComponent as IconDacca } from "assets/icons/location/dacca.svg" 
import { ReactComponent as IconDakar } from "assets/icons/location/dakar.svg" 
import { ReactComponent as IconDamascus } from "assets/icons/location/damascus.svg" 
import { ReactComponent as IconDanmarkshavn } from "assets/icons/location/danmarkshavn.svg" 
import { ReactComponent as IconDaressalaam } from "assets/icons/location/daressalaam.svg" 
import { ReactComponent as IconDarwin } from "assets/icons/location/darwin.svg" 
import { ReactComponent as IconDavis } from "assets/icons/location/davis.svg" 
import { ReactComponent as IconDawson } from "assets/icons/location/dawson.svg" 
import { ReactComponent as IconDawsoncreek } from "assets/icons/location/dawsoncreek.svg" 
import { ReactComponent as IconDenoronha } from "assets/icons/location/denoronha.svg" 
import { ReactComponent as IconDenver } from "assets/icons/location/denver.svg" 
import { ReactComponent as IconDetroit } from "assets/icons/location/detroit.svg" 
import { ReactComponent as IconDhaka } from "assets/icons/location/dhaka.svg" 
import { ReactComponent as IconDili } from "assets/icons/location/dili.svg" 
import { ReactComponent as IconDjibouti } from "assets/icons/location/djibouti.svg" 
import { ReactComponent as IconDominica } from "assets/icons/location/dominica.svg" 
import { ReactComponent as IconDouala } from "assets/icons/location/douala.svg" 
import { ReactComponent as IconDubai } from "assets/icons/location/dubai.svg" 
import { ReactComponent as IconDublin } from "assets/icons/location/dublin.svg" 
import { ReactComponent as IconDumontdurville } from "assets/icons/location/dumontdurville.svg" 
import { ReactComponent as IconDushanbe } from "assets/icons/location/dushanbe.svg" 
import { ReactComponent as IconEast } from "assets/icons/location/east.svg" 
import { ReactComponent as IconEaster } from "assets/icons/location/easter.svg" 
import { ReactComponent as IconEasterisland } from "assets/icons/location/easterisland.svg" 
import { ReactComponent as IconEastern } from "assets/icons/location/eastern.svg" 
import { ReactComponent as IconEastindiana } from "assets/icons/location/eastindiana.svg" 
import { ReactComponent as IconEdmonton } from "assets/icons/location/edmonton.svg" 
import { ReactComponent as IconEet } from "assets/icons/location/eet.svg" 
import { ReactComponent as IconEfate } from "assets/icons/location/efate.svg" 
import { ReactComponent as IconEgypt } from "assets/icons/location/egypt.svg" 
import { ReactComponent as IconEirunepe } from "assets/icons/location/eirunepe.svg" 
import { ReactComponent as IconElaaiun } from "assets/icons/location/elaaiun.svg" 
import { ReactComponent as IconElsalvador } from "assets/icons/location/elsalvador.svg" 
import { ReactComponent as IconEnderbury } from "assets/icons/location/enderbury.svg" 
import { ReactComponent as IconEnsenada } from "assets/icons/location/ensenada.svg" 
import { ReactComponent as IconEst } from "assets/icons/location/est.svg" 
import { ReactComponent as IconEst5edt } from "assets/icons/location/est5edt.svg" 
import { ReactComponent as IconEucla } from "assets/icons/location/eucla.svg" 
import { ReactComponent as IconFaeroe } from "assets/icons/location/faeroe.svg" 
import { ReactComponent as IconFakaofo } from "assets/icons/location/fakaofo.svg" 
import { ReactComponent as IconFamagusta } from "assets/icons/location/famagusta.svg" 
import { ReactComponent as IconFaroe } from "assets/icons/location/faroe.svg" 
import { ReactComponent as IconFiji } from "assets/icons/location/fiji.svg" 
import { ReactComponent as IconFortaleza } from "assets/icons/location/fortaleza.svg" 
import { ReactComponent as IconFortnelson } from "assets/icons/location/fortnelson.svg" 
import { ReactComponent as IconFortwayne } from "assets/icons/location/fortwayne.svg" 
import { ReactComponent as IconFreetown } from "assets/icons/location/freetown.svg" 
import { ReactComponent as IconFunafuti } from "assets/icons/location/funafuti.svg" 
import { ReactComponent as IconGaborone } from "assets/icons/location/gaborone.svg" 
import { ReactComponent as IconGalapagos } from "assets/icons/location/galapagos.svg" 
import { ReactComponent as IconGambier } from "assets/icons/location/gambier.svg" 
import { ReactComponent as IconGaza } from "assets/icons/location/gaza.svg" 
import { ReactComponent as IconGb } from "assets/icons/location/gb.svg" 
import { ReactComponent as IconGbeire } from "assets/icons/location/gbeire.svg" 
import { ReactComponent as IconGeneral } from "assets/icons/location/general.svg" 
import { ReactComponent as IconGibraltar } from "assets/icons/location/gibraltar.svg" 
import { ReactComponent as IconGlacebay } from "assets/icons/location/glacebay.svg" 
import { ReactComponent as IconGodthab } from "assets/icons/location/godthab.svg" 
import { ReactComponent as IconGoosebay } from "assets/icons/location/goosebay.svg" 
import { ReactComponent as IconGrandturk } from "assets/icons/location/grandturk.svg" 
import { ReactComponent as IconGrenada } from "assets/icons/location/grenada.svg" 
import { ReactComponent as IconGuadalcanal } from "assets/icons/location/guadalcanal.svg" 
import { ReactComponent as IconGuadeloupe } from "assets/icons/location/guadeloupe.svg" 
import { ReactComponent as IconGuam } from "assets/icons/location/guam.svg" 
import { ReactComponent as IconGuatemala } from "assets/icons/location/guatemala.svg" 
import { ReactComponent as IconGuayaquil } from "assets/icons/location/guayaquil.svg" 
import { ReactComponent as IconGuernsey } from "assets/icons/location/guernsey.svg" 
import { ReactComponent as IconGuyana } from "assets/icons/location/guyana.svg" 
import { ReactComponent as IconHalifax } from "assets/icons/location/halifax.svg" 
import { ReactComponent as IconHarare } from "assets/icons/location/harare.svg" 
import { ReactComponent as IconHarbin } from "assets/icons/location/harbin.svg" 
import { ReactComponent as IconHavana } from "assets/icons/location/havana.svg" 
import { ReactComponent as IconHawaii } from "assets/icons/location/hawaii.svg" 
import { ReactComponent as IconHebron } from "assets/icons/location/hebron.svg" 
import { ReactComponent as IconHelsinki } from "assets/icons/location/helsinki.svg" 
import { ReactComponent as IconHermosillo } from "assets/icons/location/hermosillo.svg" 
import { ReactComponent as IconHobart } from "assets/icons/location/hobart.svg" 
import { ReactComponent as IconHochiminh } from "assets/icons/location/hochiminh.svg" 
import { ReactComponent as IconHongkong } from "assets/icons/location/hongkong.svg" 
import { ReactComponent as IconHonolulu } from "assets/icons/location/honolulu.svg" 
import { ReactComponent as IconHovd } from "assets/icons/location/hovd.svg" 
import { ReactComponent as IconHst } from "assets/icons/location/hst.svg" 
import { ReactComponent as IconIceland } from "assets/icons/location/iceland.svg" 
import { ReactComponent as IconIndiana } from "assets/icons/location/indiana.svg" 
import { ReactComponent as IconIndianapolis } from "assets/icons/location/indianapolis.svg" 
import { ReactComponent as IconIndianastarke } from "assets/icons/location/indianastarke.svg" 
import { ReactComponent as IconInuvik } from "assets/icons/location/inuvik.svg" 
import { ReactComponent as IconIqaluit } from "assets/icons/location/iqaluit.svg" 
import { ReactComponent as IconIran } from "assets/icons/location/iran.svg" 
import { ReactComponent as IconIrkutsk } from "assets/icons/location/irkutsk.svg" 
import { ReactComponent as IconIsleofman } from "assets/icons/location/isleofman.svg" 
import { ReactComponent as IconIsrael } from "assets/icons/location/israel.svg" 
import { ReactComponent as IconIstanbul } from "assets/icons/location/istanbul.svg" 
import { ReactComponent as IconJakarta } from "assets/icons/location/jakarta.svg" 
import { ReactComponent as IconJamaica } from "assets/icons/location/jamaica.svg" 
import { ReactComponent as IconJanmayen } from "assets/icons/location/janmayen.svg" 
import { ReactComponent as IconJapan } from "assets/icons/location/japan.svg" 
import { ReactComponent as IconJayapura } from "assets/icons/location/jayapura.svg" 
import { ReactComponent as IconJersey } from "assets/icons/location/jersey.svg" 
import { ReactComponent as IconJerusalem } from "assets/icons/location/jerusalem.svg" 
import { ReactComponent as IconJohannesburg } from "assets/icons/location/johannesburg.svg" 
import { ReactComponent as IconJohnston } from "assets/icons/location/johnston.svg" 
import { ReactComponent as IconJuba } from "assets/icons/location/juba.svg" 
import { ReactComponent as IconJujuy } from "assets/icons/location/jujuy.svg" 
import { ReactComponent as IconJuneau } from "assets/icons/location/juneau.svg" 
import { ReactComponent as IconKabul } from "assets/icons/location/kabul.svg" 
import { ReactComponent as IconKaliningrad } from "assets/icons/location/kaliningrad.svg" 
import { ReactComponent as IconKamchatka } from "assets/icons/location/kamchatka.svg" 
import { ReactComponent as IconKampala } from "assets/icons/location/kampala.svg" 
import { ReactComponent as IconKanton } from "assets/icons/location/kanton.svg" 
import { ReactComponent as IconKarachi } from "assets/icons/location/karachi.svg" 
import { ReactComponent as IconKashgar } from "assets/icons/location/kashgar.svg" 
import { ReactComponent as IconKathmandu } from "assets/icons/location/kathmandu.svg" 
import { ReactComponent as IconKerguelen } from "assets/icons/location/kerguelen.svg" 
import { ReactComponent as IconKhandyga } from "assets/icons/location/khandyga.svg" 
import { ReactComponent as IconKhartoum } from "assets/icons/location/khartoum.svg" 
import { ReactComponent as IconKiev } from "assets/icons/location/kiev.svg" 
import { ReactComponent as IconKigali } from "assets/icons/location/kigali.svg" 
import { ReactComponent as IconKinshasa } from "assets/icons/location/kinshasa.svg" 
import { ReactComponent as IconKiritimati } from "assets/icons/location/kiritimati.svg" 
import { ReactComponent as IconKirov } from "assets/icons/location/kirov.svg" 
import { ReactComponent as IconKnox } from "assets/icons/location/knox.svg" 
import { ReactComponent as IconKnoxin } from "assets/icons/location/knoxin.svg" 
import { ReactComponent as IconKolkata } from "assets/icons/location/kolkata.svg" 
import { ReactComponent as IconKosrae } from "assets/icons/location/kosrae.svg" 
import { ReactComponent as IconKralendijk } from "assets/icons/location/kralendijk.svg" 
import { ReactComponent as IconKrasnoyarsk } from "assets/icons/location/krasnoyarsk.svg" 
import { ReactComponent as IconKualalumpur } from "assets/icons/location/kualalumpur.svg" 
import { ReactComponent as IconKuching } from "assets/icons/location/kuching.svg" 
import { ReactComponent as IconKuwait } from "assets/icons/location/kuwait.svg" 
import { ReactComponent as IconKwajalein } from "assets/icons/location/kwajalein.svg" 
import { ReactComponent as IconLagos } from "assets/icons/location/lagos.svg" 
import { ReactComponent as IconLapaz } from "assets/icons/location/lapaz.svg" 
import { ReactComponent as IconLarioja } from "assets/icons/location/larioja.svg" 
import { ReactComponent as IconLhi } from "assets/icons/location/lhi.svg" 
import { ReactComponent as IconLibreville } from "assets/icons/location/libreville.svg" 
import { ReactComponent as IconLibya } from "assets/icons/location/libya.svg" 
import { ReactComponent as IconLima } from "assets/icons/location/lima.svg" 
import { ReactComponent as IconLindeman } from "assets/icons/location/lindeman.svg" 
import { ReactComponent as IconLisbon } from "assets/icons/location/lisbon.svg" 
import { ReactComponent as IconLjubljana } from "assets/icons/location/ljubljana.svg" 
import { ReactComponent as IconLome } from "assets/icons/location/lome.svg" 
import { ReactComponent as IconLondon } from "assets/icons/location/london.svg" 
import { ReactComponent as IconLongyearbyen } from "assets/icons/location/longyearbyen.svg" 
import { ReactComponent as IconLordhowe } from "assets/icons/location/lordhowe.svg" 
import { ReactComponent as IconLosangeles } from "assets/icons/location/losangeles.svg" 
import { ReactComponent as IconLouisville } from "assets/icons/location/louisville.svg" 
import { ReactComponent as IconLowerprinces } from "assets/icons/location/lowerprinces.svg" 
import { ReactComponent as IconLuanda } from "assets/icons/location/luanda.svg" 
import { ReactComponent as IconLubumbashi } from "assets/icons/location/lubumbashi.svg" 
import { ReactComponent as IconLusaka } from "assets/icons/location/lusaka.svg" 
import { ReactComponent as IconLuxembourg } from "assets/icons/location/luxembourg.svg" 
import { ReactComponent as IconMacao } from "assets/icons/location/macao.svg" 
import { ReactComponent as IconMacau } from "assets/icons/location/macau.svg" 
import { ReactComponent as IconMaceio } from "assets/icons/location/maceio.svg" 
import { ReactComponent as IconMacquarie } from "assets/icons/location/macquarie.svg" 
import { ReactComponent as IconMadeira } from "assets/icons/location/madeira.svg" 
import { ReactComponent as IconMadrid } from "assets/icons/location/madrid.svg" 
import { ReactComponent as IconMagadan } from "assets/icons/location/magadan.svg" 
import { ReactComponent as IconMahe } from "assets/icons/location/mahe.svg" 
import { ReactComponent as IconMajuro } from "assets/icons/location/majuro.svg" 
import { ReactComponent as IconMakassar } from "assets/icons/location/makassar.svg" 
import { ReactComponent as IconMalabo } from "assets/icons/location/malabo.svg" 
import { ReactComponent as IconMaldives } from "assets/icons/location/maldives.svg" 
import { ReactComponent as IconMalta } from "assets/icons/location/malta.svg" 
import { ReactComponent as IconManagua } from "assets/icons/location/managua.svg" 
import { ReactComponent as IconManaus } from "assets/icons/location/manaus.svg" 
import { ReactComponent as IconManila } from "assets/icons/location/manila.svg" 
import { ReactComponent as IconMaputo } from "assets/icons/location/maputo.svg" 
import { ReactComponent as IconMarengo } from "assets/icons/location/marengo.svg" 
import { ReactComponent as IconMariehamn } from "assets/icons/location/mariehamn.svg" 
import { ReactComponent as IconMarigot } from "assets/icons/location/marigot.svg" 
import { ReactComponent as IconMarquesas } from "assets/icons/location/marquesas.svg" 
import { ReactComponent as IconMartinique } from "assets/icons/location/martinique.svg" 
import { ReactComponent as IconMaseru } from "assets/icons/location/maseru.svg" 
import { ReactComponent as IconMatamoros } from "assets/icons/location/matamoros.svg" 
import { ReactComponent as IconMauritius } from "assets/icons/location/mauritius.svg" 
import { ReactComponent as IconMawson } from "assets/icons/location/mawson.svg" 
import { ReactComponent as IconMayotte } from "assets/icons/location/mayotte.svg" 
import { ReactComponent as IconMazatlan } from "assets/icons/location/mazatlan.svg" 
import { ReactComponent as IconMbabane } from "assets/icons/location/mbabane.svg" 
import { ReactComponent as IconMcmurdo } from "assets/icons/location/mcmurdo.svg" 
import { ReactComponent as IconMelbourne } from "assets/icons/location/melbourne.svg" 
import { ReactComponent as IconMendoza } from "assets/icons/location/mendoza.svg" 
import { ReactComponent as IconMenominee } from "assets/icons/location/menominee.svg" 
import { ReactComponent as IconMerida } from "assets/icons/location/merida.svg" 
import { ReactComponent as IconMet } from "assets/icons/location/met.svg" 
import { ReactComponent as IconMetlakatla } from "assets/icons/location/metlakatla.svg" 
import { ReactComponent as IconMexicocity } from "assets/icons/location/mexicocity.svg" 
import { ReactComponent as IconMichigan } from "assets/icons/location/michigan.svg" 
import { ReactComponent as IconMidway } from "assets/icons/location/midway.svg" 
import { ReactComponent as IconMinsk } from "assets/icons/location/minsk.svg" 
import { ReactComponent as IconMiquelon } from "assets/icons/location/miquelon.svg" 
import { ReactComponent as IconMogadishu } from "assets/icons/location/mogadishu.svg" 
import { ReactComponent as IconMonaco } from "assets/icons/location/monaco.svg" 
import { ReactComponent as IconMoncton } from "assets/icons/location/moncton.svg" 
import { ReactComponent as IconMonrovia } from "assets/icons/location/monrovia.svg" 
import { ReactComponent as IconMonterrey } from "assets/icons/location/monterrey.svg" 
import { ReactComponent as IconMontevideo } from "assets/icons/location/montevideo.svg" 
import { ReactComponent as IconMonticello } from "assets/icons/location/monticello.svg" 
import { ReactComponent as IconMontreal } from "assets/icons/location/montreal.svg" 
import { ReactComponent as IconMontserrat } from "assets/icons/location/montserrat.svg" 
import { ReactComponent as IconMoscow } from "assets/icons/location/moscow.svg" 
import { ReactComponent as IconMountain } from "assets/icons/location/mountain.svg" 
import { ReactComponent as IconMst } from "assets/icons/location/mst.svg" 
import { ReactComponent as IconMst7mdt } from "assets/icons/location/mst7mdt.svg" 
import { ReactComponent as IconMuscat } from "assets/icons/location/muscat.svg" 
import { ReactComponent as IconNairobi } from "assets/icons/location/nairobi.svg" 
import { ReactComponent as IconNassau } from "assets/icons/location/nassau.svg" 
import { ReactComponent as IconNauru } from "assets/icons/location/nauru.svg" 
import { ReactComponent as IconNavajo } from "assets/icons/location/navajo.svg" 
import { ReactComponent as IconNdjamena } from "assets/icons/location/ndjamena.svg" 
import { ReactComponent as IconNepal } from "assets/icons/location/nepal.svg" 
import { ReactComponent as IconNewfoundland } from "assets/icons/location/newfoundland.svg" 
import { ReactComponent as IconNewsalem } from "assets/icons/location/newsalem.svg" 
import { ReactComponent as IconNewyork } from "assets/icons/location/newyork.svg" 
import { ReactComponent as IconNiamey } from "assets/icons/location/niamey.svg" 
import { ReactComponent as IconNicosia } from "assets/icons/location/nicosia.svg" 
import { ReactComponent as IconNipigon } from "assets/icons/location/nipigon.svg" 
import { ReactComponent as IconNiue } from "assets/icons/location/niue.svg" 
import { ReactComponent as IconNome } from "assets/icons/location/nome.svg" 
import { ReactComponent as IconNorfolk } from "assets/icons/location/norfolk.svg" 
import { ReactComponent as IconNoronha } from "assets/icons/location/noronha.svg" 
import { ReactComponent as IconNorth } from "assets/icons/location/north.svg" 
import { ReactComponent as IconNouakchott } from "assets/icons/location/nouakchott.svg" 
import { ReactComponent as IconNoumea } from "assets/icons/location/noumea.svg" 
import { ReactComponent as IconNovokuznetsk } from "assets/icons/location/novokuznetsk.svg" 
import { ReactComponent as IconNovosibirsk } from "assets/icons/location/novosibirsk.svg" 
import { ReactComponent as IconNsw } from "assets/icons/location/nsw.svg" 
import { ReactComponent as IconNuuk } from "assets/icons/location/nuuk.svg" 
import { ReactComponent as IconNz } from "assets/icons/location/nz.svg" 
import { ReactComponent as IconNzchat } from "assets/icons/location/nzchat.svg" 
import { ReactComponent as IconOjinaga } from "assets/icons/location/ojinaga.svg" 
import { ReactComponent as IconOmsk } from "assets/icons/location/omsk.svg" 
import { ReactComponent as IconOral } from "assets/icons/location/oral.svg" 
import { ReactComponent as IconOslo } from "assets/icons/location/oslo.svg" 
import { ReactComponent as IconOuagadougou } from "assets/icons/location/ouagadougou.svg" 
import { ReactComponent as IconPacific } from "assets/icons/location/pacific.svg" 
import { ReactComponent as IconPagopago } from "assets/icons/location/pagopago.svg" 
import { ReactComponent as IconPalau } from "assets/icons/location/palau.svg" 
import { ReactComponent as IconPalmer } from "assets/icons/location/palmer.svg" 
import { ReactComponent as IconPanama } from "assets/icons/location/panama.svg" 
import { ReactComponent as IconPangnirtung } from "assets/icons/location/pangnirtung.svg" 
import { ReactComponent as IconParamaribo } from "assets/icons/location/paramaribo.svg" 
import { ReactComponent as IconParis } from "assets/icons/location/paris.svg" 
import { ReactComponent as IconPerth } from "assets/icons/location/perth.svg" 
import { ReactComponent as IconPetersburg } from "assets/icons/location/petersburg.svg" 
import { ReactComponent as IconPhnompenh } from "assets/icons/location/phnompenh.svg" 
import { ReactComponent as IconPhoenix } from "assets/icons/location/phoenix.svg" 
import { ReactComponent as IconPitcairn } from "assets/icons/location/pitcairn.svg" 
import { ReactComponent as IconPodgorica } from "assets/icons/location/podgorica.svg" 
import { ReactComponent as IconPohnpei } from "assets/icons/location/pohnpei.svg" 
import { ReactComponent as IconPoland } from "assets/icons/location/poland.svg" 
import { ReactComponent as IconPonape } from "assets/icons/location/ponape.svg" 
import { ReactComponent as IconPontianak } from "assets/icons/location/pontianak.svg" 
import { ReactComponent as IconPortauprince } from "assets/icons/location/portauprince.svg" 
import { ReactComponent as IconPortmoresby } from "assets/icons/location/portmoresby.svg" 
import { ReactComponent as IconPortoacre } from "assets/icons/location/portoacre.svg" 
import { ReactComponent as IconPortofspain } from "assets/icons/location/portofspain.svg" 
import { ReactComponent as IconPortonovo } from "assets/icons/location/portonovo.svg" 
import { ReactComponent as IconPortovelho } from "assets/icons/location/portovelho.svg" 
import { ReactComponent as IconPortugal } from "assets/icons/location/portugal.svg" 
import { ReactComponent as IconPrague } from "assets/icons/location/prague.svg" 
import { ReactComponent as IconPrc } from "assets/icons/location/prc.svg" 
import { ReactComponent as IconPst8pdt } from "assets/icons/location/pst8pdt.svg" 
import { ReactComponent as IconPuertorico } from "assets/icons/location/puertorico.svg" 
import { ReactComponent as IconPuntaarenas } from "assets/icons/location/puntaarenas.svg" 
import { ReactComponent as IconPyongyang } from "assets/icons/location/pyongyang.svg" 
import { ReactComponent as IconQatar } from "assets/icons/location/qatar.svg" 
import { ReactComponent as IconQostanay } from "assets/icons/location/qostanay.svg" 
import { ReactComponent as IconQueensland } from "assets/icons/location/queensland.svg" 
import { ReactComponent as IconQyzylorda } from "assets/icons/location/qyzylorda.svg" 
import { ReactComponent as IconRainyriver } from "assets/icons/location/rainyriver.svg" 
import { ReactComponent as IconRangoon } from "assets/icons/location/rangoon.svg" 
import { ReactComponent as IconRankininlet } from "assets/icons/location/rankininlet.svg" 
import { ReactComponent as IconRarotonga } from "assets/icons/location/rarotonga.svg" 
import { ReactComponent as IconRecife } from "assets/icons/location/recife.svg" 
import { ReactComponent as IconRegina } from "assets/icons/location/regina.svg" 
import { ReactComponent as IconResolute } from "assets/icons/location/resolute.svg" 
import { ReactComponent as IconReunion } from "assets/icons/location/reunion.svg" 
import { ReactComponent as IconReykjavik } from "assets/icons/location/reykjavik.svg" 
import { ReactComponent as IconRiga } from "assets/icons/location/riga.svg" 
import { ReactComponent as IconRiobranco } from "assets/icons/location/riobranco.svg" 
import { ReactComponent as IconRiogallegos } from "assets/icons/location/riogallegos.svg" 
import { ReactComponent as IconRiyadh } from "assets/icons/location/riyadh.svg" 
import { ReactComponent as IconRoc } from "assets/icons/location/roc.svg" 
import { ReactComponent as IconRok } from "assets/icons/location/rok.svg" 
import { ReactComponent as IconRome } from "assets/icons/location/rome.svg" 
import { ReactComponent as IconRosario } from "assets/icons/location/rosario.svg" 
import { ReactComponent as IconRothera } from "assets/icons/location/rothera.svg" 
import { ReactComponent as IconSaigon } from "assets/icons/location/saigon.svg" 
import { ReactComponent as IconSaipan } from "assets/icons/location/saipan.svg" 
import { ReactComponent as IconSakhalin } from "assets/icons/location/sakhalin.svg" 
import { ReactComponent as IconSalta } from "assets/icons/location/salta.svg" 
import { ReactComponent as IconSamara } from "assets/icons/location/samara.svg" 
import { ReactComponent as IconSamarkand } from "assets/icons/location/samarkand.svg" 
import { ReactComponent as IconSamoa } from "assets/icons/location/samoa.svg" 
import { ReactComponent as IconSanjuan } from "assets/icons/location/sanjuan.svg" 
import { ReactComponent as IconSanluis } from "assets/icons/location/sanluis.svg" 
import { ReactComponent as IconSanmarino } from "assets/icons/location/sanmarino.svg" 
import { ReactComponent as IconSantaisabel } from "assets/icons/location/santaisabel.svg" 
import { ReactComponent as IconSantarem } from "assets/icons/location/santarem.svg" 
import { ReactComponent as IconSantiago } from "assets/icons/location/santiago.svg" 
import { ReactComponent as IconSantodomingo } from "assets/icons/location/santodomingo.svg" 
import { ReactComponent as IconSaopaulo } from "assets/icons/location/saopaulo.svg" 
import { ReactComponent as IconSaotome } from "assets/icons/location/saotome.svg" 
import { ReactComponent as IconSarajevo } from "assets/icons/location/sarajevo.svg" 
import { ReactComponent as IconSaratov } from "assets/icons/location/saratov.svg" 
import { ReactComponent as IconSaskatchewan } from "assets/icons/location/saskatchewan.svg" 
import { ReactComponent as IconScoresbysund } from "assets/icons/location/scoresbysund.svg" 
import { ReactComponent as IconSeoul } from "assets/icons/location/seoul.svg" 
import { ReactComponent as IconShanghai } from "assets/icons/location/shanghai.svg" 
import { ReactComponent as IconShiprock } from "assets/icons/location/shiprock.svg" 
import { ReactComponent as IconSimferopol } from "assets/icons/location/simferopol.svg" 
import { ReactComponent as IconSingapore } from "assets/icons/location/singapore.svg" 
import { ReactComponent as IconSitka } from "assets/icons/location/sitka.svg" 
import { ReactComponent as IconSkopje } from "assets/icons/location/skopje.svg" 
import { ReactComponent as IconSofia } from "assets/icons/location/sofia.svg" 
import { ReactComponent as IconSouth } from "assets/icons/location/south.svg" 
import { ReactComponent as IconSouthgeorgia } from "assets/icons/location/southgeorgia.svg" 
import { ReactComponent as IconSouthpole } from "assets/icons/location/southpole.svg" 
import { ReactComponent as IconSrednekolymsk } from "assets/icons/location/srednekolymsk.svg" 
import { ReactComponent as IconStanley } from "assets/icons/location/stanley.svg" 
import { ReactComponent as IconStbarthelemy } from "assets/icons/location/stbarthelemy.svg" 
import { ReactComponent as IconSthelena } from "assets/icons/location/sthelena.svg" 
import { ReactComponent as IconStjohns } from "assets/icons/location/stjohns.svg" 
import { ReactComponent as IconStkitts } from "assets/icons/location/stkitts.svg" 
import { ReactComponent as IconStlucia } from "assets/icons/location/stlucia.svg" 
import { ReactComponent as IconStockholm } from "assets/icons/location/stockholm.svg" 
import { ReactComponent as IconStthomas } from "assets/icons/location/stthomas.svg" 
import { ReactComponent as IconStvincent } from "assets/icons/location/stvincent.svg" 
import { ReactComponent as IconSwiftcurrent } from "assets/icons/location/swiftcurrent.svg" 
import { ReactComponent as IconSydney } from "assets/icons/location/sydney.svg" 
import { ReactComponent as IconSyowa } from "assets/icons/location/syowa.svg" 
import { ReactComponent as IconTahiti } from "assets/icons/location/tahiti.svg" 
import { ReactComponent as IconTaipei } from "assets/icons/location/taipei.svg" 
import { ReactComponent as IconTallinn } from "assets/icons/location/tallinn.svg" 
import { ReactComponent as IconTarawa } from "assets/icons/location/tarawa.svg" 
import { ReactComponent as IconTashkent } from "assets/icons/location/tashkent.svg" 
import { ReactComponent as IconTasmania } from "assets/icons/location/tasmania.svg" 
import { ReactComponent as IconTbilisi } from "assets/icons/location/tbilisi.svg" 
import { ReactComponent as IconTegucigalpa } from "assets/icons/location/tegucigalpa.svg" 
import { ReactComponent as IconTehran } from "assets/icons/location/tehran.svg" 
import { ReactComponent as IconTelaviv } from "assets/icons/location/telaviv.svg" 
import { ReactComponent as IconTellcity } from "assets/icons/location/tellcity.svg" 
import { ReactComponent as IconThimbu } from "assets/icons/location/thimbu.svg" 
import { ReactComponent as IconThimphu } from "assets/icons/location/thimphu.svg" 
import { ReactComponent as IconThule } from "assets/icons/location/thule.svg" 
import { ReactComponent as IconThunderbay } from "assets/icons/location/thunderbay.svg" 
import { ReactComponent as IconTijuana } from "assets/icons/location/tijuana.svg" 
import { ReactComponent as IconTimbuktu } from "assets/icons/location/timbuktu.svg" 
import { ReactComponent as IconTirane } from "assets/icons/location/tirane.svg" 
import { ReactComponent as IconTiraspol } from "assets/icons/location/tiraspol.svg" 
import { ReactComponent as IconTokyo } from "assets/icons/location/tokyo.svg" 
import { ReactComponent as IconTomsk } from "assets/icons/location/tomsk.svg" 
import { ReactComponent as IconTongatapu } from "assets/icons/location/tongatapu.svg" 
import { ReactComponent as IconToronto } from "assets/icons/location/toronto.svg" 
import { ReactComponent as IconTortola } from "assets/icons/location/tortola.svg" 
import { ReactComponent as IconTripoli } from "assets/icons/location/tripoli.svg" 
import { ReactComponent as IconTroll } from "assets/icons/location/troll.svg" 
import { ReactComponent as IconTruk } from "assets/icons/location/truk.svg" 
import { ReactComponent as IconTucuman } from "assets/icons/location/tucuman.svg" 
import { ReactComponent as IconTunis } from "assets/icons/location/tunis.svg" 
import { ReactComponent as IconTurkey } from "assets/icons/location/turkey.svg" 
import { ReactComponent as IconUjungpandang } from "assets/icons/location/ujungpandang.svg" 
import { ReactComponent as IconUlaanbaatar } from "assets/icons/location/ulaanbaatar.svg" 
import { ReactComponent as IconUlanbator } from "assets/icons/location/ulanbator.svg" 
import { ReactComponent as IconUlyanovsk } from "assets/icons/location/ulyanovsk.svg" 
import { ReactComponent as IconUrumqi } from "assets/icons/location/urumqi.svg" 
import { ReactComponent as IconUshuaia } from "assets/icons/location/ushuaia.svg" 
import { ReactComponent as IconUstnera } from "assets/icons/location/ustnera.svg" 
import { ReactComponent as IconUzhgorod } from "assets/icons/location/uzhgorod.svg" 
import { ReactComponent as IconVaduz } from "assets/icons/location/vaduz.svg" 
import { ReactComponent as IconVancouver } from "assets/icons/location/vancouver.svg" 
import { ReactComponent as IconVatican } from "assets/icons/location/vatican.svg" 
import { ReactComponent as IconVevay } from "assets/icons/location/vevay.svg" 
import { ReactComponent as IconVictoria } from "assets/icons/location/victoria.svg" 
import { ReactComponent as IconVienna } from "assets/icons/location/vienna.svg" 
import { ReactComponent as IconVientiane } from "assets/icons/location/vientiane.svg" 
import { ReactComponent as IconVilnius } from "assets/icons/location/vilnius.svg" 
import { ReactComponent as IconVincennes } from "assets/icons/location/vincennes.svg" 
import { ReactComponent as IconVirgin } from "assets/icons/location/virgin.svg" 
import { ReactComponent as IconVladivostok } from "assets/icons/location/vladivostok.svg" 
import { ReactComponent as IconVolgograd } from "assets/icons/location/volgograd.svg" 
import { ReactComponent as IconVostok } from "assets/icons/location/vostok.svg" 
import { ReactComponent as IconWake } from "assets/icons/location/wake.svg" 
import { ReactComponent as IconWallis } from "assets/icons/location/wallis.svg" 
import { ReactComponent as IconWarsaw } from "assets/icons/location/warsaw.svg" 
import { ReactComponent as IconWest } from "assets/icons/location/west.svg" 
import { ReactComponent as IconWet } from "assets/icons/location/wet.svg" 
import { ReactComponent as IconWhitehorse } from "assets/icons/location/whitehorse.svg"
import { ReactComponent as IconWinamac } from "assets/icons/location/winamac.svg" 
import { ReactComponent as IconWindhoek } from "assets/icons/location/windhoek.svg" 
import { ReactComponent as IconWinnipeg } from "assets/icons/location/winnipeg.svg" 
import { ReactComponent as IconWsu } from "assets/icons/location/wsu.svg" 
import { ReactComponent as IconYakutat } from "assets/icons/location/yakutat.svg" 
import { ReactComponent as IconYakutsk } from "assets/icons/location/yakutsk.svg" 
import { ReactComponent as IconYancowinna } from "assets/icons/location/yancowinna.svg" 
import { ReactComponent as IconYangon } from "assets/icons/location/yangon.svg" 
import { ReactComponent as IconYap } from "assets/icons/location/yap.svg" 
import { ReactComponent as IconYekaterinburg } from "assets/icons/location/yekaterinburg.svg" 
import { ReactComponent as IconYellowknife } from "assets/icons/location/yellowknife.svg" 
import { ReactComponent as IconYerevan } from "assets/icons/location/yerevan.svg" 
import { ReactComponent as IconYukon } from "assets/icons/location/yukon.svg" 
import { ReactComponent as IconZagreb } from "assets/icons/location/zagreb.svg" 
import { ReactComponent as IconZaporozhye } from "assets/icons/location/zaporozhye.svg" 
import { ReactComponent as IconZurich } from "assets/icons/location/zurich.svg" 

/* 
Monezitation */
/* 
OS */
import { ReactComponent as IconAndroid } from "assets/icons/os/android.svg"
import { ReactComponent as IconApple } from "assets/icons/os/apple.svg"
import { ReactComponent as IconMacOS } from "assets/icons/os/macos.svg"
import { ReactComponent as IconLinux } from "assets/icons/os/linux.svg"
import { ReactComponent as IconWindows } from "assets/icons/os/windows.svg"
import { ReactComponent as IconWindows7 } from "assets/icons/os/windows7.svg"
import { ReactComponent as IconWindows8 } from "assets/icons/os/windows8.svg"
import { ReactComponent as IconWindows10 } from "assets/icons/os/windows10.svg"
import { ReactComponent as IconWindows11 } from "assets/icons/os/windows11.svg"
import { ReactComponent as IconWindowsXp } from "assets/icons/os/windowsxp.svg"
/* 
Other */
import { ReactComponent as IconUnknown } from "assets/icons/other/unknown.svg"
const icons ={ 
    /* 
    Browser */
    "brave": {
        vfit: false, 
        icon: IconBrave},
    "chrome": {
        vfit: true, 
        icon: IconChrome},
    "duckduckgo": {
        vfit: false, 
        icon: IconDuckduckgo},
    "edge": {
        vfit: true, 
        icon: IconEdge},
    "firefox": {
        vfit: false, 
        icon: IconFirefox},
    "opera": {
        vfit: true, 
        icon: IconOpera},
    "safari": {
        vfit: true, 
        icon: IconSafari},
    "samsunginternet": {
        vfit: false, 
        icon: IconSamsunginternet},
    /* 
    Category */
    "science": {
        vfit: false, 
        icon: IconScience},
    "travel": {
        vfit: true, 
        icon: IconTravel},
    /*
    Channel */
    "email": {
        vfit: false, 
        icon: IconEmail},
    /*
    Device */
    "imac": {
        vfit: false, 
        icon: IconIMac},
    "androidphone": {
        vfit: false, 
        icon: IconAndroidphone},
    "windowspc": {
        vfit: false, 
        icon: IconWindowspc},
    "iphone": {
        vfit: false, 
        icon: IconIphone},
    /* 
    Location */
    // "berlin": {
    //     vfit: true, 
    //     icon: IconBerlin},
    // "chicago": {
    //     vfit: false, 
    //     icon: IconChicago},
    // "copenhagen": {
    //     vfit: true, 
    //     icon: IconCopenhagen},
    // "detroit": {
    //     vfit: true, 
    //     icon: IconDetroit},
    // "dublin": {
    //     vfit: true, 
    //     icon: IconDublin},
    // "helsinki": {
    //     vfit: true, 
    //     icon: IconHelsinki},
    // "indiana": {
    //     vfit: true, 
    //     icon: IconIndiana},
    // "kiev": {
    //     vfit: true, 
    //     icon: IconKiev},
    // "kuwait": {
    //     vfit: true, 
    //     icon: IconKuwait},
    // "london": {
    //     vfit: true, 
    //     icon: IconLondon},
    // "losangeles": {
    //     vfit: true, 
    //     icon: IconLosangeles},
    // "madrid": {
    //     vfit: true, 
    //     icon: IconMadrid},
    // "manila": {
    //     vfit: false, 
    //     icon: IconManila},
    // "newyork": {
    //     vfit: false, 
    //     icon: IconNewyork},
    // "oslo": {
    //     vfit: true, 
    //     icon: IconOslo},
    // "prague": {
    //     vfit: true, 
    //     icon: IconPrague},
    // "rome": {
    //     vfit: false, 
    //     icon: IconRome},
    // "saopaulo": {
    //     vfit: false, 
    //     icon: IconSaopaulo},
    // "stockholm": {
    //     vfit: true, 
    //     icon: IconStockholm},

    "aashgabat": {
        vfit: false,
        icon: IconAashgabat},
    "abidjan": {
        vfit: false,
        icon: IconAbidjan},
    "accra": {
        vfit: false,
        icon: IconAccra},
    "acre": {
        vfit: false,
        icon: IconAcre},
    "act": {
        vfit: false,
        icon: IconAct},
    "adak": {
        vfit: false,
        icon: IconAdak},
    "addisababa": {
        vfit: false,
        icon: IconAddisababa},
    "adelaide": {
        vfit: false,
        icon: IconAdelaide},
    "aden": {
        vfit: false,
        icon: IconAden},
    "alaska": {
        vfit: false,
        icon: IconAlaska},
    "aleutian": {
        vfit: false,
        icon: IconAleutian},
    "algiers": {
        vfit: false,
        icon: IconAlgiers},
    "almaty": {
        vfit: false,
        icon: IconAlmaty},
    "amman": {
        vfit: false,
        icon: IconAmman},
    "amsterdam": {
        vfit: false,
        icon: IconAmsterdam},
    "anadyr": {
        vfit: false,
        icon: IconAnadyr},
    "anchorage": {
        vfit: false,
        icon: IconAnchorage},
    "andorra": {
        vfit: false,
        icon: IconAndorra},
    "anguilla": {
        vfit: false,
        icon: IconAnguilla},
    "antananarivo": {
        vfit: false,
        icon: IconAntananarivo},
    "antigua": {
        vfit: false,
        icon: IconAntigua},
    "apia": {
        vfit: false,
        icon: IconApia},
    "aqtau": {
        vfit: false,
        icon: IconAqtau},
    "aqtobe": {
        vfit: false,
        icon: IconAqtobe},
    "araguaiana": {
        vfit: false,
        icon: IconAraguaiana},
    "arizona": {
        vfit: false,
        icon: IconArizona},
    "aruba": {
        vfit: false,
        icon: IconAruba},
    "ashkhabad": {
        vfit: false,
        icon: IconAshkhabad},
    "asmara": {
        vfit: false,
        icon: IconAsmara},
    "asmera": {
        vfit: false,
        icon: IconAsmera},
    "astrakhan": {
        vfit: false,
        icon: IconAstrakhan},
    "asuncion": {
        vfit: false,
        icon: IconAsuncion},
    "athens": {
        vfit: false,
        icon: IconAthens},
    "atikokan": {
        vfit: false,
        icon: IconAtikokan},
    "atka": {
        vfit: false,
        icon: IconAtka},
    "atlantic": {
        vfit: false,
        icon: IconAtlantic},
    "atyrau": {
        vfit: false,
        icon: IconAtyrau},
    "auckland": {
        vfit: false,
        icon: IconAuckland},
    "azores": {
        vfit: false,
        icon: IconAzores},
    "baghdad": {
        vfit: false,
        icon: IconBaghdad},
    "bahia": {
        vfit: false,
        icon: IconBahia},
    "bahiabanderas": {
        vfit: false,
        icon: IconBahiabanderas},
    "bahrain": {
        vfit: false,
        icon: IconBahrain},
    "bajanorte": {
        vfit: false,
        icon: IconBajanorte},
    "bajasur": {
        vfit: false,
        icon: IconBajasur},
    "baku": {
        vfit: false,
        icon: IconBaku},
    "bamako": {
        vfit: false,
        icon: IconBamako},
    "bangkok": {
        vfit: false,
        icon: IconBangkok},
    "bangui": {
        vfit: false,
        icon: IconBangui},
    "banjul": {
        vfit: false,
        icon: IconBanjul},
    "barbados": {
        vfit: false,
        icon: IconBarbados},
    "barnaul": {
        vfit: false,
        icon: IconBarnaul},
    "beirut": {
        vfit: false,
        icon: IconBeirut},
    "belem": {
        vfit: false,
        icon: IconBelem},
    "belfast": {
        vfit: false,
        icon: IconBelfast},
    "belgrade": {
        vfit: false,
        icon: IconBelgrade},
    "belize": {
        vfit: false,
        icon: IconBelize},
    "berlin": {
        vfit: true,
        icon: IconBerlin},
    "bermuda": {
        vfit: false,
        icon: IconBermuda},
    "beulah": {
        vfit: false,
        icon: IconBeulah},
    "bishkek": {
        vfit: false,
        icon: IconBishkek},
    "bissau": {
        vfit: false,
        icon: IconBissau},
    "blancsablon": {
        vfit: false,
        icon: IconBlancsablon},
    "blantyre": {
        vfit: false,
        icon: IconBlantyre},
    "boavista": {
        vfit: false,
        icon: IconBoavista},
    "bogota": {
        vfit: false,
        icon: IconBogota},
    "boise": {
        vfit: false,
        icon: IconBoise},
    "bougainville": {
        vfit: false,
        icon: IconBougainville},
    "bratislava": {
        vfit: false,
        icon: IconBratislava},
    "brazzaville": {
        vfit: false,
        icon: IconBrazzaville},
    "brisbane": {
        vfit: false,
        icon: IconBrisbane},
    "brokenhill": {
        vfit: false,
        icon: IconBrokenhill},
    "brunei": {
        vfit: false,
        icon: IconBrunei},
    "brussels": {
        vfit: false,
        icon: IconBrussels},
    "bucharest": {
        vfit: false,
        icon: IconBucharest},
    "budapest": {
        vfit: false,
        icon: IconBudapest},
    "buenosaires": {
        vfit: false,
        icon: IconBuenosaires},
    "bujumbura": {
        vfit: false,
        icon: IconBujumbura},
    "busingen": {
        vfit: false,
        icon: IconBusingen},
    "cairo": {
        vfit: false,
        icon: IconCairo},
    "calcutta": {
        vfit: false,
        icon: IconCalcutta},
    "cambridgebay": {
        vfit: false,
        icon: IconCambridgebay},
    "campogrande": {
        vfit: false,
        icon: IconCampogrande},
    "canary": {
        vfit: false,
        icon: IconCanary},
    "canberra": {
        vfit: false,
        icon: IconCanberra},
    "cancun": {
        vfit: false,
        icon: IconCancun},
    "capeverde": {
        vfit: false,
        icon: IconCapeverde},
    "caracas": {
        vfit: false,
        icon: IconCaracas},
    "casablanca": {
        vfit: false,
        icon: IconCasablanca},
    "casey": {
        vfit: false,
        icon: IconCasey},
    "catamarca": {
        vfit: false,
        icon: IconCatamarca},
    "cayenne": {
        vfit: false,
        icon: IconCayenne},
    "cayman": {
        vfit: false,
        icon: IconCayman},
    "center": {
        vfit: false,
        icon: IconCenter},
    "central": {
        vfit: false,
        icon: IconCentral},
    "cet": {
        vfit: false,
        icon: IconCet},
    "ceuta": {
        vfit: false,
        icon: IconCeuta},
    "chagos": {
        vfit: false,
        icon: IconChagos},
    "chatham": {
        vfit: false,
        icon: IconChatham},
    "chicago": {
        vfit: false,
        icon: IconChicago},
    "chihuahua": {
        vfit: false,
        icon: IconChihuahua},
    "chisinau": {
        vfit: false,
        icon: IconChisinau},
    "chita": {
        vfit: false,
        icon: IconChita},
    "choibalsan": {
        vfit: false,
        icon: IconChoibalsan},
    "chongqing": {
        vfit: false,
        icon: IconChongqing},
    "christmas": {
        vfit: false,
        icon: IconChristmas},
    "chungking": {
        vfit: false,
        icon: IconChungking},
    "chuuk": {
        vfit: false,
        icon: IconChuuk},
    "ciudadjuarez": {
        vfit: false,
        icon: IconCiudadjuarez},
    "cocos": {
        vfit: false,
        icon: IconCocos},
    "colombo": {
        vfit: false,
        icon: IconColombo},
    "comodrivadavia": {
        vfit: false,
        icon: IconComodrivadavia},
    "comoro": {
        vfit: false,
        icon: IconComoro},
    "conakry": {
        vfit: false,
        icon: IconConakry},
    "continental": {
        vfit: false,
        icon: IconContinental},
    "copenhagen": {
        vfit: true,
        icon: IconCopenhagen},
    "coralharbour": {
        vfit: false,
        icon: IconCoralharbour},
    "cordoba": {
        vfit: false,
        icon: IconCordoba},
    "costarica": {
        vfit: false,
        icon: IconCostarica},
    "creston": {
        vfit: false,
        icon: IconCreston},
    "cst6cdt": {
        vfit: false,
        icon: IconCst6cdt},
    "cuba": {
        vfit: false,
        icon: IconCuba},
    "cuiaba": {
        vfit: false,
        icon: IconCuiaba},
    "curacao": {
        vfit: false,
        icon: IconCuracao},
    "currie": {
        vfit: false,
        icon: IconCurrie},
    "dacca": {
        vfit: false,
        icon: IconDacca},
    "dakar": {
        vfit: false,
        icon: IconDakar},
    "damascus": {
        vfit: false,
        icon: IconDamascus},
    "danmarkshavn": {
        vfit: false,
        icon: IconDanmarkshavn},
    "daressalaam": {
        vfit: false,
        icon: IconDaressalaam},
    "darwin": {
        vfit: false,
        icon: IconDarwin},
    "davis": {
        vfit: false,
        icon: IconDavis},
    "dawson": {
        vfit: false,
        icon: IconDawson},
    "dawsoncreek": {
        vfit: false,
        icon: IconDawsoncreek},
    "denoronha": {
        vfit: false,
        icon: IconDenoronha},
    "denver": {
        vfit: false,
        icon: IconDenver},
    "detroit": {
        vfit: true,
        icon: IconDetroit},
    "dhaka": {
        vfit: false,
        icon: IconDhaka},
    "dili": {
        vfit: false,
        icon: IconDili},
    "djibouti": {
        vfit: false,
        icon: IconDjibouti},
    "dominica": {
        vfit: false,
        icon: IconDominica},
    "douala": {
        vfit: false,
        icon: IconDouala},
    "dubai": {
        vfit: false,
        icon: IconDubai},
    "dublin": {
        vfit: true,
        icon: IconDublin},
    "dumontdurville": {
        vfit: false,
        icon: IconDumontdurville},
    "dushanbe": {
        vfit: false,
        icon: IconDushanbe},
    "east": {
        vfit: false,
        icon: IconEast},
    "easter": {
        vfit: false,
        icon: IconEaster},
    "easterisland": {
        vfit: false,
        icon: IconEasterisland},
    "eastern": {
        vfit: false,
        icon: IconEastern},
    "eastindiana": {
        vfit: false,
        icon: IconEastindiana},
    "edmonton": {
        vfit: false,
        icon: IconEdmonton},
    "eet": {
        vfit: false,
        icon: IconEet},
    "efate": {
        vfit: false,
        icon: IconEfate},
    "egypt": {
        vfit: false,
        icon: IconEgypt},
    "eirunepe": {
        vfit: false,
        icon: IconEirunepe},
    "elaaiun": {
        vfit: false,
        icon: IconElaaiun},
    "elsalvador": {
        vfit: false,
        icon: IconElsalvador},
    "enderbury": {
        vfit: false,
        icon: IconEnderbury},
    "ensenada": {
        vfit: false,
        icon: IconEnsenada},
    "est": {
        vfit: false,
        icon: IconEst},
    "est5edt": {
        vfit: false,
        icon: IconEst5edt},
    "eucla": {
        vfit: false,
        icon: IconEucla},
    "faeroe": {
        vfit: false,
        icon: IconFaeroe},
    "fakaofo": {
        vfit: false,
        icon: IconFakaofo},
    "famagusta": {
        vfit: false,
        icon: IconFamagusta},
    "faroe": {
        vfit: false,
        icon: IconFaroe},
    "fiji": {
        vfit: false,
        icon: IconFiji},
    "fortaleza": {
        vfit: false,
        icon: IconFortaleza},
    "fortnelson": {
        vfit: false,
        icon: IconFortnelson},
    "fortwayne": {
        vfit: false,
        icon: IconFortwayne},
    "freetown": {
        vfit: false,
        icon: IconFreetown},
    "funafuti": {
        vfit: false,
        icon: IconFunafuti},
    "gaborone": {
        vfit: false,
        icon: IconGaborone},
    "galapagos": {
        vfit: false,
        icon: IconGalapagos},
    "gambier": {
        vfit: false,
        icon: IconGambier},
    "gaza": {
        vfit: false,
        icon: IconGaza},
    "gb": {
        vfit: false,
        icon: IconGb},
    "gbeire": {
        vfit: false,
        icon: IconGbeire},
    "general": {
        vfit: false,
        icon: IconGeneral},
    "gibraltar": {
        vfit: false,
        icon: IconGibraltar},
    "glacebay": {
        vfit: false,
        icon: IconGlacebay},
    "godthab": {
        vfit: false,
        icon: IconGodthab},
    "goosebay": {
        vfit: false,
        icon: IconGoosebay},
    "grandturk": {
        vfit: false,
        icon: IconGrandturk},
    "grenada": {
        vfit: false,
        icon: IconGrenada},
    "guadalcanal": {
        vfit: false,
        icon: IconGuadalcanal},
    "guadeloupe": {
        vfit: false,
        icon: IconGuadeloupe},
    "guam": {
        vfit: false,
        icon: IconGuam},
    "guatemala": {
        vfit: false,
        icon: IconGuatemala},
    "guayaquil": {
        vfit: false,
        icon: IconGuayaquil},
    "guernsey": {
        vfit: false,
        icon: IconGuernsey},
    "guyana": {
        vfit: false,
        icon: IconGuyana},
    "halifax": {
        vfit: false,
        icon: IconHalifax},
    "harare": {
        vfit: false,
        icon: IconHarare},
    "harbin": {
        vfit: false,
        icon: IconHarbin},
    "havana": {
        vfit: false,
        icon: IconHavana},
    "hawaii": {
        vfit: false,
        icon: IconHawaii},
    "hebron": {
        vfit: false,
        icon: IconHebron},
    "helsinki": {
        vfit: true,
        icon: IconHelsinki},
    "hermosillo": {
        vfit: false,
        icon: IconHermosillo},
    "hobart": {
        vfit: false,
        icon: IconHobart},
    "hochiminh": {
        vfit: false,
        icon: IconHochiminh},
    "hongkong": {
        vfit: false,
        icon: IconHongkong},
    "honolulu": {
        vfit: false,
        icon: IconHonolulu},
    "hovd": {
        vfit: false,
        icon: IconHovd},
    "hst": {
        vfit: false,
        icon: IconHst},
    "iceland": {
        vfit: false,
        icon: IconIceland},
    "indiana": {
        vfit: true,
        icon: IconIndiana},
    "indianapolis": {
        vfit: false,
        icon: IconIndianapolis},
    "indianastarke": {
        vfit: false,
        icon: IconIndianastarke},
    "inuvik": {
        vfit: false,
        icon: IconInuvik},
    "iqaluit": {
        vfit: false,
        icon: IconIqaluit},
    "iran": {
        vfit: false,
        icon: IconIran},
    "irkutsk": {
        vfit: false,
        icon: IconIrkutsk},
    "isleofman": {
        vfit: false,
        icon: IconIsleofman},
    "israel": {
        vfit: false,
        icon: IconIsrael},
    "istanbul": {
        vfit: false,
        icon: IconIstanbul},
    "jakarta": {
        vfit: false,
        icon: IconJakarta},
    "jamaica": {
        vfit: false,
        icon: IconJamaica},
    "janmayen": {
        vfit: false,
        icon: IconJanmayen},
    "japan": {
        vfit: false,
        icon: IconJapan},
    "jayapura": {
        vfit: false,
        icon: IconJayapura},
    "jersey": {
        vfit: false,
        icon: IconJersey},
    "jerusalem": {
        vfit: false,
        icon: IconJerusalem},
    "johannesburg": {
        vfit: false,
        icon: IconJohannesburg},
    "johnston": {
        vfit: false,
        icon: IconJohnston},
    "juba": {
        vfit: false,
        icon: IconJuba},
    "jujuy": {
        vfit: false,
        icon: IconJujuy},
    "juneau": {
        vfit: false,
        icon: IconJuneau},
    "kabul": {
        vfit: false,
        icon: IconKabul},
    "kaliningrad": {
        vfit: false,
        icon: IconKaliningrad},
    "kamchatka": {
        vfit: false,
        icon: IconKamchatka},
    "kampala": {
        vfit: false,
        icon: IconKampala},
    "kanton": {
        vfit: false,
        icon: IconKanton},
    "karachi": {
        vfit: false,
        icon: IconKarachi},
    "kashgar": {
        vfit: false,
        icon: IconKashgar},
    "kathmandu": {
        vfit: false,
        icon: IconKathmandu},
    "kerguelen": {
        vfit: false,
        icon: IconKerguelen},
    "khandyga": {
        vfit: false,
        icon: IconKhandyga},
    "khartoum": {
        vfit: false,
        icon: IconKhartoum},
    "kiev": {
        vfit: true,
        icon: IconKiev},
    "kigali": {
        vfit: false,
        icon: IconKigali},
    "kinshasa": {
        vfit: false,
        icon: IconKinshasa},
    "kiritimati": {
        vfit: false,
        icon: IconKiritimati},
    "kirov": {
        vfit: false,
        icon: IconKirov},
    "knox": {
        vfit: false,
        icon: IconKnox},
    "knoxin": {
        vfit: false,
        icon: IconKnoxin},
    "kolkata": {
        vfit: false,
        icon: IconKolkata},
    "kosrae": {
        vfit: false,
        icon: IconKosrae},
    "kralendijk": {
        vfit: false,
        icon: IconKralendijk},
    "krasnoyarsk": {
        vfit: false,
        icon: IconKrasnoyarsk},
    "kualalumpur": {
        vfit: false,
        icon: IconKualalumpur},
    "kuching": {
        vfit: false,
        icon: IconKuching},
    "kuwait": {
        vfit: true,
        icon: IconKuwait},
    "kwajalein": {
        vfit: false,
        icon: IconKwajalein},
    "lagos": {
        vfit: false,
        icon: IconLagos},
    "lapaz": {
        vfit: false,
        icon: IconLapaz},
    "larioja": {
        vfit: false,
        icon: IconLarioja},
    "lhi": {
        vfit: false,
        icon: IconLhi},
    "libreville": {
        vfit: false,
        icon: IconLibreville},
    "libya": {
        vfit: false,
        icon: IconLibya},
    "lima": {
        vfit: false,
        icon: IconLima},
    "lindeman": {
        vfit: false,
        icon: IconLindeman},
    "lisbon": {
        vfit: false,
        icon: IconLisbon},
    "ljubljana": {
        vfit: false,
        icon: IconLjubljana},
    "lome": {
        vfit: false,
        icon: IconLome},
    "london": {
        vfit: true,
        icon: IconLondon},
    "longyearbyen": {
        vfit: false,
        icon: IconLongyearbyen},
    "lordhowe": {
        vfit: false,
        icon: IconLordhowe},
    "losangeles": {
        vfit: true,
        icon: IconLosangeles},
    "louisville": {
        vfit: false,
        icon: IconLouisville},
    "lowerprinces": {
        vfit: false,
        icon: IconLowerprinces},
    "luanda": {
        vfit: false,
        icon: IconLuanda},
    "lubumbashi": {
        vfit: false,
        icon: IconLubumbashi},
    "lusaka": {
        vfit: false,
        icon: IconLusaka},
    "luxembourg": {
        vfit: false,
        icon: IconLuxembourg},
    "macao": {
        vfit: false,
        icon: IconMacao},
    "macau": {
        vfit: false,
        icon: IconMacau},
    "maceio": {
        vfit: false,
        icon: IconMaceio},
    "macquarie": {
        vfit: false,
        icon: IconMacquarie},
    "madeira": {
        vfit: false,
        icon: IconMadeira},
    "madrid": {
        vfit: true,
        icon: IconMadrid},
    "magadan": {
        vfit: false,
        icon: IconMagadan},
    "mahe": {
        vfit: false,
        icon: IconMahe},
    "majuro": {
        vfit: false,
        icon: IconMajuro},
    "makassar": {
        vfit: false,
        icon: IconMakassar},
    "malabo": {
        vfit: false,
        icon: IconMalabo},
    "maldives": {
        vfit: false,
        icon: IconMaldives},
    "malta": {
        vfit: false,
        icon: IconMalta},
    "managua": {
        vfit: false,
        icon: IconManagua},
    "manaus": {
        vfit: false,
        icon: IconManaus},
    "manila": {
        vfit: false,
        icon: IconManila},
    "maputo": {
        vfit: false,
        icon: IconMaputo},
    "marengo": {
        vfit: false,
        icon: IconMarengo},
    "mariehamn": {
        vfit: false,
        icon: IconMariehamn},
    "marigot": {
        vfit: false,
        icon: IconMarigot},
    "marquesas": {
        vfit: false,
        icon: IconMarquesas},
    "martinique": {
        vfit: false,
        icon: IconMartinique},
    "maseru": {
        vfit: false,
        icon: IconMaseru},
    "matamoros": {
        vfit: false,
        icon: IconMatamoros},
    "mauritius": {
        vfit: false,
        icon: IconMauritius},
    "mawson": {
        vfit: false,
        icon: IconMawson},
    "mayotte": {
        vfit: false,
        icon: IconMayotte},
    "mazatlan": {
        vfit: false,
        icon: IconMazatlan},
    "mbabane": {
        vfit: false,
        icon: IconMbabane},
    "mcmurdo": {
        vfit: false,
        icon: IconMcmurdo},
    "melbourne": {
        vfit: false,
        icon: IconMelbourne},
    "mendoza": {
        vfit: false,
        icon: IconMendoza},
    "menominee": {
        vfit: false,
        icon: IconMenominee},
    "merida": {
        vfit: false,
        icon: IconMerida},
    "met": {
        vfit: false,
        icon: IconMet},
    "metlakatla": {
        vfit: false,
        icon: IconMetlakatla},
    "mexicocity": {
        vfit: false,
        icon: IconMexicocity},
    "michigan": {
        vfit: false,
        icon: IconMichigan},
    "midway": {
        vfit: false,
        icon: IconMidway},
    "minsk": {
        vfit: false,
        icon: IconMinsk},
    "miquelon": {
        vfit: false,
        icon: IconMiquelon},
    "mogadishu": {
        vfit: false,
        icon: IconMogadishu},
    "monaco": {
        vfit: false,
        icon: IconMonaco},
    "moncton": {
        vfit: false,
        icon: IconMoncton},
    "monrovia": {
        vfit: false,
        icon: IconMonrovia},
    "monterrey": {
        vfit: false,
        icon: IconMonterrey},
    "montevideo": {
        vfit: false,
        icon: IconMontevideo},
    "monticello": {
        vfit: false,
        icon: IconMonticello},
    "montreal": {
        vfit: false,
        icon: IconMontreal},
    "montserrat": {
        vfit: false,
        icon: IconMontserrat},
    "moscow": {
        vfit: false,
        icon: IconMoscow},
    "mountain": {
        vfit: false,
        icon: IconMountain},
    "mst": {
        vfit: false,
        icon: IconMst},
    "mst7mdt": {
        vfit: false,
        icon: IconMst7mdt},
    "muscat": {
        vfit: false,
        icon: IconMuscat},
    "nairobi": {
        vfit: false,
        icon: IconNairobi},
    "nassau": {
        vfit: false,
        icon: IconNassau},
    "nauru": {
        vfit: false,
        icon: IconNauru},
    "navajo": {
        vfit: false,
        icon: IconNavajo},
    "ndjamena": {
        vfit: false,
        icon: IconNdjamena},
    "nepal": {
        vfit: false,
        icon: IconNepal},
    "newfoundland": {
        vfit: false,
        icon: IconNewfoundland},
    "newsalem": {
        vfit: false,
        icon: IconNewsalem},
    "newyork": {
        vfit: false,
        icon: IconNewyork},
    "niamey": {
        vfit: false,
        icon: IconNiamey},
    "nicosia": {
        vfit: false,
        icon: IconNicosia},
    "nipigon": {
        vfit: false,
        icon: IconNipigon},
    "niue": {
        vfit: false,
        icon: IconNiue},
    "nome": {
        vfit: false,
        icon: IconNome},
    "norfolk": {
        vfit: false,
        icon: IconNorfolk},
    "noronha": {
        vfit: false,
        icon: IconNoronha},
    "north": {
        vfit: false,
        icon: IconNorth},
    "nouakchott": {
        vfit: false,
        icon: IconNouakchott},
    "noumea": {
        vfit: false,
        icon: IconNoumea},
    "novokuznetsk": {
        vfit: false,
        icon: IconNovokuznetsk},
    "novosibirsk": {
        vfit: false,
        icon: IconNovosibirsk},
    "nsw": {
        vfit: false,
        icon: IconNsw},
    "nuuk": {
        vfit: false,
        icon: IconNuuk},
    "nz": {
        vfit: false,
        icon: IconNz},
    "nzchat": {
        vfit: false,
        icon: IconNzchat},
    "ojinaga": {
        vfit: false,
        icon: IconOjinaga},
    "omsk": {
        vfit: false,
        icon: IconOmsk},
    "oral": {
        vfit: false,
        icon: IconOral},
    "oslo": {
        vfit: true,
        icon: IconOslo},
    "ouagadougou": {
        vfit: false,
        icon: IconOuagadougou},
    "pacific": {
        vfit: false,
        icon: IconPacific},
    "pagopago": {
        vfit: false,
        icon: IconPagopago},
    "palau": {
        vfit: false,
        icon: IconPalau},
    "palmer": {
        vfit: false,
        icon: IconPalmer},
    "panama": {
        vfit: false,
        icon: IconPanama},
    "pangnirtung": {
        vfit: false,
        icon: IconPangnirtung},
    "paramaribo": {
        vfit: false,
        icon: IconParamaribo},
    "paris": {
        vfit: false,
        icon: IconParis},
    "perth": {
        vfit: false,
        icon: IconPerth},
    "petersburg": {
        vfit: false,
        icon: IconPetersburg},
    "phnompenh": {
        vfit: false,
        icon: IconPhnompenh},
    "phoenix": {
        vfit: false,
        icon: IconPhoenix},
    "pitcairn": {
        vfit: false,
        icon: IconPitcairn},
    "podgorica": {
        vfit: false,
        icon: IconPodgorica},
    "pohnpei": {
        vfit: false,
        icon: IconPohnpei},
    "poland": {
        vfit: false,
        icon: IconPoland},
    "ponape": {
        vfit: false,
        icon: IconPonape},
    "pontianak": {
        vfit: false,
        icon: IconPontianak},
    "portauprince": {
        vfit: false,
        icon: IconPortauprince},
    "portmoresby": {
        vfit: false,
        icon: IconPortmoresby},
    "portoacre": {
        vfit: false,
        icon: IconPortoacre},
    "portofspain": {
        vfit: false,
        icon: IconPortofspain},
    "portonovo": {
        vfit: false,
        icon: IconPortonovo},
    "portovelho": {
        vfit: false,
        icon: IconPortovelho},
    "portugal": {
        vfit: false,
        icon: IconPortugal},
    "prague": {
        vfit: true,
        icon: IconPrague},
    "prc": {
        vfit: false,
        icon: IconPrc},
    "pst8pdt": {
        vfit: false,
        icon: IconPst8pdt},
    "puertorico": {
        vfit: false,
        icon: IconPuertorico},
    "puntaarenas": {
        vfit: false,
        icon: IconPuntaarenas},
    "pyongyang": {
        vfit: false,
        icon: IconPyongyang},
    "qatar": {
        vfit: false,
        icon: IconQatar},
    "qostanay": {
        vfit: false,
        icon: IconQostanay},
    "queensland": {
        vfit: false,
        icon: IconQueensland},
    "qyzylorda": {
        vfit: false,
        icon: IconQyzylorda},
    "rainyriver": {
        vfit: false,
        icon: IconRainyriver},
    "rangoon": {
        vfit: false,
        icon: IconRangoon},
    "rankininlet": {
        vfit: false,
        icon: IconRankininlet},
    "rarotonga": {
        vfit: false,
        icon: IconRarotonga},
    "recife": {
        vfit: false,
        icon: IconRecife},
    "regina": {
        vfit: false,
        icon: IconRegina},
    "resolute": {
        vfit: false,
        icon: IconResolute},
    "reunion": {
        vfit: false,
        icon: IconReunion},
    "reykjavik": {
        vfit: false,
        icon: IconReykjavik},
    "riga": {
        vfit: false,
        icon: IconRiga},
    "riobranco": {
        vfit: false,
        icon: IconRiobranco},
    "riogallegos": {
        vfit: false,
        icon: IconRiogallegos},
    "riyadh": {
        vfit: false,
        icon: IconRiyadh},
    "roc": {
        vfit: false,
        icon: IconRoc},
    "rok": {
        vfit: false,
        icon: IconRok},
    "rome": {
        vfit: false,
        icon: IconRome},
    "rosario": {
        vfit: false,
        icon: IconRosario},
    "rothera": {
        vfit: false,
        icon: IconRothera},
    "saigon": {
        vfit: false,
        icon: IconSaigon},
    "saipan": {
        vfit: false,
        icon: IconSaipan},
    "sakhalin": {
        vfit: false,
        icon: IconSakhalin},
    "salta": {
        vfit: false,
        icon: IconSalta},
    "samara": {
        vfit: false,
        icon: IconSamara},
    "samarkand": {
        vfit: false,
        icon: IconSamarkand},
    "samoa": {
        vfit: false,
        icon: IconSamoa},
    "sanjuan": {
        vfit: false,
        icon: IconSanjuan},
    "sanluis": {
        vfit: false,
        icon: IconSanluis},
    "sanmarino": {
        vfit: false,
        icon: IconSanmarino},
    "santaisabel": {
        vfit: false,
        icon: IconSantaisabel},
    "santarem": {
        vfit: false,
        icon: IconSantarem},
    "santiago": {
        vfit: false,
        icon: IconSantiago},
    "santodomingo": {
        vfit: false,
        icon: IconSantodomingo},
    "saopaulo": {
        vfit: false,
        icon: IconSaopaulo},
    "saotome": {
        vfit: false,
        icon: IconSaotome},
    "sarajevo": {
        vfit: false,
        icon: IconSarajevo},
    "saratov": {
        vfit: false,
        icon: IconSaratov},
    "saskatchewan": {
        vfit: false,
        icon: IconSaskatchewan},
    "scoresbysund": {
        vfit: false,
        icon: IconScoresbysund},
    "seoul": {
        vfit: false,
        icon: IconSeoul},
    "shanghai": {
        vfit: false,
        icon: IconShanghai},
    "shiprock": {
        vfit: false,
        icon: IconShiprock},
    "simferopol": {
        vfit: false,
        icon: IconSimferopol},
    "singapore": {
        vfit: false,
        icon: IconSingapore},
    "sitka": {
        vfit: false,
        icon: IconSitka},
    "skopje": {
        vfit: false,
        icon: IconSkopje},
    "sofia": {
        vfit: false,
        icon: IconSofia},
    "south": {
        vfit: false,
        icon: IconSouth},
    "southgeorgia": {
        vfit: false,
        icon: IconSouthgeorgia},
    "southpole": {
        vfit: false,
        icon: IconSouthpole},
    "srednekolymsk": {
        vfit: false,
        icon: IconSrednekolymsk},
    "stanley": {
        vfit: false,
        icon: IconStanley},
    "stbarthelemy": {
        vfit: false,
        icon: IconStbarthelemy},
    "sthelena": {
        vfit: false,
        icon: IconSthelena},
    "stjohns": {
        vfit: false,
        icon: IconStjohns},
    "stkitts": {
        vfit: false,
        icon: IconStkitts},
    "stlucia": {
        vfit: false,
        icon: IconStlucia},
    "stockholm": {
        vfit: true,
        icon: IconStockholm},
    "stthomas": {
        vfit: false,
        icon: IconStthomas},
    "stvincent": {
        vfit: false,
        icon: IconStvincent},
    "swiftcurrent": {
        vfit: false,
        icon: IconSwiftcurrent},
    "sydney": {
        vfit: false,
        icon: IconSydney},
    "syowa": {
        vfit: false,
        icon: IconSyowa},
    "tahiti": {
        vfit: false,
        icon: IconTahiti},
    "taipei": {
        vfit: false,
        icon: IconTaipei},
    "tallinn": {
        vfit: false,
        icon: IconTallinn},
    "tarawa": {
        vfit: false,
        icon: IconTarawa},
    "tashkent": {
        vfit: false,
        icon: IconTashkent},
    "tasmania": {
        vfit: false,
        icon: IconTasmania},
    "tbilisi": {
        vfit: false,
        icon: IconTbilisi},
    "tegucigalpa": {
        vfit: false,
        icon: IconTegucigalpa},
    "tehran": {
        vfit: false,
        icon: IconTehran},
    "telaviv": {
        vfit: false,
        icon: IconTelaviv},
    "tellcity": {
        vfit: false,
        icon: IconTellcity},
    "thimbu": {
        vfit: false,
        icon: IconThimbu},
    "thimphu": {
        vfit: false,
        icon: IconThimphu},
    "thule": {
        vfit: false,
        icon: IconThule},
    "thunderbay": {
        vfit: false,
        icon: IconThunderbay},
    "tijuana": {
        vfit: false,
        icon: IconTijuana},
    "timbuktu": {
        vfit: false,
        icon: IconTimbuktu},
    "tirane": {
        vfit: false,
        icon: IconTirane},
    "tiraspol": {
        vfit: false,
        icon: IconTiraspol},
    "tokyo": {
        vfit: false,
        icon: IconTokyo},
    "tomsk": {
        vfit: false,
        icon: IconTomsk},
    "tongatapu": {
        vfit: false,
        icon: IconTongatapu},
    "toronto": {
        vfit: false,
        icon: IconToronto},
    "tortola": {
        vfit: false,
        icon: IconTortola},
    "tripoli": {
        vfit: false,
        icon: IconTripoli},
    "troll": {
        vfit: false,
        icon: IconTroll},
    "truk": {
        vfit: false,
        icon: IconTruk},
    "tucuman": {
        vfit: false,
        icon: IconTucuman},
    "tunis": {
        vfit: false,
        icon: IconTunis},
    "turkey": {
        vfit: false,
        icon: IconTurkey},
    "ujungpandang": {
        vfit: false,
        icon: IconUjungpandang},
    "ulaanbaatar": {
        vfit: false,
        icon: IconUlaanbaatar},
    "ulanbator": {
        vfit: false,
        icon: IconUlanbator},
    "ulyanovsk": {
        vfit: false,
        icon: IconUlyanovsk},
    "urumqi": {
        vfit: false,
        icon: IconUrumqi},
    "ushuaia": {
        vfit: false,
        icon: IconUshuaia},
    "ustnera": {
        vfit: false,
        icon: IconUstnera},
    "uzhgorod": {
        vfit: false,
        icon: IconUzhgorod},
    "vaduz": {
        vfit: false,
        icon: IconVaduz},
    "vancouver": {
        vfit: false,
        icon: IconVancouver},
    "vatican": {
        vfit: false,
        icon: IconVatican},
    "vevay": {
        vfit: false,
        icon: IconVevay},
    "victoria": {
        vfit: false,
        icon: IconVictoria},
    "vienna": {
        vfit: false,
        icon: IconVienna},
    "vientiane": {
        vfit: false,
        icon: IconVientiane},
    "vilnius": {
        vfit: false,
        icon: IconVilnius},
    "vincennes": {
        vfit: false,
        icon: IconVincennes},
    "virgin": {
        vfit: false,
        icon: IconVirgin},
    "vladivostok": {
        vfit: false,
        icon: IconVladivostok},
    "volgograd": {
        vfit: false,
        icon: IconVolgograd},
    "vostok": {
        vfit: false,
        icon: IconVostok},
    "wake": {
        vfit: false,
        icon: IconWake},
    "wallis": {
        vfit: false,
        icon: IconWallis},
    "warsaw": {
        vfit: false,
        icon: IconWarsaw},
    "west": {
        vfit: false,
        icon: IconWest},
    "wet": {
        vfit: false,
        icon: IconWet},
    "whitehorse": {
        vfit: false,
        icon: IconWhitehorse},
    "winamac": {
        vfit: false,
        icon: IconWinamac},
    "windhoek": {
        vfit: false,
        icon: IconWindhoek},
    "winnipeg": {
        vfit: false,
        icon: IconWinnipeg},
    "wsu": {
        vfit: false,
        icon: IconWsu},
    "yakutat": {
        vfit: false,
        icon: IconYakutat},
    "yakutsk": {
        vfit: false,
        icon: IconYakutsk},
    "yancowinna": {
        vfit: false,
        icon: IconYancowinna},
    "yangon": {
        vfit: false,
        icon: IconYangon},
    "yap": {
        vfit: false,
        icon: IconYap},
    "yekaterinburg": {
        vfit: false,
        icon: IconYekaterinburg},
    "yellowknife": {
        vfit: false,
        icon: IconYellowknife},
    "yerevan": {
        vfit: false,
        icon: IconYerevan},
    "yukon": {
        vfit: false,
        icon: IconYukon},
    "zagreb": {
        vfit: false,
        icon: IconZagreb},
    "zaporozhye": {
        vfit: false,
        icon: IconZaporozhye},
    "zurich": {
        vfit: false,
        icon: IconZurich},
    /* 
    Monezitation */
    /* 
    OS */
    "android": {
        vfit: false, 
        icon: IconAndroid},
    "apple": {
        vfit: false, 
        icon: IconApple},
    "macos": {
        vfit: false, 
        icon: IconMacOS},
    "linux": {
        vfit: false, 
        icon: IconLinux},
    "windows": {
        vfit: true, 
        icon: IconWindows},
    "windows7": {
        vfit: true, 
        icon: IconWindows7},
    "windows8": {
        vfit: true, 
        icon: IconWindows8},
    "windows10": {
        vfit: true, 
        icon: IconWindows10},
    "windows11": {
        vfit: true, 
        icon: IconWindows11},
    "windowsxp": {
        vfit: true, 
        icon: IconWindowsXp},
    /* 
    Other */
    "unknown": {
        vfit: false, 
        icon: IconUnknown}
}
const SvgIcon = ({svgName}) => {
    const ico = icons[svgName?.normalize("NFD").replace(/(_|-| |\p{Diacritic})/gu, "").toLowerCase()] || icons["unknown"]
    const Svg = ico?.icon
    return (
        <div className = "card-persona__dimension__icon-wrapper">
            <Svg className={`card-persona__dimension__icon ${ico?.vfit ? "card-persona__dimension__icon-vfit" : ""}`}/>
        </div>
    )
}

/* TODO:
    - Selectively [and automatically] auto-dim fields if no value is provided
    - Locally compute banner text 
    - Automatically assign icons from labels (shared svg name [so very easy])
*/ 
export const Persona = ({ report, buttonCallback, banner }) => {
    
    // TODO: Use useMemo (or useEffect w/ an empty array) instead to compute once per session/render
    const share = report?.share?.[0]
    const escore = useRef(report?.escore?.[report?.escore?.length - 1]/* NEXT: report.escore?.at(-1)*/)// ALT: useRef(report.escore?.reduce((a, b) => a + b) / report.escore?.length)
    const dimensions = report?.dimensions
    const device = dimensions?.device
    const admin = report?.admin
    const pronoun = report?.profile?.pronoun
    const determiner = (!device?.length || ["a", "e", "i", "o", "u"].includes(device?.[0]?.[0]?.toLowerCase())) ? "an" : "a"

    // useLayoutEffect(() => {
    //     pics.forEach((pic) => {
    //         const img = new Image()
    //         img.src = pic
    //         // One-liner -> new Image().src = pic.fileName
    //     })
    // })//, [report?.profile?.portrait])
    
    return (
        <div className="card-persona">
            <div className="card-persona__s01" style={{
                filter: banner == "draft" ? "grayscale(1) brightness(1.2)" : "",
                opacity: banner == "draft" ? "0.5" : "1",
                background: `url(${report?.profile?.image}) transparent no-repeat center center / contain`
            }}>
            <div className={`card-persona__s01__banner card-persona__s01__banner-${banner}`}>
                <span className={`text-xs`}>{banner?.toUpperCase()} {admin ? " STATE" : " VALUABLE AUDIENCE"}</span>
            </div>
                {/* <img src={pic4} alt={ report.name }/> */}
                {/* <div><Banner value={report.top}/></div> */}
                {/* {report.top === 1 ? "MOST VALUABLE AUDIENCE" : (report.top === -1 ? "LEAST VALUABLE AUDENCIE" : "")} */}
            </div>
            <div className="card-persona__s02">

                <div className="card-persona__wheel-wrapper">
                    <div className="card-persona__wheel-counter__arc text-xxl" >
                        <Counter value={admin ? admin?.elabel : escore.current}/>
                    </div>
                    <Arc target={admin ? admin?.elabel : escore.current} theme={admin ? "admin" : "null"}/>
                </div>
                <span className="card-persona__wheel-label text-xs">{admin ? "eLabel" : "eScore"}</span>
            </div>
            <div className="card-persona__s03">
                <span className="text-m"><b>{ report?.profile?.name || "..." }</b></span>
                <span className="text-s">{ report?.profile?.title || "..." }</span>
            </div>
            <div className="card-persona__s04">
                <div className="card-persona__wheel-wrapper">
                    {admin 
                    ? 
                    <>
                        <div className="card-persona__wheel-counter__arc text-xxl" >
                            <Counter value={admin?.elabel}/>
                        </div>
                        <Arc target={admin?.elabel} theme="admin"/>
                    </>
                    :
                    <>
                        <div className={`card-persona__wheel-counter__pie ${true ? "text-xxl" : "text-l"}`}>
                            <span className="text-xl"><Counter value={share}/></span>
                            <span className="text-s">%</span>
                        </div>
                        <Arc target={share + "%"}/>
                    </>
                    }
                </div>
                <span className="card-persona__wheel-label text-xs">{admin ? "gLabel" : "of all users"}</span>
            </div>
            <div className="card-persona__s05">
            </div>

            <div className={`card-persona__s06 card-persona__dimension card-persona__dimension__l ${dimensions?.category?.[0] ? "" : "card-persona__dimension--disable"}`}>
                <div>
                    <span className="text-xs">{ pronoun || "..." } loves</span><br/>
                    <span className="text-s"><b>{ dimensions?.category?.[0] || "Unknown" }</b></span>
                </div>
                <SvgIcon svgName={dimensions?.category?.[0]}/>            
            </div>
            <div className={`card-persona__s07 card-persona__dimension card-persona__dimension__r ${device?.[0] ? "" : "card-persona__dimension--disable"}`}>
                <SvgIcon svgName={device?.[0]}/>
                <div>
                    <span className="text-xs">{ pronoun || "..." } owns {determiner}</span><br/>
                    <span className="text-s"><b>{ device?.[0] || "Unknown" }</b></span>
                </div>
            </div>
            <div className={`card-persona__s08 card-persona__dimension card-persona__dimension__l ${dimensions?.location?.[0] ? "" : "card-persona__dimension--disable"}`}>
                <div>
                    <span className="text-xs">{ pronoun || "..." } lives in</span><br/>
                    <span className="text-s"><b>{ dimensions?.location?.[0]?.replace("_", " ") || "Unknown" }</b></span>
                </div>
                <SvgIcon svgName={dimensions?.location?.[0]}/>
            </div>
            <div className={`card-persona__s09 card-persona__dimension card-persona__dimension__r ${dimensions?.app?.[0] ? "" : "card-persona__dimension--disable"}`}>
                <SvgIcon svgName={dimensions?.app?.[0]}/>
                <div>
                    <span className="text-xs">{ pronoun || "..." } reads from</span><br/>
                    <span className="text-s"><b>{ dimensions?.app?.[0] || "Unknown" }</b></span>
                </div>
            </div>
            <div className={`card-persona__s10 card-persona__dimension card-persona__dimension__l ${dimensions?.monetization.length ? "" : "card-persona__dimension--disable"}`}>
                <div>
                    <span className="text-xs">{ pronoun || "..." } pays with</span><br/>
                    <span className="text-s"><b>{ dimensions?.monetization?.[0] || "Unknown" }</b></span>
                </div>
                <SvgIcon svgName={dimensions?.monetization?.[0]}/>
            </div>
            <div className={`card-persona__s11 card-persona__dimension card-persona__dimension__r ${dimensions?.channel?.[0] ? "" : "card-persona__dimension--disable"}`}>
                <SvgIcon svgName={dimensions?.channel?.[0]}/>
                <div>
                    <span className="text-xs">{ pronoun || "..." } comes from</span><br/>
                    <span className="text-s"><b>{ dimensions?.channel?.[0] || "Unknown" }</b></span>
                </div>
            </div>

            <div className={`card-persona__s12 card-persona__dimension ${admin ? "" : "card-persona__dimension--disable"}`}>
                <button className="card-persona__s12__button text-m" onClick={admin && buttonCallback}>{(admin ? "Update " : "Understand ") + report?.profile?.name}</button>
            </div> 
        </div>
    )
}