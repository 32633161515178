export const STATE_STATISTICS = {
	IN_PROGRESS: "in_progress",
	DRAFT: "draft",
	PUBLISHED: "published",
	DEPRECATED: "deprecated"
}

export const JOBS_STATISTICS = {
	ALL_V1: 'all_v1',
	FETCH_PERIOD: "fetch_period",
	STORE_PERIOD: "store_period",
	INFERENCE: "inference"
}

export const JOBS_STATUS = {
    NOT_STARTED: 'not_started',
    IN_PROGRESS: 'in_progress',
    DONE: 'done',
    ERROR: 'error'
}

export const JOBS_CODE = {
	NOT_STARTED: 'NOT_STARTED',
	ALREADY_EXISTS: 'ALREADY_EXISTS',
	JOBS_DONE: 'JOBS_DONE'
}