// Dependencies
import { useEffect, useState } from "react"
import { GoogleOAuthProvider } from "@react-oauth/google"
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  Navigate
} from "react-router-dom"

// Views
import {
  Login,
  Tags,
  Settings,
  Installation,
  Highlights,
  Audiences,
  Content,
  Paywall,
  AdminTags,
  AdminCsv,
  AdminUploadPersona,
  StatisticsReport
} from "views"

// Contexts 
import { 
  ProvideAuth
} from "contexts"

// Hooks
import { useFetch } from "hooks/fetch"

// Util
import { GA_CLIENT_ID } from "util/const"
import {
  ROUTE_LOGIN,
  ROUTE_SETTINGS,
  ROUTE_INSTALLATION,
  ROUTE_CSV,
  ROUTE_HIGHLIGHTS,
  ROUTE_AUDIENCES,
  ROUTE_CONTENT,
  ROUTE_PAYWALL,
  ROUTE_TAGS,
  ROUTE_UPLOAD_PERSONA,
  ROUTE_STATISTICS_REPORT,
  ROUTE_PREFIX
} from "util/Routes.const"
import {
  API_NUCLEUS_BACKEND_USER,
  API_NUCLEUS_BACKEND_PROPERTY,
  API_NUCLEUS_BACKEND_PROPERTY_STATUS,
  API_NUCLEUS_BACKEND_AUDIENCES
} from "util/service.const"
import { STATUS_FAILURE } from "util/Status"
import {
  APP_TYPE,
  APP_TYPE_ADMIN,
  ENABLE_ROUTE_HIGHLIGHTS,
  ENABLE_ROUTE_AUDIENCES,
  ENABLE_ROUTE_CONTENT,
  ENABLE_ROUTE_PAYWALL,
  ENABLE_ROUTE_CSV,
  ENABLE_ROUTE_UPLOAD_PERSONA,
  ENABLE_ROUTE_STATISTICS_REPORT,
  SHOW_N_ENABLE_ALL
} from "util/const"

// Icons
import { ReactComponent as NucleusLogoIcon } from "assets/logo-icon.svg"

// Styling
import "./App.css"
import "./constants.css"

export const App = () => {
  const isAdminApp = APP_TYPE === APP_TYPE_ADMIN

  // TODO: Create TTL for Google token
  // TODO: Place in Auth-Context
  const existingUser = localStorage.getItem("nucleus-gu") ? JSON.parse(localStorage.getItem("nucleus-gu")) : false
  // const existingUser = false

  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(false)
  const [googleUser, setGoogleUser] = useState(existingUser)
  const [properties, setProperties] = useState([])
  const [domainKey, setDomainKey] = useState()
  const [audiences, setAudiences] = useState()

  const fetch = useFetch()

  // TODO: Streamline login flow
  const checkAuth = async ({ googleUser, user }) => {
    googleUser && setGoogleUser(googleUser) && await getProperties() // && await retrievePropertyStatus()
    user && setUser(user)
  }

  // TODO: Streamline login flow
  const retrieveUser = async (user) => {
    const token = user.credential

    if (!token) {
      setIsLoading(false)
      return
    }

    try {
      const url = isAdminApp ? `${API_NUCLEUS_BACKEND_USER}?check_admin=1` : API_NUCLEUS_BACKEND_USER
      const response = await fetch({ url, token })
      const { status, payload } = response

      setUser({
        ...payload,
        shouldSignup: STATUS_FAILURE === status
      })
    } catch (error) {
      console.error("Could not get user")
    }
  }

  const getProperties = async () => {

    const token = googleUser?.credential

    if (!token) {
      return
    }

    try {
      const url = isAdminApp ? `${API_NUCLEUS_BACKEND_PROPERTY}/all` : API_NUCLEUS_BACKEND_PROPERTY
      const response = await fetch({
        url,
        token
      })

      const { status, payload } = response

      if (STATUS_FAILURE === status) {
        return
      }

      if (isAdminApp) {
        payload?.all_properties && setProperties(payload?.all_properties)
        return payload?.all_properties
      } 
      const newProperties = payload?.properties && await retrievePropertyStatus(payload?.properties)
      setProperties(newProperties ? newProperties : payload?.properties)
      return newProperties
    } catch (error) {
      console.error("Could not get properties")
    } finally {
      setIsLoading(false)
    }
  }

  const retrievePropertyStatus = async (properties) => {
    const token = googleUser?.credential

    if (!token) {
      return
    }

    try {

      const response = await fetch({
        url: `${API_NUCLEUS_BACKEND_PROPERTY_STATUS}?shouldGroupByProperty=1`,
        token
      })

      const { status, payload } = response

      if (STATUS_FAILURE === status) {
        return
      }

      // TODO: Logic when response is grouped by app

      let newProperties = properties

      if (!newProperties) {
        return
      }

      // TODO: Prettify this!
      const consolidatedProperties = newProperties.map(property => {
        let payloadIndex = payload?.total_visits ? payload?.total_visits.findIndex(visitObject => {
          // TODO: Prettify this
          // Remote last / in url
          return (
            visitObject?.url.slice(0, visitObject?.url.lastIndexOf("/") === visitObject?.url.length - 1
              ? visitObject?.url.lastIndexOf("/") : visitObject?.url.length)) === property.url
        }) : null

        const newApps = property?.apps?.map(app => {
          const totalForApp = payload?.total_visits[payloadIndex] || null

          if (!totalForApp) {
            return {
              ...app,
              total_visits: 0,
              progress: 0
            }
          }

          let progress = 0

          if (totalForApp?.total && payload?.limit) {
            progress = (totalForApp?.total <= payload?.limit) ? (+totalForApp?.total / +payload?.limit) * 100 : 100
          }

          return {
            ...app,
            total_visits: +totalForApp?.total || 0,
            progress
          }
        })

        return {
          ...property,
          apps: newApps,
          total_visits_aggregated: payload?.total_visits_aggregated,
          total_limit: payload?.limit
        }
      })

      return consolidatedProperties
    } catch (error) {
      console.error("Could not get property status", error)
    }
  }

  const getAudiences = async (pkey) => {
    const token = googleUser?.credential
    if (!token) {
      return
    }
    try {
      const response = await fetch({
        url: `${API_NUCLEUS_BACKEND_AUDIENCES}${pkey ? "/" + pkey : ""}`,// -> https://nucleus-backend.wordpay.dev/api/v1/audience/PUBLIC_KEY
        token
      })
      const { status, payload } = response
      if (STATUS_FAILURE === status) {
        return
      }

      // ---
      // TODO: Ok for the time being for testing, but this should be part of the main audiences payload (i.e. one time series vector per [history-able] value)
      /* So:
         > Now: escore_temp = [42, 24, ...] <-- Ok for the time being (while on decoupled testing)
         > Nxt: personas?.[0].escore = [42] <-- The escore vector should go here (we only have one value there for the time being, but the needed 1D array is already there)
      */
      const response_xtra = await fetch({
        url: `${API_NUCLEUS_BACKEND_AUDIENCES}/historical${pkey ? "/" + pkey : ""}`// -> "https://nucleus-development-vlpnx.ondigitalocean.app/api/v1/audience/historical"
      })
      if (STATUS_FAILURE === response_xtra?.status) {
        return
      }
      // ---

      const payLoad = response_xtra?.payload
      if (payload && payLoad) {
        setAudiences({
          origin: (pkey) => setDomainKey(pkey),
          personas: payload.personas,
          // ---
          // TEMP DEV TEST
          escore_temp: payLoad.escore
          // escore_first: payLoad?.start_date.replace(" ", "")
          // --- 
        })
        setDomainKey(pkey)
      }
    } catch (error) {
      console.error("Could not get audiences", error)
    }
  }

  // Fix: Re-render due to navigation to login and then to the tags section
  useEffect(() => {
    if (!googleUser) {
      setIsLoading(false)
      return
    }

    // TODO: Place in Auth-Context
    localStorage.setItem("nucleus-gu", JSON.stringify(googleUser))
    retrieveUser(googleUser)
    getProperties().then((earlyProperties) => {
      // Include to avoid undefined errors
      earlyProperties && getAudiences(earlyProperties[0]?.public_key?.value/* TODO: Use localStorage for this after the first session */)
      //getAudiences(properties[0]?.public_key?.value)/* NOTE: State 'properties' still not ready at this point */
    })
    // retrievePropertyStatus()
    // FIXME: Fix dependency array eslint error
    // eslint-disable-next-line
  }, [googleUser])

  useEffect(() => {
    getAudiences(domainKey)
    // eslint-disable-next-line
  }, [domainKey])

  useEffect(() => {
    if (!(googleUser && user && properties && audiences)) {
      // FIXME: Fix constant login event if user is signed in
      return
    }
    // retrievePropertyStatus()
    // FIXME: Fix dependency array eslint error
    // eslint-disable-next-line
  }, [googleUser, user, properties, audiences])

  // TODO: Check if default route should be Tags instead
  const PrivateRoute = () => googleUser && user ? <Outlet /> : <Navigate to={`/${ROUTE_LOGIN}`} />
  const AdminRoute = () => googleUser && user && user.is_admin ? <Outlet /> : <Navigate to={`/${ROUTE_LOGIN}`} />

  return (
    isLoading 
      ? 
      <div className="loading">
        <NucleusLogoIcon className="loading__icon" />
      </div> 
      :
      <GoogleOAuthProvider clientId={GA_CLIENT_ID}>
        <ProvideAuth auth={{ user, googleUser, properties, audiences, currentDomainKey: domainKey }}>
            <BrowserRouter basename={ROUTE_PREFIX}>
              {APP_TYPE === APP_TYPE_ADMIN 

              ? (// nucleusanalytics.io/admin
                <Routes>
                  <Route path={`/${ROUTE_LOGIN}`} element={<Login checkAuth={checkAuth} newUser={!googleUser}/>} />
                  <Route element={<AdminRoute />}>

                    {/* Top */}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HIGHLIGHTS) && <Route path={`/${ROUTE_HIGHLIGHTS}`} element={<Highlights user={user} googleUser={googleUser} properties={properties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_AUDIENCES) && <Route path={`/${ROUTE_AUDIENCES}`} element={<Audiences user={user} googleUser={googleUser} properties={properties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CONTENT) && <Route path={`/${ROUTE_CONTENT}`} element={<Content user={user} googleUser={googleUser} properties={properties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_PAYWALL) && <Route path={`/${ROUTE_PAYWALL}`} element={<Paywall user={user} googleUser={googleUser} properties={properties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CSV) && <Route path={`/${ROUTE_CSV}`} element={<AdminCsv user={user} googleUser={googleUser} properties={properties} getProperties={getProperties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_UPLOAD_PERSONA) && <Route path={`/${ROUTE_UPLOAD_PERSONA}`} element={<AdminUploadPersona user={user} googleUser={googleUser} properties={properties} getProperties={getProperties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_STATISTICS_REPORT) && <Route path={`/${ROUTE_STATISTICS_REPORT}`} element={<StatisticsReport user={user} googleUser={googleUser} properties={properties} getProperties={getProperties} getAudiences={getAudiences} />}></Route>}
                    
                    {/* Bottom */}
                    <Route path={`/${ROUTE_TAGS}`} element={<AdminTags user={user} googleUser={googleUser} properties={properties} />} />

                    {/* Bottom (deep) */}
                    <Route path={`/${ROUTE_INSTALLATION}`} element={<Installation user={user} googleUser={googleUser} properties={properties} getProperties={getProperties} />}></Route>

                  </Route>
                  <Route path="*" element={<Navigate to={`/${ROUTE_TAGS}`} replace />} />
                </Routes>
                
              ) : (// nucleusanalytics.io
                <Routes>
                  <Route path={`/${ROUTE_LOGIN}`} element={<Login checkAuth={checkAuth} newUser={!googleUser} />} />
            
                  <Route element={<PrivateRoute />} >

                    {/* TODO: Create HOC to pass props */}

                    {/* Top */}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HIGHLIGHTS) && <Route path={`/${ROUTE_HIGHLIGHTS}`} element={<Highlights user={user} googleUser={googleUser} properties={properties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_AUDIENCES) && <Route path={`/${ROUTE_AUDIENCES}`} element={<Audiences user={user} googleUser={googleUser} properties={properties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CONTENT) && <Route path={`/${ROUTE_CONTENT}`} element={<Content user={user} googleUser={googleUser} properties={properties} />}></Route>}
                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_PAYWALL) && <Route path={`/${ROUTE_PAYWALL}`} element={<Paywall user={user} googleUser={googleUser} properties={properties} />}></Route>}

                    {/* Bottom */}
                    <Route path={`/${ROUTE_TAGS}`} element={<Tags user={user} googleUser={googleUser} properties={properties} />}></Route>
                    <Route path={`/${ROUTE_SETTINGS}`} element={<Settings user={user} googleUser={googleUser} properties={properties} />}></Route>
                   
                    {/* Bottom (deep) */}
                    <Route path={`/${ROUTE_INSTALLATION}`} element={<Installation user={user} googleUser={googleUser} properties={properties} getProperties={getProperties} />}></Route>
                  
                  </Route>

                  <Route path="*" element={<Navigate to={`/${ROUTE_TAGS}`} replace />} />
                </Routes>
              )}
            </BrowserRouter>
        </ProvideAuth>
      </GoogleOAuthProvider>
  )
}

export default App