import { createRef } from "react"
import { useSearchParams } from "react-router-dom"

// Components
import { FormGroup, FormAction } from "components"

// Util
import { APP_TYPE_WEB, APP_TYPE_REACT_NATIVE } from "util/Apps.const"
import { SEARCH_PARAM_APP_TYPE } from "util/SearchParams.const"
import { WITH_CONSENT, WITH_SPA } from "util/const"

// Styling
import "./InstallationWizard.css"

export const InstallationWizard = ({ onSubmit }) => {
    // eslint-disable-next-line
    const [searchParams, _] = useSearchParams()

    const newPropertyRef = createRef()
    const withConsentRef = createRef()
    const withSpaRef = createRef()

    const createNewPropertyId = "createNewProperty"
    const withConsentId = "withConsentId"
    const withSpaId = "withSpaId"

    const appType = searchParams.get(SEARCH_PARAM_APP_TYPE)

    const onSubmitWizard = (e) => {
        e.preventDefault()

        const newPropertyUrl = newPropertyRef.current?.value

        let body = { url: newPropertyUrl, isNew: true, type: appType }

        if (WITH_CONSENT) {
            body = {
                ...body,
                with_consent: withConsentRef.current.checked
            }
        }

        if (WITH_SPA) {
            body = {
                ...body,
                is_spa: withSpaRef.current.checked
            }
        }

        onSubmit(body)
    }

    const getPlaceholder = () => {
        switch (appType) {
            case APP_TYPE_WEB:
                return "https://example.com"
            case APP_TYPE_REACT_NATIVE:
                return "com.example.app"
            default:
                return "https://example.com"
        }
    }

    const getPattern = () => {
        switch (appType) {
            case APP_TYPE_WEB:
                return "http(s?)://.*"
            case APP_TYPE_REACT_NATIVE:
                return ".*"
            default:
                return "http(s?)://.*"
        }
    }

    const getInputType = () => {
        switch (appType) {
            case APP_TYPE_WEB:
                return "url"
            case APP_TYPE_REACT_NATIVE:
                return "text"
            default:
                return "url"
        }
    }

    return (
        <form onSubmit={onSubmitWizard} className="installation-wizard">
            <h2>Create property</h2>
            <FormGroup
                className="installation-wizard__group"
                id={createNewPropertyId}
                name={"newPropertyUrl"}
                label={"Create new property"}
                type={getInputType()}
                placeholder={getPlaceholder()}
                required
                pattern={getPattern()}
                size="50"
                ref={newPropertyRef}
            />
            {WITH_CONSENT && (
                <div className="installation-wizard__group installation-wizard__group--place-start">
                    <label className="installation-wizard__group-label" htmlFor={withConsentId}>
                        <span className="installation-wizard__group-label-main">Only collect data if consent is given</span>
                        <i className="installation-wizard__group-label-helper">If you are in a region that requires user consent (GDPR, CCPA, etc.) you should mark the checkbox.</i>
                    </label>
                    <input className="installation-wizard__group-check" type="checkbox" id={withConsentId} ref={withConsentRef} />
                </div>
            )
            }
            {WITH_SPA &&
                <div className="installation-wizard__group installation-wizard__group--place-start">
                    <label className="installation-wizard__group-label" htmlFor={withSpaId}>
                        <span className="installation-wizard__group-label-main">Mark this if you have a Single Page Application (SPA)</span>
                        <i className="installation-wizard__group-label-helper">Marking this will ensure correct registering of visits on pages with client-site routing.<br />This feature is in beta. We are still refining this feature. Feel free to contact us if you experience any issues.</i>
                    </label>
                    <input className="installation-wizard__group-check" type="checkbox" id={withSpaId} ref={withSpaRef} />
                </div>
            }
            <FormAction className="installation-wizard__group" text={"Save"} />
        </form>
    )
}