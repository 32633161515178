import { forwardRef } from "react"

// Styling
import "./FormGroup.css"

export const FormGroup = forwardRef(({
    id,
    label,
    name,
    type = "text",
    placeholder,
    required,
    pattern,
    size,
    list,
    onChange,
    className,
    additionalAttributes,
    readOnly = false,
    children
}, ref) => (
    <div className={`form-group ${className ? className : ""}`}>
        <label className="form-group__label" htmlFor={id}>{label}</label>
        <input
            className={`form-group__control ${readOnly ? 'form-group__control--dimmed' : ''}`}
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
            pattern={pattern}
            size={size}
            list={list}
            ref={ref}
            onInput={onChange}
            readOnly={readOnly}
            {
                ...additionalAttributes
            }
        />
        {children}
    </div>
))