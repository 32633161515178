import "./Navbar.css"

export const Navbar = ({dots}) => {
  let next = 0
  return (
    <div className="navbar">
      <span className="navbar__nlabel navbar__ntop text-xs">100</span>
      {(new Array(34).fill()).map((_, index_col) =>// NEXT: 2023:~97% -> [...Array(34)].map
        <div key={index_col} className="navbar__dotrow">
          {[0, 0, 0].map((_, index_row) => <div key={index_row} className={`navbar__dotrow__hot-${
            dots[next] === ((index_col * 3) + index_row) && !!++next
          } ${next === 1 ? "first" : (next === dots.length ? "last" : "")}`}></div>)}
        </div>
      )}
      <span className="navbar__nlabel navbar__nbottom text-xs">0</span>
    </div>
  )
}