import { useNavigate } from "react-router-dom"
import { googleLogout } from "@react-oauth/google"

// Util
import { ROUTE_LOGIN } from "util/Routes.const"

// Icons
import { ReactComponent as LogoutIcon } from "assets/icons_mini/logout.svg"

// Styling
import "./Avatar.css"

export const Avatar = ({ user }) => {
    const navigate = useNavigate()
    const onLogout = () => {
        googleLogout()
        navigate(`/${ROUTE_LOGIN}`)
        localStorage.removeItem("nucleus-gu")
    }
    return (
        /*
        Google OAuth API (developers.google.com/identity/protocols/oauth2) response example:
        {_te
            "sub": "1057abc98136861333615xz",
            "name": "My Name",
            "given_name": "My",
            "family_name": "Name",
            "picture": "https://lh3.googleusercontent.com/a-/AOh14qiJarwP9rRw7IzxO40anYi4pTTAU_xseuRPFeeYFg",
            "email": "MyName@gmail.com",
            "email_verified": true,
            "locale": "en"
          }
        */
        <div className="avatar">
            <div className={`avatar__picture ${false && user?.avatar ? "" : "avatar__picture-less"}`} onClick={onLogout}>
                <LogoutIcon className="avatar__picture__icon"/>
                {false /* TODO: Detect when the source Google avatar is letter-based (broken/ugly if so) */ && user?.avatar
                    ? 
                    <img src={user?.avatar} alt={user?.name}/>
                    :
                    <div>{user?.given_name?.slice(0, 1).toUpperCase() || "?"}</div>
                }
            </div>
            <div className="avatar__details">
                <span className="text-m"><b>{user?.name || "Unknown name"}</b></span>
                <span className="text-s">{user?.email || "Unknown email"}</span>
            </div> 
            <span className="avatar__prompt text-m">Sign out</span>
        </div>
    )
}