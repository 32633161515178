// Content-wide styling
import "./Content.css"

// Containers
import { 
    Layout
} from "containers"

// Content assembly
export const Content = () => {
    return (
      <Layout header={"Content"}>
        <b>Work in progress...</b><br/>
        <small>This section will be hidden before a new commit is pushed</small>
      </Layout>
)}