import { useRef } from "react"
import { Link, useSearchParams } from "react-router-dom"

// Components
import { FormAction } from "components"

// Util
import { APP_TYPE_WEB } from "util/Apps.const"
import { URL_WIDGET, URL_WIDGET_CDN } from "util/Static.const"
import { ROUTE_SETTINGS } from "util/Routes.const"
import { SEARCH_PARAM_APP_TYPE } from "util/SearchParams.const"
import { WITH_CUSTOM_USER_ID } from "util/const"

// Styling
import "./InstallationScript.css"

export const InstallationScript = ({ url, token, withConsent, withSpa, showSuccessMessage }) => {
    // eslint-disable-next-line
    const [searchParams, _] = useSearchParams()

    const appType = searchParams.get(SEARCH_PARAM_APP_TYPE) || APP_TYPE_WEB

    const scriptRef = useRef()
    const customUserIdRef = useRef()

    const widgetUrl = URL_WIDGET_CDN || URL_WIDGET
    const nucleusScriptId = "nucleus-script"

    const onCopy = () => {
        const codeArea = scriptRef.current
        navigator.clipboard.writeText(codeArea.textContent);
        showSuccessMessage("Snippet Copied to clipboard")
    }

    const onCopyCustomUserId = () => {
        const codeArea = customUserIdRef.current
        navigator.clipboard.writeText(codeArea.textContent);
        showSuccessMessage("Snippet Copied to clipboard")
    }

    // Included dynamic app-type
    return (
        <section className="installation-script">
            <h2>Install script on {url ? url : "Your Website"}</h2>
            <br/>
            <b>Include the following on your site:</b>
            <br/>
            <span className="text-s">For modern browser you can place the script tag just before the closing body tag for old browsers you must place the script tag in the head.</span>
            <div className="installation-script__content">
                <code ref={scriptRef} className="installation-script__script">
                    &lt;script type=&quot;module&quot; id=&quot;{nucleusScriptId}&quot; src=&quot;{widgetUrl}&quot; data-npuk=&quot;{token}&quot; data-nat=&quot;{appType}&quot;{withConsent ? ` data-consent="1"` : null}{withSpa ? ` data-spa="1"`: null}&gt;&lt;/script&gt;
                </code>
            <br/>
            </div>
            <p>After you have installed your script you can check the status on the <Link to={`/${ROUTE_SETTINGS}`}>settings page</Link></p>
            <FormAction className="installation-script__action" onClick={onCopy} text={"Copy snippet"} />
            {withConsent && (
                <>
                    <b>Include consent triggers</b>
                    <p>Include the following JS-snippets on your site to make sure to activate and withdraw consent correctly:</p>
                    <div className="installation-script__content">
                        <code>
                            &#47;&#47; Activate consent
                            <br />
                            window.nucleus?.consent_given()
                            <br />
                            <br />
                            &#47;&#47; Withdraw consent<br />
                            window.nucleus?.consent_withdraw()
                    </code>
                    </div>
                </>
            )}

            {WITH_CUSTOM_USER_ID && (
                <>
                    <b>Include custom user id</b>
                    <p>If you want to pass a custom user id you can attach a data-attribute to the script above</p>
                    <div className="installation-script__content">
                        <code className="installation-script__script">
                            &lt;script<br />
                            ...Other attributes<br />
                            data-custom-user-id="custom-user-id"<br />
                            &gt;&lt;/script&gt;
                </code>
                    </div>
                    <p>If you want to get your custom user id with Javascript you can attach the data-attribute programatically</p>
                    <div className="installation-script__content">
                        <code ref={customUserIdRef} className="installation-script__script">
                            &lt;script&gt;<br />
                        window.addEventListener("DOMContentLoaded", () =&gt; &#123;<br />
				            const nucleusScript = document.querySelector("#{nucleusScriptId}")<br />
				            nucleusScript.setAttribute("data-custom-user-id", "custom-user-id")<br />
                        &#125;)<br />
                    &lt;/script&gt;
                </code>
                    </div>
                    <FormAction className="installation-script__action" onClick={onCopyCustomUserId} text={"Copy snippet"} />
                </>
            )}
        </section>
    )
}