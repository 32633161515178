import { 
    useState,
    useEffect
} from "react"

import { 
    Header,
    Sidebar
} from "components"

import "./Layout.css"

/* TODO: 
   - Fix sliding/show-hide behavior (translate vs width)
   - Close button
   - Custom scrollbar top-edge
   - Scrollable sidebar after overflow
*/
export const Layout = ({ children, header, scroll }) => {
    const [isSidebarHidden, setSidebarHidden] = useState(true)
    useEffect(() => {
        setSidebarHidden(false)
        const resetSidebar = () => {
            setSidebarHidden(null)  
        }
        window.addEventListener("resize", resetSidebar, {once: true})
      }, [])
    return ( 
        <div className="layout">
            <div className="layout__header-container">
                <Header content={header} callback={() => {
                    setSidebarHidden(!isSidebarHidden)
                }}/>
            </div>
            {scroll &&
            <div className={`layout__scrollbar-container layout__scrollbar-container--${isSidebarHidden ? "show" : "hide"}`}>
                {scroll}
            </div>}
            <div className="layout__content-container" onClick={() => setSidebarHidden(false)}>  
                {children}
            </div>
            <div className={`layout__sidebar-container ${
                isSidebarHidden === null ? "layout__sidebar-container--drag" : 
                isSidebarHidden === true ? "layout__sidebar-container--show" : ""}`}>
                <Sidebar/>
            </div>
        </div>
    )
}