import { useRef, useEffect } from "react"
import { Link } from "react-router-dom"

// Components
import { Meter, InstallationButton } from "components"

// Util
import { APP_TYPE_REACT, APP_TYPE_REACT_IOS_SDK, APP_TYPE_REACT_NATIVE, APP_TYPE_WEB } from "util/Apps.const"
import { ROUTE_INSTALLATION } from "util/Routes.const"
import { SEARCH_PARAM_APP_TYPE, SEARCH_PARAM_URL, SEARCH_PARAM_STEP } from "util/SearchParams.const"

// Icons
// import { ReactComponent as TrashIcon } from "assets/Trash.svg"
import { ReactComponent as PencilIcon } from "assets/_temp/Pencil.svg"

// Styling
import "./InstallationTable.css"

// TODO: Include actions
export const AppsTable = ({ apps, ActionsRow = null, propertyUrl, totalLimit, className }) => {
    const meterRef = useRef([])

    const getUIAppType = (appType) => {
        switch (appType) {
            case APP_TYPE_REACT:
                return "ReactJS"
            case APP_TYPE_REACT_IOS_SDK:
                return "iOS (SDK)"
            case APP_TYPE_REACT_NATIVE:
                return "React Native"
            case APP_TYPE_WEB:
                return "Web"
            default:
                return appType
        }
    }

    const getAppStyling = (appType) => {
        switch (appType) {
            case APP_TYPE_REACT:
                return "installation-table__app--primary"
            case APP_TYPE_REACT_IOS_SDK:
                return "installation-table__app--secondary"
            case APP_TYPE_REACT_NATIVE:
                return "installation-table__app--primary"
            case APP_TYPE_WEB:
                return "installation-table__app--secondary"
            default:
                return ""
        }
    }

    // const onDelete = (app) => {
    //     const shouldDelete = window.confirm("Are you sure to delete pixel?")
    //     console.log("onDelete", app, shouldDelete)
    // }

    useEffect(() => {
        meterRef.current.forEach(meter => meter.classList.add("meter__bar--show"))
    }, [])

    /* TODO:
       - User [modern] CSS grid instead of table
    */
    return (
        <table className={`installation-table__content ${className ? className : ""}`}>
            <thead>
                <tr className="installation-table__row">
                    <th className="installation-table__cell">Status</th>
                    <th className="installation-table__cell installation-table__cell--fill">Events this month</th>
                    <th className="installation-table__cell">Pixel</th>
                    <th className="installation-table__cell"></th>
                </tr>
            </thead>
            <tbody>
                {apps.map((app, index) => (
                    <tr className="installation-table__row" key={`${app.type}-${index}`}>
                        <td className="installation-table__cell">
                            <span
                                className={`installation-table__status
                                        ${app.active
                                        ? "installation-table__status--active"
                                        : "installation-table__status--inactive"}`}
                            >{app.active ? "Active" : "Inactive"}</span>
                        </td>
                        <td className="installation-table__cell installation-table__cell--fill">
                            <Meter ref={meterElement => meterRef.current[index] = meterElement} meterLevel={app.progress} current={app.total_visits} total={totalLimit} />
                        </td>
                        <td className="installation-table__cell">
                            <span className={`installation-table__app ${getAppStyling(app.type)}`}>{getUIAppType(app.type)}</span>
                        </td>
                        <td className="installation-table__cell">
                            {ActionsRow ? <ActionsRow app={app} /> : (
                                <div>
                                    <InstallationButton link={`/${ROUTE_INSTALLATION}?${SEARCH_PARAM_URL}=${propertyUrl}&${SEARCH_PARAM_APP_TYPE}=${app.type}`} text={""} className="installation-table__action" />
                                    {/* <button onClick={() => onDelete(app)} className="installation-table__action">
                                        <TrashIcon />
                                    </button> */}
                                    <Link
                                        className="installation-table__action"
                                        to={`/${ROUTE_INSTALLATION}?${SEARCH_PARAM_STEP}=${"edit"}&${SEARCH_PARAM_URL}=${propertyUrl}&${SEARCH_PARAM_APP_TYPE}=${app.type}`}
                                        title="Edit app"
                                    >
                                        <PencilIcon />
                                    </Link>
                                </div> 
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}