const { REACT_APP_PATH } = process.env

// Base
export const ROUTE_LOGIN = "login"
export const ROUTE_TAGS = "tags"
export const ROUTE_INSTALLATION = "installation"
export const ROUTE_INSIGHTS = "insights"
export const ROUTE_CSV = "csv"
export const ROUTE_HOME = "tags"
export const ROUTE_HIGHLIGHTS = "highlights"
export const ROUTE_GROUPS = "groups"
export const ROUTE_RECOMMENDATIONS = "recommendations"
export const ROUTE_CONSENT = "consent"
export const ROUTE_SETTINGS = "settings"

export const ROUTE_AUDIENCES = "audiences"
export const ROUTE_CONTENT = "content"
export const ROUTE_PAYWALL = "paywall"
export const ROUTE_UPLOAD_PERSONA = "upload-persona"
export const ROUTE_STATISTICS_REPORT = "statistics-report"

// Util
export const ROUTE_PREFIX = REACT_APP_PATH || ""