import { GoogleLogin } from "@react-oauth/google"

export const LoginButton = ({ onSuccess, onError, width }) => {
    return (
        <GoogleLogin
            onSuccess={onSuccess}
            onError={onError}
            useOneTap
            auto_select
            context="use"
            width={width}
        />
    )
}