import { 
  useEffect, 
  useMemo
} from "react"

import "./Grid.css"

export const Grid = ({content, template, cellSize={w: null, h: null}}) => {

  // Make sure every grid is unique (in order to avoid collisions)
  const gridId = useMemo(() => crypto.getRandomValues((new Uint32Array(3))).join(""), [])

  // Reverse syntactic sugar
  useEffect(() => {

    // FIXME: Eslint - Template variable value will lost after each render
    // Column modifiers fallback
    if (!template.includes("?")) {
      template += "? ".repeat(template.match(/[0-9].*/g)?.pop().split(" ").length)
    }

    // Grid hydration
    const w = cellSize.w ? cellSize.w + "px" : "100%"
    const h = cellSize.h ? cellSize.h + "px" : "calc(100vh - 90px)"// -> 90px being the top bar
    document.getElementById(gridId).style.grid = template
      .replace(/([0-9]+)/g, ` g${gridId}c$1`)   // Rename grid areas (g[rid] + ID + c[ell] + Index)
      .replace(/\n\s*(g)/g, `\n"$1`)            // Add leading quotation marks
      .replace(/([0-9]+)\s*\n/g, `$1" ${h}\n`)  // Add trailing quotation marks
      .replace(/\s*([!?])/, " /\n$1")           // Add slash divisor for columns
      .replace(/[?]/g, `minmax(${w}, 1fr)`)     // Rename shorthand for responsive mode (default behavior)
      .replace(/[!]/g, `${w}`)                  // Rename shorthand for fixed mode (if any)
  })

  return (
    <section id={gridId} className="grid">
      {content.map((card, index) => {
        return (
          <div key={index} className={`grid__cell grid__cell-${index}`} style={{"gridArea": `g${gridId}c${index}`}}>
            {card}
          </div>
        )
      })}
    </section>
  )
}